import React, { Component } from 'react';
import ajaxSchool from '../../../remote/ajaxSchool';
import ActivityLoader from '../../Common/ActivityLoader';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import CallOut from '../../Common/CallOut';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';


class ImportSchoolPay extends Component {

    state={
        info:'',
        loading:false,
        fileName:'',
        actualCsvFile:''
    }


   
    onChangeFile = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
             this.setState({
                actualCsvFile:{file:  e.target.result}
             })
         }
         
       }
    

       onConfirm = async (event) => {
        event.preventDefault();
        const {fileName, actualCsvFile}=this.state;
        if (fileName.length > 0) {
            this.setState({
                loading: true
            })

            const server_response=await ajaxSchool.importSchoolPay({"payment_file":actualCsvFile});
            this.setState({
                loading: false
            })
            if(server_response.status==="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.message} />,
                    fileName:'',
                    actualCsvFile:''
                })
            }else{
                this.setState({
                    info: <AlertError message={server_response.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Select CSV file and try again"} />
            })
        }
    }


  
    render() {
        return (
            <SystemModal id="mdl-school-pay" title="Import School Pay" type="modal-lg">
                <CallOut 
                    title="Note: The payment records must be from a csv file arranged in the format:-" 
                    description="Transaction Date, Description, Payment code, Receipt Number, Amount, and Bank Code(System)"/>

               <form method="post" className="form-horizontal" onSubmit={this.onConfirm}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}
               <TextInput
                            type="file"
                            label="Select CSV file"
                            placeholder="Select CSV file"
                            leftIcon="fa fa-file"
                            onChange={this.onChangeFile} 
                            required/>
                 

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                                <div className="col-md-7">
                                {!this.state.loading && <button type="submit" className="btn btn-success">Import Now</button>}
                                </div>
                            </div>

                        </form> 

   

                </SystemModal>



        );
    }
}

export default ImportSchoolPay;