import React, { Component } from 'react';
import ajaxPeriod from '../../remote/ajaxPeriod';
import InfoBox from '../Common/InfoBox';

class DataOptionStatistics extends Component {

    state={
        currentPeriod:"..."
    }

    componentDidMount() {
        this.getCurrentPeriod();
    }

    getCurrentPeriod=async()=>{
        const server_response = await ajaxPeriod.getCurrentPeriod();
        if(server_response.status==="OK")
        {
            this.setState({
                currentPeriod:server_response.details.opening_date.short_date + " - " + server_response.details.closing_date.short_date
            })
        }
    }

    render() {
       
        return (
            <>
            <InfoBox title="Financial Period" iconName="fa fa-calendar txt-primary" bg="bg-light" value={this.state.currentPeriod}/>
            <InfoBox title="Tags" iconName="fa fa-tag" bg="bg-warning" value="00"/>
            <InfoBox title="Vendors" iconName="fa fa-truck" bg="bg-primary" value="00"/>
            <InfoBox title="Accounts" iconName="fa fa-university" bg="bg-success" value="00"/>

          </>
        );
    }
}

export default DataOptionStatistics;