import React, { Component } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import SelectSearch from '../../Components/Common/SelectSearch';
import TextInput from '../../Components/Common/TextInput';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';
import TrialBalance from '../../Components/Report/TrialBalance';
import ajaxIncome from '../../remote/ajaxIncome';
import ajaxPayee from '../../remote/ajaxPayee';
import ajaxPeriod from '../../remote/ajaxPeriod';
import ajaxReport from '../../remote/ajaxReport';
import ajaxTag from '../../remote/ajaxTag';

class NewFinancialPeriod extends Component {

    state={
        info:'',
        loading:false,
        openingDate:'',
        closingDate:'',
        statement:false
    }


    onChangeOpeningDate=(event)=>{
        this.setState({
            openingDate:event.target.value
        })
    }

    onChangeClosingDate=(event)=>{
        this.setState({
            closingDate:event.target.value
        })
    }

    componentDidMount() {
        this.generateTrialBalance();
    }


    generateTrialBalance=async()=>{
        this.setState({
            statement:false
        })
        const server_response = await ajaxReport.getTrialBalance();
        this.setState({
            loading:false

        })
        if(server_response.status==="OK")
        {  this.setState({
                statement:server_response.details
            }) 
        }
    }

    onConfirmPeriod=async(event)=>{
        event.preventDefault();

        const {closingDate, openingDate} = this.state;
        this.setState({
            loading:true
        })
        const server_response = await ajaxPeriod.startNewPeriod(openingDate, closingDate);

        this.setState({
            loading:false
        })

        if(server_response.status==="OK")
        {
            this.setState({
                openingDate:'',
                closingDate:'',
                info:<AlertSuccess message={server_response.message}/>
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }

    }

  
    render() {
        return (
           
            <PageContainer title="New Financial Period">
            <div className="row">
                <div className="col-md-12">
                    <CallOut title="Note" description="Note: The system must have a valid trial balance before creating a new period."/>
                    {this.state.info}

                    <div className="row">
                    
                    <div className="col-md-4">
                         <div className="card">

                            <div className="card-body">
            
                                <form className="form-horizontal" onSubmit={this.onConfirmPeriod}>
                                        
                                        {this.state.loading && <ActivityLoader/>}


                                        <TextInput
                                            type="date"
                                            label="Opening Date"
                                            placeholder="Opening Date"
                                            leftIcon="fa fa-calendar"
                                            onChange={this.onChangeOpeningDate} 
                                            required
                                            wrap
                                            />

                                        <TextInput
                                            type="date"
                                            label="Closing Date"
                                            placeholder="Closing Date"
                                            leftIcon="fa fa-calendar"
                                            onChange={this.onChangeClosingDate} 
                                            required
                                            wrap
                                            />

                           
        

                                        <div className="row">&nbsp;</div>
                            
                                        <div className="modal-footer no-border">
                                        {!this.state.loading && <button type="submit" className="btn btn-primary">Save & Start Period</button>}
                                        </div>

                      

                                </form> 
                    
                        

                            </div>
                        </div>
                    </div>

                


                    <div className="col-md-8 overflow-auto h-300">
                          {!this.state.statement && <ActivityLoader/>}
                          {this.state.statement &&  <TrialBalance data={this.state.statement} intervalView={true}/>}

                    </div>

                    </div>



                    </div>

                    </div>{/** body end */}

                

                </PageContainer>
           
        );
    }
}

export default NewFinancialPeriod;