import React, { useContext, useEffect, useState } from 'react';
import ajaxSchool from '../remote/ajaxSchool';
import SchoolContext from './SchoolContext';

const SchoolDiscountContext = React.createContext();

export const SchoolDiscountConsumer = SchoolDiscountContext.Consumer;

export const SchoolDiscountProvider = (props) => {

    const [transactionListing, setTransactionListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [recurringListing, setRecurringListing] = useState(false);
    const {listFeesConfig, feesConfigListing} =useContext(SchoolContext);
    const [discountConfigured, setDiscountConfigured] = useState(true);

    useEffect(()=>{
        getRecurringDiscounts();
    }, [])

    useEffect(()=>{

        if(feesConfigListing)
        {
            setDiscountConfigured(feesConfigListing.discount_configured)
    
        }

    }, [feesConfigListing])

    useEffect(()=>{
       getSchoolDiscountTransactions();
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getSchoolDiscountTransactions=async()=>{
        setLoading(true)
        setTransactionListing(false)
        const server_response = await ajaxSchool.fetchRecentDiscounts(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setTransactionListing(server_response.details.list);
            setMetaData(server_response.details.meta);
        }else{
            setTransactionListing("404");
            setMetaData(false);
        }
    }

  


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);

        const server_response = await ajaxSchool.fetchRecentDiscounts(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setTransactionListing(transactionListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "Discount_reason":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        })
        
    }





    const getRecurringDiscounts=async()=>{
        setRecurringListing(false)
        const server_response = await ajaxSchool.fetchRecurringDiscounts([]);
        if(server_response.status==="OK"){
            setRecurringListing(server_response.details);
        }else{
            setRecurringListing("404");
        }
    }


    const refresh=()=>{
        resetData();
        getRecurringDiscounts();
    }


   

  
    return (
        <SchoolDiscountContext.Provider value={{
            transactionListing,
            metaData,
            loading,
            recurringListing,
            discountConfigured,

            refresh,
            goToNextPage,
            setData,
            resetData,
            filterData,
            getSchoolDiscountTransactions,
            getRecurringDiscounts,
            listFeesConfig
        }}
        >
            {props.children}
        </SchoolDiscountContext.Provider>
    );
}


export default SchoolDiscountContext;
