import React, { useEffect, useState } from 'react';
import ajaxExpenses from '../remote/ajaxExpenses';

const InvoicingContext = React.createContext();

export const InvoicingConsumer = InvoicingContext.Consumer;

export const InvoicingProvider = (props) => {

    const [transactionListing, setTransactionListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);


   

    useEffect(()=>{
       getInvoicingTransactions();
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getInvoicingTransactions=async()=>{
        setLoading(true)
        setTransactionListing(false)
        if(currentPage!==1){
            resetData()
            return;
        }
        const server_response = await ajaxExpenses.fetchInvoiceRecords(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setTransactionListing(server_response.details.list);
            setMetaData(server_response.details.meta);
        }else{
            setTransactionListing("404");
            setMetaData(false);
        }
    }

  


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);

        const server_response = await ajaxExpenses.fetchInvoiceRecords(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.list);
            setTransactionListing(transactionListing.concat(incoming));
            setMetaData(server_response.details.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "payment_reason":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        })
        
    }







  
    return (
        <InvoicingContext.Provider value={{
            transactionListing,
            metaData,
            loading,
            goToNextPage,
            setData,
            resetData,
            filterData,
            getInvoicingTransactions,
        }}
        >
            {props.children}
        </InvoicingContext.Provider>
    );
}


export default InvoicingContext;
