import React, { useCallback, useEffect, useState } from 'react';
import ajaxLedger from '../../remote/ajaxLedger';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import PageNav from '../Common/PageNav';
import SystemModal from '../Common/SystemModal';

const AccountTrail =(props)=> {

        const [accountId, setAccountId] = useState(props.accountId);
        const [page, setPage] = useState(1);
        const [openingDate, setOpeningDate] = useState(props.openingDate);
        const [closingDate, setClosingDate] = useState(props.closingDate);
        const [transactionList, setTransactionList] = useState(false);
        const [metaData, setMetaData] = useState(false);
        const [pagination, setPagination] = useState(false);
   


        useEffect(()=>{
            let isApiSubscribed = true;
            if(isApiSubscribed)
            {
                getTransaction();
            }
            return () => {
                isApiSubscribed = false;
            }
        }, [])

    

        useEffect(()=>{
            paginate()
        }, [metaData, page])
        
        useEffect(()=>{
            getTransaction();  
        }, [page])

        // useEffect(() => {
        //     const subscription = props.source.subscribe();
        //     return () => {
        //       // Clean up the subscription
        //       subscription.unsubscribe();
        //     };
        //   });

        const getTransaction = async()=>{
            setPagination(false);
            setTransactionList(false);
            const server_response = await ajaxLedger.filterAccountTransactions(accountId, page, openingDate, closingDate);
            if(server_response.status==="OK")
            {
                setTransactionList(server_response.details.list);
                setMetaData(server_response.details.meta);
            }else{
                setTransactionList("404");
            }
        }




        //==============NAVIGATION CONTROLS START===========================
        const onClickNext=()=>{
            //increment page numbers
            if(metaData.current_page*1+1<=metaData.total_pages*1)
            {
                setPage(page+1);
            //setTransactionList(false);
           // getTransaction();
            }

        }

        const onClickPage=(page)=>{
            setPage(page);
            
        }

        const onClickPrevious=()=>{

            if(metaData.current_page*1>1)
            {
                setPage(page-1);
                //setTransactionList(false);
                //getTransaction();
            }

        }

        const paginate=()=>{

            if(metaData)
            {

                setPagination(<PageNav
                totalPages={metaData.total_pages}
                pageList={metaData.list_of_pages}
                currentPage={page}
                onClickNext={onClickNext}
                onClickPage={onClickPage}
                onClickPrevious={onClickPrevious}
                />
                )
            
            }else{
                setPagination(false)
            }
        }

        //==============NAVIGATION CONTROLS END===========================


        return (
           
            <SystemModal id="mdl-account-trail" title={"Account Trail: " + props.accountName} type="modal-lg">
                        
                    <div className='table-responsive'>
                    {Array.isArray(transactionList) &&<table className="table table-bordered table-striped datatable table-ellipsis">
									
                        <thead>
                            <tr>
                            <th>LedgerNo</th> 
                            <th>Trans. Date</th> 
                            <th>Account</th>
                            <th>Description</th>
                            <th>Dr.</th>
                            <th>Cr.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionList.map((item, key)=>
                            <tr key={key}>
                                <td>{item.ledger_no}</td>
                                <td>{item.transaction_date.short_date}</td>
                                <td>{item.account.account_name}</td>
                                <td>{item.description}</td>
                                <td>{item.debit.amount>0?item.debit.amount_c:''}</td>
                                <td>{item.credit.amount>0?item.credit.amount_c:''}</td>

                            </tr>
                        )}
                       
                    </tbody>
                    </table>}
                    {transactionList==="404" && <AlertError type="info" message="Trail could not be detected"/>}
                    {!transactionList && <ActivityLoader/>}
                    {pagination}
                    </div>
                  
        
                
    
                        <div className="row">&nbsp;</div>
    
    
                
                        {/*                         
                        <div className="modal-footer no-border">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>                      
                        </div> */}
    
    
            
    
                </SystemModal>

                    )
             
}

export default AccountTrail;