import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import TableHeader from '../../Components/Common/TableHeader';
import PageContainer from '../../Components/Headings/PageContainer';
import LoadMoreButton from '../../Components/NavMenus/LoadMoreButton';
import ReportFilter from '../../Components/Report/ReportFilter';
import PopAssignFeesGroup from '../../Components/SchoolFees/PopAssignFeesGroup';
import PopBillStudent from '../../Components/SchoolFees/PopBillStudent';
import PopCustomBill from '../../Components/SchoolFees/PopCustomBill';
import SchoolBillingContext from '../../Context/SchoolBillingContext';
import ajaxSchool from '../../remote/ajaxSchool';
import functions from '../../util/functions';
import ButtonGroup from '../../Components/Common/Controls/ButtonGroup';

class BillingCenterPage extends Component {

    static contextType = SchoolBillingContext

    state={
        ledgerId:false,
        modal:false,
        loading:false,
        queuedStudent:false,
        info:''
    }
   

    // RenderLedgers = () => {
    //     const transactions = this.state.transactions;
    //     return (
    //         <>
    //             <ReportFilter
    //                 title="Manage ledger entries"
    //                 filterSelection={this.filterTransactions}
    //             />
               
    //         </>
    //     )
    // }


    onSelectRecord=(ledgerId)=>{
        this.setState({ledgerId})
    }

    handleRefresh=()=>{
        this.context.getSchoolBillingTransactions()
    }

    handleCustomBill=()=>{

        this.setState({
            modal:<PopCustomBill/>
        }, ()=>{functions.openPopup("mdl-custom-bill")})

    }


    handleAutoBill=()=>{
        this.setState({
            modal:<PopBillStudent/>
        }, ()=>{functions.openPopup("mdl-auto-bill")})
    }




    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>

                    <li>
                        <ButtonGroup title="Options">
                            <Link to="#" className="dropdown-item" onClick={this.handleAutoBill}>
                                <i className="fa fa-users mr-1"></i>&nbsp;Bill Students</Link>
                            
                   
                            <Link to="#" className="dropdown-item" onClick={this.handleCustomBill}>
                            <i className="fa fa-sync-alt mr-1"></i>&nbsp;Custom Billing</Link>

                            <Link to="/school/billing/config" className="dropdown-item">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Billing Settings</Link>

     
                            <Link to="#" className="dropdown-item" onClick={this.handleRefresh}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>

                          
                        </ButtonGroup>

                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

      handBillStudent=(studentId, key)=>{
            this.setState({
                loading:true,
                queuedStudent:studentId
            }, async()=>{
                const server_reponse = await ajaxSchool.billStudent(studentId);
                this.setState({
                    loading:false
                })
                if(server_reponse.status==="OK")
                {
                    this.context.studentListing[key].billing = server_reponse.details;

                    this.setState({
                        info:<AlertSuccess message={server_reponse.message}/>
                    }, ()=>{
                        setTimeout(() => {
                                this.setState({info:''})
                        }, 5000);
                    })
                   
                }else{

                    this.setState({
                        info:<AlertError message={server_reponse.message}/>
                    }, ()=>{
                        setTimeout(() => {
                                this.setState({info:''})
                        }, 5000);
                    })

                }
            })
      }
    

     


    render() {

        const {transactionListing, metaData, loading, goToNextPage} = this.context;

        return (
            <PageContainer 
                title="Billing Center"
                headerNav={<this.RenderControls/>}
                >
                {this.state.modal}
                <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card ">
                            <div className="card-body map-card">

                        <TableHeader
                            title="Student Billing"
                            subtitle="List of all students' billing records"
                        />

                        <LoadMoreButton
                          title={metaData.current_page + " of " + metaData.total_pages}
                          action={goToNextPage}
                          loading={loading}
                        />

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                          <div className="table-responsive table-ellipsi">
                            <div className="card-body p-0">
                                {this.state.info}
                                    {!transactionListing && <ActivityLoader />}
                            {Array.isArray(transactionListing) && <table className="table table-bordered text-nowrap mb-0 datatabl" id='table-billing'>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Reg NO</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Class</th>
                                        <th>Billing Batch</th>
                                        <th>Billed Amount</th>
                                        <th>Fees Vote</th>
                                        <th>Description</th>
                                        <th>Billing Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionListing.map((item, key) =>

                                        <tr
                                            className={`${item.ledger_id === this.state.ledgerId ? 'flag-row' : 'hot-tr'}`}
                                            style={{ cursor: 'default' }}
                                            key={key}
                                            onClick={() => this.onSelectRecord(item.ledger_id, item)}
                                            id={"student" + item.id}
                                        >
                                            <td>{key+1}</td>
                                            <td>
                                            <Link to={
                                                {
                                                    pathname:`/school/fees/students/profile/${item.student.id}`,
                                                    state:{studentId:item.student.id}
                                                }
                                                }>
                                                {item.student.reg_no}
                                                </Link>
                                                </td>
                                            <td>{item.student.first_name}</td>
                                            <td>{item.student.last_name}</td>
                                            <td>{item.class.class_name}</td>
                                            <td>{item.batch.batch_name}</td>
                                            <td>{item.amount.amount_c}</td>
                                            <td>{item.vote.vote_name}</td>
                                            <td>{item.description}</td>

                                            <td>{item.billing_date.short_date + " " + item.billing_date.time}</td>
                                        </tr>

                                    )}

                                </tbody>
                            </table>}
                        
                            {transactionListing === '404' && <AlertError type="info" message="No billing records found"/>}

                            </div>
                            </div>
                        </div>
                       
                       
                    </div>
                </div>
                </div>

                </div>
            </PageContainer>

        );
    }
}

export default BillingCenterPage;
