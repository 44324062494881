import React, { Component } from 'react';
import ajaxConfig from '../../../remote/ajaxConfig';
import ajaxUser from '../../../remote/ajaxUser';
import dictionary from '../../../util/dictionary';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class NewUser extends Component {

    state={
        firstName:'',
        lastName:'',
        roleId:'',
        email:'',
        phone:'',
        username:'',
        rolesList:''
    }

    componentDidMount() {
        this.get_roles();
    }


    onChangeFirstName=(event)=>{
        this.setState({
            firstName:event.target.value
        })
    }

    onChangeLastName=(event)=>{
        this.setState({
            lastName:event.target.value
        })
    }

    onChangeEmail=(event)=>{
        this.setState({
            email:event.target.value
        })
    }

    onChangeUsername=(event)=>{
        this.setState({
            username:event.target.value
        })
    }

    onChangePhone=(event)=>{
        this.setState({
            phone:event.target.value
        })
    }

    onChangeRole=(item)=>{
        this.setState({
            roleId:item.value
        })
    }

    get_roles=async()=>{
      const server_response = await ajaxConfig.filter_roles(0);
      if(server_response.status==="OK")
      {
        this.setState({
          rolesList:server_response.details.list
        })
      }else{
        this.setState({
            rolesList:"404"
        })
      }
    }


    confirmUser=async(event)=>{
        event.preventDefault();
  
        const {firstName, lastName, roleId, username, phone, email}=this.state;
        if(firstName.length>0 && lastName.length>0 && roleId>0 && username.length>0 && phone.length>0 && email.length>0)
        {
            const server_response = await ajaxUser.createUser(username, firstName, lastName, email, phone, roleId);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>,
                    firstName:'',
                    lastName:'',
                    username:'',
                    email:'',
                    phone:''
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    render() {

        const rolesList = this.state.rolesList;
        const optionsRolesList=[];
        if(rolesList && rolesList!=="404")
        {
            rolesList.map((item, key)=>
                optionsRolesList.push({label:item.role_name, value:item.id})
            )
        }


        return (
            
    <SystemModal id="mdl-new-user" title="New User">
    <form method="post" className="form-horizontal" onSubmit={this.confirmUser}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="First Name"
                            placeholder="First Name"
                            leftIcon="fa fa-user"
                            value={this.state.firstName}
                            onChange={this.onChangeFirstName} 
                            required
                            wrap
                            />
                <TextInput
                            type="text"
                            label="Last Name"
                            placeholder="Last Name"
                            leftIcon="fa fa-user"
                            value={this.state.lastName}
                            onChange={this.onChangeLastName} 
                            required
                            wrap
                            />

                 <TextInput
                            type="text"
                            label="Username"
                            placeholder="Username"
                            leftIcon="fa fa-user"
                            value={this.state.username}
                            onChange={this.onChangeUsername} 
                            required
                            wrap
                            />

                <TextInput
                            type="email"
                            label="Email Address"
                            placeholder="Email Address"
                            leftIcon="fa fa-envelope"
                            value={this.state.email}
                            onChange={this.onChangeEmail} 
                            required
                            wrap
                            />

                
                <TextInput
                            type="text"
                            label="Phone Number"
                            placeholder="Phone Number"
                            leftIcon="fa fa-phone"
                            value={this.state.phone}
                            onChange={this.onChangePhone} 
                            required
                            wrap
                            />

                <TextInput
                            type="select"
                            label="User Role"
                            placeholder="Select role"
                            leftIcon="fa fa-users"
                            onChange={this.onChangeRole}
                            options={optionsRolesList} 
                            required
                            wrap
                            />
           
       

           
    

            <div className="row">&nbsp;</div>


       
            <div className="row">&nbsp;</div>
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-success">Confirm New User</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default NewUser;