/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";
import apiCall from "./apiCall";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   
    async accountStatement(accountId, periodId, openingDate, closingDate, page)
    {

        let data={
            "account_id":accountId,
            "period_id":periodId,
            "opening_date":openingDate,
            "closing_date":closingDate,
            "page":page
        }
        try{
            let response= await fetch(apiHost + 'reports/account-statement',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async getHighIncomeStatement(periodId, tagId, openingDate, closingDate)
    {

        let data={
            "period_id":periodId,
            "tag_id":tagId,
            "type":"high",
            "opening_date":openingDate,
            "closing_date":closingDate
        }
        try{
            let response= await fetch(apiHost + 'reports/income-statement/high',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async getTrialBalance(periodId, openingDate, closingDate)
    {

        let data={
            "period_id":periodId,
            "type":"high",
            "opening_date":openingDate,
            "closing_date":closingDate
        }
        try{
            let response= await fetch(apiHost + 'reports/trial-balance',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


    async getBalanceSheet(periodId, openingDate, closingDate)
    {

        let data={
            "period_id":periodId,
            "type":"high",
            "opening_date":openingDate,
            "closing_date":closingDate
        }
        try{
            let response= await fetch(apiHost + 'reports/balance-sheet',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },



    async fetchVendorStatement(vendorId, periodId, openingDate, closingDate, page)
    {

        let data={
            "vendor_id":vendorId,
            "period_id":periodId,
            "opening_date":openingDate,
            "closing_date":closingDate,
            "page":page
        }


        const response = await apiCall("reports/vendor-statement", data)

        return response

    },

} 