import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import PageNav from '../../Components/Common/PageNav';
import SilentText from '../../Components/Common/SilentText';
import TextInput from '../../Components/Common/TextInput';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';
import ReportFilter from '../../Components/Report/ReportFilter';
import EditLedger from '../../Components/Transaction/EditLedger';
import ajaxReport from '../../remote/ajaxReport';
import { restoreTable } from '../../util/script/customTables';
const exportFormats =['copy', 'excel', 'csv', 'pdf', 'print']

class AccountStatementPage extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:"",
        page:1,
        metaData:false,
        pagination:false,
        activeTab:"main",
        currentRecord:false,
        accountId:'',
        accountInfo:false,
        openingBalance:false,
        openingDate:'',
        closingDate:''
    }

    componentDidMount() {


        if(!this.props.match.params.accountId)
		{
			this.props.history.push('/404');
		}else{
			this.setState({
				accountId:this.props.match.params.accountId
			}, ()=>{
                this.listTransactions();

			})
        }

    }

    listTransactions=async()=>{
        const server_response = await ajaxReport.accountStatement(this.state.accountId, null, this.state.openingDate, this.state.closingDate, this.state.page);
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details.list?server_response.details.list:"404",
                metaData:server_response.details.meta,
                accountInfo:server_response.details.account_info,
                openingBalance:server_response.details.opening_balance
            })
        }else{
            this.setState({
                transactions:"404",
                info:<AlertError type="info" message={server_response.message}/>
            })
        }
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }


    filterTransactions=(openingDate, closingDate)=>{
      restoreTable("table-statement");
        this.setState({
          openingDate,
          closingDate,
          transactions:false,
          pagination:false,
          metaData:false
        }, ()=>this.listTransactions())
    }



  onClickTab=(value)=>{
      this.setState({activeTab:value})
  }



  RenderLedgers=()=>{
        const transactions = this.state.transactions;
        const openingBalance = this.state.openingBalance;
        const accountInfo = this.state.accountInfo;
      return(
        <>
        {!transactions && <ActivityLoader/>}


            <div className="table-responsive table-ellipsis">
            {transactions && transactions!=="404" && <table id="table-statement" className="table datatable table-striped text-nowrap mb-0" export-format={exportFormats}>
                <thead>
                    <tr>
                    <th>Record Date</th>                                   
                    <th>Trans Date</th> 
                    <th>#Ref</th>
                    <th>Description</th>
                    <th>Dr.</th>
                    <th>Cr.</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>
                {(accountInfo.account_category.category_id*1===3 || accountInfo.account_category.category_id*1===4) &&
                  <tr>
                     <td>&nbsp;</td>
                     <td>&nbsp;</td>
                     <td>&nbsp;</td>
                     <td><b><i>Opening balance</i></b></td>
                     <td>&nbsp;</td>
                     <td>&nbsp;</td>
                     <td><b>{openingBalance.amount_c}</b></td>
                  </tr>
                }
                {transactions.map((item, key)=>
                 <tr 
                    className={`${item.ledger_id===this.state.transactionId?'flag-row':'hot-t'}`}
                    style={{cursor:'default'}}
                    key={key}
                    onClick={()=>this.onSelectRecord(item.ledger_id, item)}
                 >
                     <td>{item.created_at.short_date} {item.created_at.time}
                     {/* <br/><SilentText text={"Ledger No. " + item.ledger_grouping}/> */}
                     </td>
                     <td>{item.transaction_date.short_date}</td>
                     <td>{item.transaction_ref} - 
                     <Link to={ 
                       {
                          pathname:`/finder/${item.ledger_grouping}`,
                          state:{tab:'ledger'}
                        }
                     }>Ledger No. {item.ledger_grouping}</Link></td>
                     <td>{item.description}</td>
                     <td>{item.debit.amount>0?item.debit.amount_c:""}</td>
                     <td>{item.credit.amount>0?item.credit.amount_c:""}</td>
                     <td><b>{item.balance.amount_c}</b></td>

                </tr>
                )}
                   
            
                
                </tbody>
            </table>}
                {transactions==="404" && this.state.info}
                {/* {this.state.pagination} */}



            </div>


    
    </>

      )
  }


  


    render() {
      const accountInfo = this.state.accountInfo;

        return (
          <PageContainer title="Statement Of Accounts">
          <div className="row">
              <div className="col-md-12">

              <ReportFilter 
                title={accountInfo.account_name}
                filterSelection={this.filterTransactions}
                />


              <div className="card">
                 
                  <div className="card-body">
                    {this.state.activeTab==="main" && <this.RenderLedgers/>}
                    {this.state.activeTab==="edit" && <EditLedger record={this.state.currentRecord}/>}
                </div>
              </div>

						
          </div>
          </div>
          </PageContainer>
                   
        );
    }
}

export default AccountStatementPage;