import React from "react";
import { Link } from "react-router-dom";
import InitialAvatar from "../../Common/InitialAvatar";

const ProfileCard=(props)=>{


    return(
        <>
         <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center"> 
                                {props.profile.photo==="none" && <div className="profile-user-img img-fluid img-circle">
                                <InitialAvatar value={props.profile.initials}/>
                              </div>}

                                  {props.profile.photo!=="none" && <img className="profile-user-img img-fluid img-circle"
                                        src={props.profile.photo}
                                        alt="Student"
                                    />}
                                </div>

                                <h3 className="profile-username text-center">{props.profile.full_name}</h3>

                                <p className="text-muted text-center">{props.profile.gender}</p>

                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Class</b> <span className="float-right">{props.profile.class.class_name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Reg No.</b> <span className="float-right">{props.profile.reg_no}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Payment Code</b> <span className="float-right">{props.profile.payment_code}</span>
                                    </li>
                                </ul>

                                <span className="btn btn-primary btn-block"><b>{props.profile.status_name}</b></span>
                            </div>

                        </div>



                        <div className="card card-primary">
                            {/* <div className="card-header">
                                <h3 className="card-title">Other Details</h3>
                            </div> */}

                            <div className="card-body">
                                <strong><i className="fas fa-book mr-1"></i> Fees Group</strong>

                                <p className="text-muted">
                                    {props.profile.fees_group? props.profile.fees_group.group_name + " " + props.profile.fees_group.description : "Not assigned"}
                                </p>

                                <hr />

                                <strong><i className="fas fa-phone mr-1"></i> Home Contact</strong>

                                <p className="text-muted">{props.profile.home_contact}</p>

                                <hr />

                            
                               {props.profile.status*1===1 &&  <strong><Link to="#" onClick={props.methods.terminateBilling} className="text-danger"><i className="fas fa-handshake mr-1"></i> Deactivate Billing</Link></strong>}
                               {props.profile.status*1===0 &&  <strong><Link to="#" onClick={props.methods.activateBilling} className="text-success"><i className="fas fa-handshake mr-1"></i> Activate Billing</Link></strong>}


                                {/*<p className="text-muted">
                                    <span className="tag tag-danger">UI Design</span>
                                    <span className="tag tag-success">Coding</span>
                                    <span className="tag tag-info">Javascript</span>
                                    <span className="tag tag-warning">PHP</span>
                                    <span className="tag tag-primary">Node.js</span>
                                </p>

                                <hr />

                                <strong><i className="far fa-file-alt mr-1"></i> Notes</strong>

                                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum enim neque.</p>
                            */}
                           </div>

                        </div>
        </>
    )

}


export default ProfileCard;