import { useContext } from "react";
import ScrollFilterBar from "../Common/Filter/ScrollFilterBar";
import SchoolContext from "../../Context/SchoolContext";
import FilterSelectInput from "../Common/Filter/FilterSelectInput";

const StudentFilter = ()=>{

    const { classListing,  residenceListing} = useContext(SchoolContext);
    const { resetData,  filterStudent} = useContext(SchoolContext)


        const optionsclassList=[{title:"All Classes", value:"0"}];
        if(Array.isArray(classListing))
        {
            classListing.forEach((item)=>
                optionsclassList.push({title:item.class_name, value:item.reference_id})
            )
        }
    
        const optionsResidence=[{title:"All Residences", value:"0"}]
        if(Array.isArray(residenceListing)){
          residenceListing.forEach(item =>{
            optionsResidence.push({title:item.residence_name, value:item.reference_id})
          })
        }
    
        return (
            <ScrollFilterBar
                onReset={resetData}
                onConfirm={filterStudent}
                firstScreen={
                  {
                    inputs:[
                        (onSet, key)=><FilterSelectInput
                          key={key}
                          label="Filter By Class"
                          options={optionsclassList}
                          onChange={onSet}
                          name="studentClass"
                        />,
                        (onSet, key)=><FilterSelectInput
                        key={key}
                        label="Filter By Residence"
                        options={optionsResidence}
                        onChange={onSet}
                        name="studentResidence"
                      />
                    ],
                    names:["studentClass", "studentResidence"]
                  }
                }
            />
        )
    

}

export default StudentFilter;