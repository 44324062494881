import React, { Component, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountsContext from '../../Context/AccountsContext';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopDefineStructure=(props)=>{

    const [info, setInfo] = useState("")
    const [loading, setLoading] = useState(false)
    const [voteId, setVoteId] = useState("");
    const [amount, setAmount] = useState("");

    const {feesVotes} = useContext(SchoolContext);

  
    const onChangeVote=(item)=>{
            setVoteId(item.value)
    }




    const confirm=async(event)=>{
        event.preventDefault();
  
        if(voteId>0 && amount.length>0)
        {   
            setInfo(<ActivityLoader/>)
            const server_response = await ajaxSchool.defineGroupStructure(props.groupId, voteId, amount);
            if(server_response.status==="OK")
            {
                props.refresh()
                setInfo(<AlertSuccess message={server_response.message}/>)
                
            }else{
                setInfo(<AlertError message={server_response.message}/>)
        
            }

        }else{
           
            setInfo(<AlertError message={dictionary._completeFields}/>)
        
        }
    }





        const optionsVotes=[];
        if(Array.isArray(feesVotes))
        {
            feesVotes.map((item, key)=>
                optionsVotes.push({label:item.vote_name, value:item.id})
            )
        }


   

        return (
            
        <SystemModal id="mdl-define-structure" title="Define Fees Structure">
        <form method="post" className="form-horizontal" onSubmit={confirm}>
               {info}

               
                <TextInput
                    type="select"
                    label="Fees Vote"
                    placeholder="Select fees vote"
                    leftIcon="fa fa-tag"
                    onChange={onChangeVote}
                    options={optionsVotes} 
                    required
                    wrap
                    />

                 <TextInput
                    type="number"
                    label="Amount"
                    placeholder="Enter Vote Name"
                    leftIcon="fa fa-money-bill"
                    onChange={(e)=>setAmount(e.target.value)}
                    required
                    value={amount}
                    wrap
                    />
        



            
            </form> 
            
            <div className="modal-footer no-border">
                <button className="btn btn-primary" type='submit' onClick={confirm} disabled={loading}>
                   {loading?<ActivityLoader inline size={20}/> : "Update Structure"}
                </button>
            </div>

   

   

    </SystemModal>
        );
    }


export default PopDefineStructure;