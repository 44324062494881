import React, { useEffect, useState } from "react"

const FilterSelectInput=(props)=>{

    const [value, setValue] = useState("");

    useEffect(()=>{
		props.onChange(props.name, value)
	}, [value])


    return(
        <div className={"col-md-3"}>

            <div className="form-group mb-lg-0">
					<label className="">{props.label}</label>
					<select
						className="form-control select2-flag-search"
						data-placeholder={props.placeholder}
						onChange={(e)=>setValue(e.target.value)}
					>
						<option value="">-Select-</option>
						{props.options.map((item, key) =>
							<option key={key} value={item.value}>{item.title}</option>
						)}
					</select>
				</div>


	</div>
    )

}

export default FilterSelectInput