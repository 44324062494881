import React, { Component } from 'react';
import ajaxAccountChart from '../../remote/ajaxAccountChart';
import ajaxBanking from '../../remote/ajaxBanking';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

class NewAccount extends Component {

    state={
        accountCategory:"",
        balanceAccount:false,
        isSubAccount:true,
        accountNumber:"",
        accountTitle:"",
        accountDescription:"",
        mainAccountId:"",
        accountCategoryList:false,
        accountListing:false,
        bankListing:false,
        bankId:"",
        openingBalance:"",
        balanceDate:"",
        info:""
    }



    componentDidMount() {
        this.getaccountCategories();
        this.getBanks();
    }



    getaccountCategories=async()=>{
        const server_response = await ajaxAccountChart.listAccountCategories();
        if(server_response.status==="OK")
        {
            this.setState({ accountCategoryList:server_response.details })
        }else{
            this.setState({
                accountCategoryList:"404"
            })
        }
    }


    getAccounts=()=>{
        this.setState({
            accountListing:false
        }, async()=>{
            const server_response = await ajaxAccountChart.listAccountsForCategory(this.state.accountCategory);
            if(server_response.status==="OK")
            {
                this.setState({ accountListing:server_response.details })
            }else{
                this.setState({
                    accountListing:"404"
                })
            }
        })
      
    }


    getBanks=()=>{
        this.setState({
            bankListing:false
        }, async()=>{
            const server_response = await ajaxBanking.listBanks();
            if(server_response.status==="OK")
            {
                this.setState({ bankListing:server_response.details })
            }else{
                this.setState({
                    bankListing:"404"
                })
            }
        })
      
    }


    changeIsSubAccount=()=>{
        this.setState({
            isSubAccount:!this.state.isSubAccount
        })
    }

    onChangeAccountTitle=(event)=>{
        this.setState({
            accountTitle:event.target.value
        })
    }

    onChangeAccountNumber=(event)=>{
        this.setState({
            accountNumber:event.target.value
        })
    }

    onChangeAccountDescription=(event)=>{
        this.setState({
            accountDescription:event.target.value
        })
    }

    onChangeAccountCategory=(item)=>{
        this.setState({
            accountCategory:item.value,
            mainAccountId:''
        }, ()=>this.getAccounts())
    }

    onChangeBank=(item)=>{
        this.setState({
            bankId:item.value,
        })
    }

    onChangeBalance=(event)=>{
        this.setState({
            openingBalance:event.target.value
        })
    }

    onChangeBalanceDate=(event)=>{
        this.setState({
            balanceDate:event.target.value
        })
    }

    /** Gets main umbrella accounts for which new accounts can be categorized under */
    onChangeMainAccount=(item)=>{
        this.setState({
            mainAccountId:item.value
        }, ()=>{
            if(this.state.mainAccountId*1===1 || this.state.mainAccountId*1===2)
            {
                this.setState({balanceAccount:true})
            }else{
                this.setState({balanceAccount:false})
            }
        })
    }



    onConfirmAccount=async(event)=>{
        event.preventDefault();
        const {accountNumber, accountTitle, accountDescription, mainAccountId, accountCategory, openingBalance, balanceDate, bankId}=this.state;
        if(accountCategory.length<=0)
        {
            this.setState({
                info:<AlertError message="Enter account category an try again"/>
            })
            return false;
        }

        if(this.state.isSubAccount && mainAccountId.length<=0)
        {
            this.setState({
                info:<AlertError message="This is a sub account, please select a main account and try again"/>
            })
            return false;
        }

        if(this.state.balanceAccount && (openingBalance.length<=0 || balanceDate.length<=0))
        {

            this.setState({
                info:<AlertError message="Enter opening balance and balance date then try again"/>
            })
            return false;
        }

        const server_response = await ajaxAccountChart.createAccount(
            accountNumber, 
            accountTitle, 
            accountCategory, 
            accountDescription, 
            mainAccountId,
            openingBalance,
            balanceDate,
            bankId
            );
        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>,
                accountTitle:"",
                accountNumber:"",
                accountDescription:""
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }

    }



    RenderAccountCategory=()=>{
        const accountCategoryList = this.state.accountCategoryList;
        const optionsAccountCategory=[];
        if(accountCategoryList && accountCategoryList!=="404")
        {
            accountCategoryList.map((item, key)=>
            optionsAccountCategory.push({label:item.value + ' ' + item.category_name, value:item.category_id})
            )
        }
        
        return  <TextInput
                        label="Account Category"
                        type="select" 
                        leftIcon="fa fa-folder"
                        placeholder="Select account category...." 
                        options={optionsAccountCategory}
                        onChange={this.onChangeAccountCategory}
                        leftComponent={<i className="fa fa-folder" onClick={this.getaccountCategories}></i>}
                        loading={!accountCategoryList?true:false}
                        />
        
    }


    RenderAccountListing=()=>{
        const accountListing = this.state.accountListing;
        const optionsAccountListing=[];
        if(accountListing && accountListing!=="404")
        {
            accountListing.map((item, key)=>
            optionsAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return  <TextInput
                        label="Is a sub account of"
                        type="select" 
                        leftIcon="fa fa-university"
                        placeholder="Select main account...." 
                        options={optionsAccountListing}
                        onChange={this.onChangeMainAccount}
                        leftComponent={<i className="fa fa-university" onClick={this.getAccounts}></i>}
                        loading={!accountListing?true:false}
                        />
        }





    RenderBalanceAccount=()=>{
        return  <>
                    <TextInput
                            type="number"
                            label="Opening Balance"
                            placeholder="Enter Amount"
                            leftIcon="fa fa-credit-card"
                            value={this.state.openingBalance}
                            onChange={this.onChangeBalance} 
                            required
                            />

                    <TextInput
                            type="date"
                            label="Balance Date"
                            placeholder="Date"
                            leftIcon="fa fa-calendar"
                            value={this.state.balanceDate}
                            onChange={this.onChangeBalanceDate} 
                            required
                            />
                

            </>
    }


    RenderBankField=()=>{
        const bankListing = this.state.bankListing;
        const optionsBankListing=[];
        if(bankListing && bankListing!=="404")
        {
            bankListing.map((item, key)=>
                optionsBankListing.push({label:item.account_number + ' ' + item.bank_name, value:item.bank_id})
            )
        }
        
        return   <TextInput
                        label="Bank Account"
                        type="select" 
                        leftIcon="fa fa-university"
                        placeholder="Select bank...." 
                        options={optionsBankListing}
                        onChange={this.onChangeBank}
                        leftComponent={<i className="fa fa-university" onClick={this.getBanks}></i>}
                        loading={!bankListing?true:false}
                        />
        
    }







render() {
    return (
        <div>


    <form method="post" className="form-horizontal" onSubmit={this.onConfirmAccount}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Account number"
                            placeholder="Account number"
                            leftIcon="fa fa-university"
                            value={this.state.accountNumber}
                            onChange={this.onChangeAccountNumber} 
                            required
                            />
                <TextInput
                            type="text"
                            label="Account title"
                            placeholder="Account title"
                            leftIcon="fa fa-university"
                            value={this.state.accountTitle}
                            onChange={this.onChangeAccountTitle} 
                            required
                            />
           
           <TextInput
                            type="textarea"
                            label="Account description"
                            placeholder="Account description. Eg. ( What does this account track ? )"
                            leftIcon="fa fa-list"
                            value={this.state.accountDescription}
                            onChange={this.onChangeAccountDescription} 
                            required
                            />

           
        
        

       

       
        {/* <div className="row">&nbsp;</div>

         <div className="form-group">
            <label for="checkbox" className="col-md-4 control-label">Sub-account?</label>
            <div className="col-sm-8">
                
                <div className="checkbox-inline1"><label>
                			<input onChange={this.changeIsSubAccount} type="checkbox" name="IncludeInChartOfAccounts" checked={this.state.isSubAccount}/>
                        </label>
                </div>

                
                    
            </div>
        </div> */}

        <div className="row">&nbsp;</div>

       <this.RenderAccountCategory/>
        
        {this.state.isSubAccount && <this.RenderAccountListing/>}
        {this.state.mainAccountId*1===1 && <this.RenderBankField/>}


        <div className="row">&nbsp;</div>

        {this.state.balanceAccount && <this.RenderBalanceAccount/>}


        <div className="row">&nbsp;</div>
        
         <div className="modal-footer no-border">
            <button type="submit" className="btn btn-primary">Confirm New Account</button>
        </div>

    </form> 

   

</div>

        );
    }
}

export default NewAccount;