import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

class PopClassFeesGroup extends Component {

    static contextType = SchoolContext

    state={
       classId:"",
       residenceId:"",
       gender:"",
       feesGroupId:this.props.groupId,
       loading:false,
       info:""
    }


   
    onChangeClass=(item)=>{
        this.setState({
            classId:item.value
        })
    }


    onChangeResidence=(item)=>{
        this.setState({
            residenceId:item.value
        })
    }


    onChangeGender=(item)=>{
        this.setState({
            gender:item.value
        })
    }



    confirmSync=async(event)=>{
        event.preventDefault();
  
        const {classId, residenceId, feesGroupId, gender}=this.state;
        if(classId.length>0 && residenceId.length>0 && feesGroupId>0 && gender.length>0)
        {   
            this.setState({info:<ActivityLoader/>})
            const server_response = await ajaxSchool.createBillingConfig({
                    "class_id": classId, 
                    "fees_group_id":feesGroupId, 
                    "residence_id": residenceId,
                    "gender": gender
                });
            if(server_response.status==="OK")
            {
                this.context.listAllStudents();
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


   

   
    RenderResidence=()=>{

        const { residenceListing } = this.context

        const options=[];

        if(Array.isArray(residenceListing)){
            residenceListing.forEach((item)=>{
                options.push({label:item.residence_name, value: item.id})
            })
        }
        
        
        return <TextInput
                    label="Residence"
                    type="select" 
                    leftIcon="fa fa-map-pin"
                    placeholder="Select residence...." 
                    options={options}
                    onChange={this.onChangeResidence}
                    wrap
                    />
        
        }




    RenderGender=()=>{


        const options=[
            {label:"Female", value:"Female"},
            {label:"Male", value:"Male"}
        ];
        
        return <TextInput
                    label="Gender"
                    type="select" 
                    leftIcon="fa fa-user"
                    placeholder="Select gender...." 
                    options={options}
                    onChange={this.onChangeGender}
                    wrap
                    />
    
    }




    render() {


        const classList = this.context.classListing;
        const optionsclassList=[];
        if(Array.isArray(classList))
        {
            classList.map((item, key)=>
                optionsclassList.push({label:item.class_name, value:item.reference_id})
            )
        }


        return (
            
    <SystemModal id="mdl-group-fees-assign" title="Assign to Group">
        <form method="post" className="form-horizontal" onSubmit={this.confirmSync}>
               {this.state.info}
              
               <TextInput
                            type="select"
                            label="Select Class"
                            placeholder="Select class"
                            leftIcon="fa fa-users"
                            onChange={this.onChangeClass}
                            options={optionsclassList} 
                            required
                            wrap
                            />



                <this.RenderResidence/>

                <this.RenderGender/>
            </form> 
            
            <div className="modal-footer no-border">
               
                <button type="submit" className="btn btn-primary" onClick={this.confirmSync}>Assign Fees Group</button>
            </div>

   

   

    </SystemModal>
        );
    }
}

export default PopClassFeesGroup;