/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   
    async constructChart()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'account-chart/construct',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


    async listAccountCategories()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'account-chart/category/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


    async listAccountsForCategory(categoryId)
    {

        let data={
            "category_id":categoryId,
        }
        try{
            let response= await fetch(apiHost + 'account-chart/category/account/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


    async createAccount(accountNumber, accountName, categoryId, description, mainAccount, openingBalance, balanceDate, bankId)
    {

        let data={
            account_name:accountName,
            account_number:accountNumber,
            category_id:categoryId,
            description:description,
            main_account_id:mainAccount,
            opening_balance:openingBalance,
            balance_date:balanceDate,
            bank_id:bankId
        }
        try{
            let response= await fetch(apiHost + 'account-chart/create',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async importAccounts(accountsFile)
    {

        let data={
            "accounts_file":accountsFile,
        }
        try{
            let response= await fetch(apiHost + 'account-chart/import',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },



    async updateAccount(accountNumber, accountName, description, accountId)
    {

        let data={
            account_name:accountName,
            account_number:accountNumber,
            account_id:accountId,
            description:description,
        }
        try{
            let response= await fetch(apiHost + 'account-chart/update',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },
    

    async deleteAccount(accountId)
    {

        let data={
            account_id:accountId
        }
        try{
            let response= await fetch(apiHost + 'account-chart/delete',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

} 