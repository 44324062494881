import React, { Component, useContext } from 'react';
import SchoolContext from '../../Context/SchoolContext';
import DashCard from '../Common/DashCard';
import TitleValueCard from '../Common/TitleValueCard';

const FeesSummary =()=> {

    const {
        totalInvoices, 
        totalPaid, 
        collectionPercentage,  
        totalBilledStudents,
        totalStudents,
        feesQuickStat
    } = useContext(SchoolContext);

   
    const invoiced_percentage = feesQuickStat?feesQuickStat.projection_achievement + "%" : "..."



        return (
            <div class="row row-sm">


                <DashCard
                    title="Billed Students"
                    value={totalBilledStudents}
                    href="#"
                    caption={"Student Popln: " + totalStudents}
                    progress="100%"
                    progressbg="bg-primary"
                    icon="fa fa-users"
                    color="text-primary"
                />

                <DashCard
                    title="Total Amount Invoiced"
                    value={feesQuickStat? feesQuickStat.total_amount_invoiced.amount_p : "..."}
                    href="#"
                    caption={"Budget Score: " + invoiced_percentage}
                    progress="100%"
                    progressbg="bg-success"
                    icon="fa fa-bookmark"
                    color="text-success"
                />


                <DashCard
                    title="Total Discounts"
                    value={feesQuickStat?feesQuickStat.total_amount_discounted.total_p:"..."}
                    href="/school/fees/discounting"
                    caption={feesQuickStat?feesQuickStat.percentage_discounted + "% of Total Invoiced amount":"..."}
                    progress={feesQuickStat?feesQuickStat.percentage_discounted +"%" : "70%"}
                    progressbg="bg-warning"
                    icon="fa fa-percentage"
                    color="text-warning"
                />


                <DashCard
                    title="Termly Amount Paid"
                    value={feesQuickStat?feesQuickStat.total_amount_paid.amount_p : "..."}
                    href="/school/fees/payments"
                    progress={feesQuickStat?feesQuickStat.percentage_paid +"%" : "100%"}
                    progressbg="bg-danger"
                    caption={feesQuickStat?feesQuickStat.percentage_paid + "% total collection":"..."}
                    icon="fa fa-handshake"
                    color="text-danger"
                />

            </div>
        );
    
}

export default FeesSummary;