import React, { Component, useContext, useEffect, useState } from 'react'
import CardTitle from '../../Components/Common/CardTitle'
// import ActivityLoader from '../../Components/Common/ActivityLoader'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import ContentHeader from '../../Components/Headings/ContentHeader'
// import AccountTimelineItem from '../../Components/Report/AccountTimelineItem'
import Basaries from '../../Components/SchoolFees/Basaries'
import ClassBreakDown from '../../Components/SchoolFees/ClassBreakDown'
import FeesGraph from '../../Components/SchoolFees/FeesGraph'
import FeesGroupsCard from '../../Components/SchoolFees/FeesGroupsCard'
import FeesSummary from '../../Components/SchoolFees/FeesSummary'
import RecentStudents from '../../Components/SchoolFees/RecentStudents'
import SchoolContext from '../../Context/SchoolContext'
import { Link } from 'react-router-dom'
import PopChangeBatch from '../../Components/SchoolFees/PopChangeBatch'
import functions from '../../util/functions'
import ajaxSchool from '../../remote/ajaxSchool'

const CollectionOverviewPage =()=> {
    const {batch, setBatchId} = useContext(SchoolContext);
    const [modal, setModal] = useState(false);

    useEffect(()=>{
      if(modal){
        functions.openPopup("mdl-change-batch")
      }
    }, [modal])

    const handleSwitchBatch=()=>{
        setModal(<PopChangeBatch
                    title="Switch Batch"
                    commandTitle="Confirm Switch"
                    onConfirm={switchBatch}
                />)
    }

    const switchBatch=async (batchId)=>{

      const server_response = await ajaxSchool.switchBillingBatch(batchId);
      if(server_response.status==="OK"){
        functions.closePopup("mdl-change-batch");
        setModal(false)
        setBatchId(batchId)

      }

     
    }

    return (
      <>
        <TopBar />
        <LeftSideBar />
        <div className='content-wrapper'>
          {modal}
          <ContentHeader 
              title='School Fees'
              subtitle={batch.batch_name}
              titleElement={<Link to="#" onClick={handleSwitchBatch} title='Switch Billing Batch'><i className='fa fa-cog'></i></Link>}
               />
          <section className='content'>
            <div className='container-fluid'>
              <FeesSummary />

              <div className='row row-sm'>
                <div className='col-sm-12 col-xl-8 col-lg-8'>
                  <FeesGraph />
                </div>

                <div className='col-sm-12 col-xl-4 col-lg-4'>
                  <ClassBreakDown/>
                </div>
              </div>

              <div className='row row-sm'>
                <div className='col-sm-12 col-xl-5 col-lg-5'>
                  <FeesGroupsCard/>
                </div>

                <div className='col-sm-12 col-xl-7 col-lg-7'>
                  <RecentStudents />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }


export default CollectionOverviewPage
