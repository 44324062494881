import React, { Component } from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border:0
    },

    centerStyle:{
        top: '50%',
    }
  };

  const centerStyles={
        content: { 
        // top:'auto',
        // bottom:'auto',
        // right:0,
        // left:0,
        // verticalAlign: 'middle',
        // position:'absolute',
       
        },
        overlay:{
            // backgroundColor: 'rebeccapurple',
            zIndex:1000
        },
        ReactModal:{
            display:'none'
        }
  }
class CustomModal extends Component {

    state={
        modalIsOpen:true
    }

    openModal=()=>{
        this.setState({
            modalIsOpen:true
        })
       
      }
    
    afterOpenModal=()=>{
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
      }
    
    closeModal=()=>{
        this.setState({
            modalIsOpen:false
        })
      }


      comp=()=>{
        const props=this.props;
          return (
            <div className="modal-content">
            <div className="modal-header">
                <button type="button" onClick={this.closeModal} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="exampleModalLabel">{props.title || "YEXP"}</h4>
            </div>
            <div class="modal-body">
                {props.bodyComponent}

            </div>


            {props.footerComponent && <div class="modal-footer">
                {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-danger" >Confirm Delete</button> */}
                {props.footerComponent}
            </div>}

        </div>
          )
      }


    render() {
        const props=this.props;

        return (
            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
           style={this.props.custom?customStyles:centerStyles}
           role="document"
          className={`${!this.props.custom?'modal-dialog modal-dialog-centered':''}`}
            contentLabel="YEXP System"
        >

                {props.custom && 
                        <div className="modal-dialog" role="document"> 
								<this.comp/>
						</div>}

                {!props.custom && <this.comp/>}


        </Modal>
        );
    }
}

export default CustomModal;