import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SchoolContext from '../../Context/SchoolContext'
// import Chart from 'react-apexcharts'
import ActivityLoader from '../Common/ActivityLoader'
import CardTitle from '../Common/CardTitle'

const FeesVotes =(props)=> {
 
    const {feesVotes} = useContext(SchoolContext);
    const [selectedVote, setSelectedVote] = useState(false)
    useEffect(()=>{
       props.onSelect(selectedVote)
    }, [selectedVote])

    
    return (
      <>
        <div className="card">
          <CardTitle
            title="Fees Votes"
            subtitle="List of fees votes with income balances"
          />

          <div className="card-body p-0 overflow-auto" style={{height:"325px"}}>
            {Array.isArray(feesVotes) && <ul className="products-list product-list-in-card pl-2 pr-2">
              {feesVotes.map((item, key)=><li onClick={()=>setSelectedVote(item.id)}
                className={`item link ${selectedVote===item.id?'flag-row':''}`} key={key}>
               
                <div className="product-info">
                  <Link to="#" className="product-title">{item.vote_name}
                    <span className="badge badge-warning float-right mr-3">UGX. {item.collection_account.balance.amount_p}</span>
                  </Link>
                  <span className="product-description">
                    <i className='fa fa-arrow-down text-success'/> {item.collection_account.account_name}
                  </span>
                  <span className="product-description">
                    <i className='fa fa-exchange-alt text-danger'/> {item.asset_account.account_name}
                  </span>
                </div>
              </li>)}
              
            </ul>}
            {!feesVotes && <ActivityLoader/>}
          </div>
        
        </div>
       
      </>
    )
  
}

export default FeesVotes
