import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ajaxAccountChart from '../../remote/ajaxAccountChart';
import functions from '../../util/functions';
import ActivityLoader from '../Common/ActivityLoader';
import ItemNotFound from '../Common/ItemNotFound';
import WarnDelete from '../Common/WarnDelete';
import EditAccount from './EditAccount';

class AccountListing extends Component {

    state={
        accountListing:false,
        mdlEdit:false,
        mdlDelete:false
    }

    componentDidMount() {
       this.getChart();
    }


    onClickEdit=(item)=>{
        this.setState({
            mdlEdit:<EditAccount item={item}/>
        }, ()=>{
            functions.openPopup("mdl-edit-account")
        })
    }

    onClickDelete=(item)=>{

        this.setState({
          mdlDelete:false
        }, ()=>{
  
          this.setState({
            mdlDelete:<WarnDelete 
                          title="Delete Account" 
                          description={"Confirm that you want to delete this account("+ item.account_number + " ) from the chart of accounts. Please make sure this account has no transactions."}
                          id="mdl_delete"
                          buttonType="danger"
                          onConfirm={()=>this.onConfirmDelete(item.account_id)}
                          />
          }, ()=>{
              functions.openPopup("mdl_delete")
          })
  
        })
  
      }

      onConfirmDelete=async(account_id)=>{
        const server_response = await ajaxAccountChart.deleteAccount(account_id);
        if(server_response.status==="OK")
        {
          functions.closePopup("mdl_delete");
          alert(server_response.message)
        }else{
          alert(server_response.message)
        }
  
    }
  
  

    getChart=async()=>{
        const server_response = await ajaxAccountChart.constructChart();
        if(server_response.status==="OK")
        {
            this.setState({
                accountListing:server_response.details
            }, ()=>this.setChartNav())
        }else{
            this.setState({
                accountListing:"404"
            })
        }
    }


    setChartNav=()=>{
        var toggler = document.getElementsByClassName("tree-stem");
        var i;

        for (i = 0; i < toggler.length; i++) {
        toggler[i].addEventListener("click", function() {
            this.parentElement.querySelector(".tree-branch").classList.toggle("active-branch-node");
            this.classList.toggle("tree-stem-down");
        });
        } 
    }

    CreateTree=(branch, key)=>{
        if(Object.keys(branch).length>0)
        {
            if(branch.sub_accounts.length>0){   
                    return <> 
                         <li key={branch.account_id} style={{lineHeight:'30px'}}>
                            <span className="tree-stem"></span>
                                <span className="tree-cell">
                                    <Link to={
                                                {
                                                    pathname:`/reports/account-statement/${branch.account_id}`,
                                                    state:{accountId:branch.account_id}
                                                }
                                            } >
                                        <i className="fa fa-university"/>&nbsp;
                                    {branch.account_number} {branch.account_name}
                                    </Link>&nbsp;&nbsp;
                                    </span>
                                    <span className="tree-cell long-description">{branch.description}</span>
                            <ul className="tree-branch">
                                {branch.sub_accounts.length>0 && branch.sub_accounts.map((branch_item, branch_key)=>
                                    this.CreateTree(branch_item, branch_item.account_id)
                                )}
                            </ul>
                        </li>

                        </>
            }else{
                return (
                    <li key={key}>
                            <ol className="tree-row">
                                <li className="account-cell">
                                    
                                    <Link to={
                                                {
                                                    pathname:`/reports/account-statement/${branch.account_id}`,
                                                    state:{accountId:branch.account_id}
                                                }
                                            } >
                                        <i className="fa fa-university"/>&nbsp;
                                        {branch.account_number} {branch.account_name}
                                    </Link>
                                </li>
                                <li className="description-cell long-description">{branch.description}</li>
                                
                                <li>
                                        <Link to="#" class="text-muted" onClick={()=>this.onClickEdit(branch)}>
                                            <i class="fas fa-edit"></i>
                                        </Link> &nbsp;
                                        <Link to="#" class="text-muted" onClick={()=>this.onClickDelete(branch)}>
                                            <i class="fas fa-trash-alt"></i>
                                        </Link>
                                </li>
                                
                                <li className="balance-cell">
                                    {branch.balance.amount_p}
                                    
                                </li>
                            </ol>
                        </li>
                    )
                }
        }else{
            return <></>
        }
        
    }


    render() {
        const chart = this.state.accountListing;
        return (
            <div>
                {this.state.mdlEdit}
                {this.state.mdlDelete}
                {!chart && <ActivityLoader/>}
                {chart==="404" && <ItemNotFound title="Chart Error" description="No account was found in the system"/>}

                 {chart && chart !=="404" && 
                 <ul id="main-tree">

                   
                        {chart.map((item, key)=>
                            <li key={key}><span className="tree-stem tree-stem-down">
                                {/* {item.value}  */}
                            {item.category_name}</span>
                                <ul className="tree-branch active-branch-node">
                               
                                {item.accounts && item.accounts.map((level_one, level_one_key)=>

                                    this.CreateTree(level_one, level_one_key)
                                )}

                            
                                </ul>
                            </li>
                        )} 


                 

                    </ul> 
                    }




                
            </div>
        );
    }
}

export default AccountListing;