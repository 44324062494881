import React, { Component } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';
import ajaxTag from '../../remote/ajaxTag';


class ImportTagsPage extends Component {

    state={
        info:'',
        loading:false,
        fileName:'',
        actualCsvFile:''
    }


   
    onChangeFile = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
             this.setState({
                actualCsvFile:{file:  e.target.result}
             })
         }
         
       }
    

       onConfirm = async (event) => {
        event.preventDefault();
        const {fileName, actualCsvFile}=this.state;
        if (fileName.length > 0) {
            this.setState({
                loading: true
            })

            const server_response=await ajaxTag.importTags(actualCsvFile);
            this.setState({
                loading: false
            })
            if(server_response.status==="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.message} />,
                    fileName:'',
                    actualCsvFile:''
                })
            }else{
                this.setState({
                    info: <AlertError message={server_response.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Select CSV file and try again"} />
            })
        }
    }


  
    render() {
        return (
            <PageContainer title="Import Tags">
            <div className="row">
                <div className="col-md-12">
                    <CallOut title="Note" description="The tag records must be from a csv file arranged in the format: Tag Name, Description"/>
                   
                    <div className="card">
                    {/* <div className="card-header"></div> */}

                    <div className="card-body">
                    
                 
                        <form className="form-horizontal" method="post" onSubmit={this.onConfirm}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}

                             <TextInput
                                type="file"
                                label="Select CSV file"
                                placeholder="Select CSV file"
                                leftIcon="fa fa-file"
                                onChange={this.onChangeFile} 
                                required
                                />



                            <div className="row">&nbsp;</div>
                           
                          
        

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                                <div className="col-md-7">
                                {!this.state.loading && <button type="submit" className="btn btn-primary">Import</button>}
                                </div>
                            </div>

                        </form> 

   

                    </div>





                    </div>

                </div>


                {/** Body ends */}

                </div>
           </PageContainer>
        );
    }
}

export default ImportTagsPage;