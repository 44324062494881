import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

class PopSyncStudent extends Component {

    static contextType = SchoolContext

    state={
       classId:"",
       loading:false,
       info:""
    }


   
    onChangeClass=(item)=>{
        this.setState({
            classId:item.value
        })
    }




    confirmSync=async(event)=>{
        event.preventDefault();
  
        const {classId}=this.state;
        if(classId.length>0)
        {   
            this.setState({info:<ActivityLoader/>})
            const server_response = await ajaxSchool.syncStudents(this.state.classId);
            if(server_response.status==="OK")
            {
                this.context.listAllStudents();
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={"Select class and try again! You can click 'sync classes' to populate classes."}/>
            })
        }
    }


    handleSyncClasses=async()=>{

        this.setState({loading:true})
        const server_response = await ajaxSchool.syncClasses();
        this.setState({loading:false})
        if(server_response.status==="OK")
        {
            this.context.listAllClasses();

        }
    }


    handleSyncResidence=async()=>{

        this.setState({loading:true})
        const server_response = await ajaxSchool.syncResidence();
        this.setState({loading:false})
        if(server_response.status==="OK")
        {
            this.context.listResidences();

        }
    }


    render() {


        const classList = this.context.classListing;
        const optionsclassList=[];
        if(Array.isArray(classList))
        {
            classList.map((item, key)=>
                optionsclassList.push({label:item.class_name, value:item.reference_id})
            )
        }


        return (
            
    <SystemModal id="mdl-sync-student" title="Sync Students">
        <form method="post" className="form-horizontal" onSubmit={this.confirmSync}>
               {this.state.info}
              
               <TextInput
                            type="select"
                            label="Select Class"
                            placeholder="Select class to sync"
                            leftIcon="fa fa-users"
                            onChange={this.onChangeClass}
                            options={optionsclassList} 
                            required
                            wrap
                            />
            </form> 
            
            <div className="modal-footer no-border">
                <button className="btn btn-light" onClick={this.handleSyncClasses}>
                   {this.state.loading?<ActivityLoader inline size={20}/> : "Sync Classes"}
                </button>

                <button className="btn btn-warning" onClick={this.handleSyncResidence}>
                   {this.state.loading?<ActivityLoader inline size={20}/> : "Sync Residence"}
                </button>
                <button type="submit" className="btn btn-primary" onClick={this.confirmSync}>Confirm Sync</button>
            </div>

   

   

    </SystemModal>
        );
    }
}

export default PopSyncStudent;