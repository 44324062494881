import React, { Component } from 'react';
import Chart from "react-apexcharts";
import SchoolContext from '../../Context/SchoolContext';
import AlertError from '../Common/AlertError';
import CardTitle from '../Common/CardTitle';

class FeesGraph extends Component {
 static contextType = SchoolContext

   


  render() {

    const {feesGraph, graphErrorMessage} = this.context

    let series = [
      {
        name: 'Total Amount Invoiced',
        type: 'column',
        data: feesGraph.total_invoiced
      },

      {
        name: 'Total Amount Paid',
        type: 'area',
        data: feesGraph.total_paid
      }
    ]

    let options = {
      chart: {
        // height: 139,
        type: 'line',
        stacked: false,
        toolbar: {
          show: false
        }
      },

      plotOptions: {
        bar: {
          columnWidth: '35%',
          endingShape: 'rounded'
          // distributed: true,
        }
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      colors: ['#477CFE', '#F5C107'],
      stroke: {
        width: [0, 2, 4],
        curve: 'smooth'
      },
      labels: feesGraph.labels,
      markers: {
        size: 0
      },
      yaxis: {
        title: {
          // text: 'Points',
          display: false,
        },
        min: 0,
        tickAmount: 5,
        // max: 200,
      },
      legend: {
        display: true,
        position: 'top'
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " UGX";
            }
            return y;
          }
        }
      },
      // xaxis: {
      //   type: 'datetime'
      // },
      grid: {
        borderColor: '#f1f1f1'
      },
    }

  




    return (
      <div className="card custom-card overflow-hidden">

        <CardTitle
          title="Overview of Fees Collection"
          subtitle="Defaulted over collected fees."
          filter={ <div className="btn-group p-0">
                                <button className="btn btn-light btn-sm" type="button">This Term</button>
                                <button className="btn btn-light btn-sm" type="button">This Year</button>
                            </div>}
        />

        <div className="card-body">
          <div>
            {feesGraph && <Chart
              options={options}
              series={series}
              height={270}
            />}
            {feesGraph==="404" && <AlertError type="warning" message={graphErrorMessage}/>}
          </div>
        </div>
      </div>

    );
  }
}

export default FeesGraph;