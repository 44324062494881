import React, { Component, useContext } from 'react'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import AlertError from '../../Components/Common/AlertError'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import CallOut from '../../Components/Common/CallOut'
// import SelectSearch from '../../Components/Common/SelectSearch'
import TextInput from '../../Components/Common/TextInput'
// import TopBar from '../../Components/Common/TopBar'
import PageContainer from '../../Components/Headings/PageContainer'
import ImportSchoolPay from '../../Components/SchoolFees/Import/ImportSchoolPay'
import SchoolContext from '../../Context/SchoolContext'
// import PageTitle from '../../Components/Headings/PageTitle'
import ajaxExpenses from '../../remote/ajaxExpenses'
import ajaxPayee from '../../remote/ajaxPayee'
import ajaxSchool from '../../remote/ajaxSchool'
import ajaxTag from '../../remote/ajaxTag'
import functions from '../../util/functions'

class NewFeesPaymentPage extends Component {
  static contextType = SchoolContext
  state = {
    info: '',
    loading: false,
    paymentAccountListing: false,
   

    studentListing:false,
    tag: '0',
    studentId:'',
    voteId:'',
    paymentAccount: '',
    amount: '',
    description: '',
    transactionRef: '',
    transactionDate: '',
    modal:false
  }

  componentDidMount () {
    this.getPaymentAccounts()
    this.getTags()
  }



  findStudent=async(hint)=>{
      const server_reponse  = await ajaxSchool.searchStudent(hint);
      if(server_reponse.status === "OK"){
          this.setState({
            studentListing:server_reponse.details
          })
      }else{
        this.setState({
          studentListing:"404"
        })
      }
  }



  onClickNewVendor = () => {
    window.open('/vendor/new', '_blank').focus()
  }

  onClickNewTag = () => {
    window.open('/tags/new', '_blank').focus()
  }

  onChangeStudent = item => {
    this.setState({
      studentId: item.value
    })
  }

  onChangePaymentAccount = item => {
    this.setState({
      paymentAccount: item.value
    })
  }

  onChangeVote = item => {
   
      this.setState({
          voteId: item.value
      })
    
  }

  onChangeTag = item => {
    if (item.value === 'add') {
      this.onClickNewTag()
    } else {
      this.setState({
        tag: item.value
      })
    }
  }

  onChangeAmount = event => {
    this.setState({ amount: event.target.value })
  }
  onChangeDescription = event => {
    this.setState({ description: event.target.value })
  }
  onChangeReference = event => {
    this.setState({ transactionRef: event.target.value })
  }
  onChangeDate = event => {
    this.setState({ transactionDate: event.target.value })
  }

 

  getPaymentAccounts = () => {
    this.setState(
      {
        paymentAccountListing: false
      },
      async () => {
        const server_response = await ajaxExpenses.listPaymentAccounts()
        if (server_response.status === 'OK') {
          this.setState({ paymentAccountListing: server_response.details })
        } else {
          this.setState({
            paymentAccountListing: '404'
          })
        }
      }
    )
  }

  

  getTags = () => {
    this.setState(
      {
        tagListing: false
      },
      async () => {
        const server_response = await ajaxTag.listTags()
        if (server_response.status === 'OK') {
          this.setState({ tagListing: server_response.details })
        } else {
          this.setState({
            tagListing: '404'
          })
        }
      }
    )
  }

  onConfirmPayment = async event => {
    event.preventDefault()

    const {
      amount,
      paymentAccount,
      description,
      transactionDate,
      transactionRef,
      studentId,
      voteId,
      tag
    } = this.state
    if (amount <= 0) {
      this.setState({
        info: <AlertError message='Enter amount and try again' />
      })

      return false
    }

    if (paymentAccount.length < 1) {
      this.setState({
        info: (
          <AlertError message='Select cash account and try again' />
        )
      })

      return false
    }

    if (studentId.length < 1) {
      this.setState({
        info: (
          <AlertError message='Select the student paying in' />
        )
      })

      return false
    }

    this.setState({
      loading: true
    })
    const server_response = await ajaxSchool.payFees({
      "student_id":studentId,
      "vote_id":voteId,
      "payment_date":transactionDate,
      "amount":amount,
      "cash_account_id":paymentAccount,
      "description":description,
      "reference":transactionRef,
      "tag":tag
    }
     
    )
    this.setState({
      loading: false
    })

    if (server_response.status === 'OK') {
      this.setState({
        info: <AlertSuccess message={server_response.message} />,
        amount: '',
        description: '',
        transactionRef: ''
      }, ()=>{setTimeout(()=>{
                this.setState({
                  info:''
                })
            }, 3000)
          })
    } else {
      this.setState({
        info: <AlertError message={server_response.message} />
      })
    }
  }

  RenderStudent = () => {
    const studentListing = this.state.studentListing
    const optionStudent = []
      Array.isArray(studentListing) && studentListing.map((item, key) =>
        optionStudent.push({
          label: item.last_name + ' ' + item.first_name + ' - ' + item.reg_no + ' (' + item.class.class_name + ')',
          value: item.id
        })
      )
    

    return (
      <TextInput
        label='Student'
        type='select'
        leftIcon='fa fa-user'
        placeholder='Select student....'
        options={optionStudent}
        onChange={this.onChangeStudent}
        leftComponent={
          <i className='fa fa-user'></i>
        }
        loading={!studentListing ? true : false}
        onType={(e)=>{this.findStudent(e.target.value)}}
      />
    )
  }

  RenderPaymentAccount = () => {
    const paymentAccountListing = this.state.paymentAccountListing
    const optionsPaymentAccountListing = []
    if (paymentAccountListing && paymentAccountListing !== '404') {
      paymentAccountListing.map((item, key) =>
        optionsPaymentAccountListing.push({
          label: item.account_number + ' ' + item.account_name,
          value: item.account_id
        })
      )
    }

    return (
      <TextInput
        label='Cash Account'
        type='select'
        leftIcon='fa fa-university'
        placeholder='Select funds destination account....'
        options={optionsPaymentAccountListing}
        onChange={this.onChangePaymentAccount}
        leftComponent={
          <i className='fa fa-university' onClick={this.getPaymentAccounts}></i>
        }
        loading={!paymentAccountListing ? true : false}
      />
    )
  }

  RenderVotes = () => {

    const { feesVotes, listFeesVotes } = this.context

    const optionsVoteListing = []
    if (Array.isArray(feesVotes)) {
      feesVotes.map((item, key) =>
           optionsVoteListing.push({ label: item.vote_name, value: item.id })
      )
    }

    return (
      <TextInput
        label='Paid Fees Vote'
        type='select'
        leftIcon='fa fa-check'
        placeholder='Select fees vote for payment destination'
        options={optionsVoteListing}
        onChange={this.onChangeVote}
        leftComponent={<i className='fa fa-check' onClick={listFeesVotes}></i>}
        loading={!feesVotes ? true : false}
      />
    )
  }

  RenderTag = () => {
    const tagListing = this.state.tagListing
    const optionsTagListing = [
      { label: 'Create new tag', value: 'add' },
      { label: '- No tag -', value: '0' }
    ]
    if (tagListing && tagListing !== '404') {
      tagListing.map((item, key) =>
        optionsTagListing.push({ label: item.tag_name, value: item.tag_id })
      )
    }

    return (
      <TextInput
        label='Tag Transaction'
        type='select'
        leftIcon='fa fa-tag'
        placeholder='Transaction is not tagged...'
        options={optionsTagListing}
        onChange={this.onChangeTag}
        leftComponent={<i className='fa fa-tag' onClick={this.getTags}></i>}
        loading={!tagListing ? true : false}
      />
    )
  }




  onClickImportSchoolPay=()=>{
    this.setState({
      modal:false
    }, ()=>this.setState({modal:<ImportSchoolPay/>}, ()=>{
      functions.openPopup("mdl-school-pay")
    }
    
    ))
  }



  render () {
    return (
      <PageContainer 
        title='New Fees Payment' 
        headerNav={  <div className="card-tools">
            
        <button type="button" className="btn btn-secondary float-right" onClick={this.onClickImportSchoolPay}>
        <i className="fas fa-upload"></i> School Pay</button> 

    </div>
}>
      {this.state.modal}
        <div className='row'>
          <div className='col-md-12'>
            <CallOut
              title='New Fees Payment'
              description='Enter payment made for a student. If the payment has not been made, select an acconts payable account as a payment track account.'
            />

            <div className='card'>
              {/* <div className="card-header"></div> */}

              <div className='card-body'>
                <form
                  class='form-horizontal'
                  method='post'
                  onSubmit={this.onConfirmPayment}
                >
                  {this.state.info}
                  {this.state.loading && <ActivityLoader />}

                  <this.RenderStudent />
                  <TextInput
                    type='text'
                    label='Amount Paid'
                    placeholder='Amount'
                    leftIcon='fa fa-credit-card'
                    value={this.state.amount}
                    onChange={this.onChangeAmount}
                    required
                  />
                  <TextInput
                    type='textarea'
                    label='Fees Description'
                    placeholder='Fees Description'
                    leftIcon='fa fa-list'
                    value={this.state.description}
                    onChange={this.onChangeDescription}
                    required
                  />

                  <div className='row'>&nbsp;</div>

                  <TextInput
                    type='date'
                    label='Transaction Date'
                    placeholder='Transaction Date'
                    leftIcon='fa fa-calendar'
                    value={this.state.transactionDate}
                    onChange={this.onChangeDate}
                    required
                  />

                  <TextInput
                    type='text'
                    label='Transaction Ref'
                    placeholder='Transaction Ref'
                    leftIcon='fa fa-hashtag'
                    value={this.state.transactionRef}
                    onChange={this.onChangeReference}
                    required
                  />

                  <this.RenderPaymentAccount />

                  <this.RenderVotes />
                  <this.RenderTag />

                  <div className='row'>&nbsp;</div>

                  <div className='modal-footer no-border'>
                    {!this.state.loading && (
                      <button type='submit' className='btn btn-success'>
                        Save Fees Payment
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/** body end */}
        </div>
      </PageContainer>
    )
  }
}

export default NewFeesPaymentPage
