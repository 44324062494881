import React, { Component } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import PageContainer from '../../Components/Headings/PageContainer';
import ajaxIncome from '../../remote/ajaxIncome';
import ajaxLiability from '../../remote/ajaxLiability';
import ajaxPayee from '../../remote/ajaxPayee';
import ajaxTag from '../../remote/ajaxTag';

class PayLiabilityPage extends Component {

    state={
        info:'',
        loading:false,
        incomeAccountListing:false,
        paymentAccountListing:false,
        payeeListing:false,
        tagListing:false,
        incomeAccount:'',
        paymentAccount:'',
        amount:'',
        description:'',
        transactionRef:'',
        transactionDate:'',
        payee:'',
        tag:'0'
    }


    componentDidMount() {
        this.getIncomeAccounts();
        this.getPaymentAccounts();
        this.getPayees();
        this.getTags();
    }

    onClickNewVendor=()=>{
        
        window.open('/vendor/new', '_blank').focus();

    }

    onClickNewTag=()=>{
        
        window.open('/tags/new', '_blank').focus();

    }


    onChangeIncomeAccount=(item)=>{
        this.setState({
            incomeAccount:item.value
        })
    }

    onChangePaymentAccount=(item)=>{
        this.setState({
            paymentAccount:item.value
        })
    }

    onChangePayee=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewVendor();
        }else{
            this.setState({
                payee:item.value
            })
        }
    }

    onChangeTag=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewTag();
        }else{
            this.setState({
                tag:item.value
            })
        }
    }


    onChangeAmount=(event)=>{
        this.setState({amount:event.target.value})
    }
    onChangeDescription=(event)=>{
        this.setState({description:event.target.value})
    }
    onChangeReference=(event)=>{
        this.setState({transactionRef:event.target.value})
    }
    onChangeDate=(event)=>{
        this.setState({transactionDate:event.target.value})
    }


    getIncomeAccounts=()=>{
        this.setState({
            incomeAccountListing:false
        }, async()=>{
            const server_response = await ajaxLiability.listLiabilityAccounts();
            if(server_response.status==="OK")
            {
                this.setState({ incomeAccountListing:server_response.details })
            }else{
                this.setState({
                    incomeAccountListing:"404"
                })
            }
        })
      
    }

    getPaymentAccounts=()=>{
        this.setState({
            paymentAccountListing:false
        }, async()=>{
            const server_response = await ajaxIncome.listPaymentAccounts();
            if(server_response.status==="OK")
            {
                this.setState({ paymentAccountListing:server_response.details })
            }else{
                this.setState({
                    paymentAccountListing:"404"
                })
            }
        })
      
    }


    getPayees=()=>{
        this.setState({
            payeeListing:false
        }, async()=>{
            const server_response = await ajaxPayee.listPayees();
            if(server_response.status==="OK")
            {
                this.setState({ payeeListing:server_response.details })
            }else{
                this.setState({
                    payeeListing:"404"
                })
            }
        })
      
    }

    getTags=()=>{
        this.setState({
            tagListing:false
        }, async()=>{
            const server_response = await ajaxTag.listTags();
            if(server_response.status==="OK")
            {
                this.setState({ tagListing:server_response.details })
            }else{
                this.setState({
                    tagListing:"404"
                })
            }
        })
      
    }

    onConfirmTransacion=async(event)=>{
        event.preventDefault();

        const {amount, incomeAccount, paymentAccount, description, transactionDate, transactionRef, payee, tag}=this.state;
        if(amount<=0)
        {
            this.setState({
                info:<AlertError message="Enter amount and try again"/>
            })

            return false;
        }

        if(incomeAccount.length<1 || paymentAccount.length<1)
        {
            this.setState({
                info:<AlertError message="Select both liability account and funds payment account"/>
            })

            return false;
        }

        if(payee.length<1)
        {
            this.setState({
                info:<AlertError message="Select payee - i.e. the origin of funds"/>
            })

            return false;
        }

        this.setState({
            loading:true
        })
        const server_response = await ajaxLiability.reduceLiability(
            paymentAccount, incomeAccount, amount, transactionDate, description, payee, transactionRef, tag
        );
        this.setState({
            loading:false
        })

        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>,
                amount:'',
                description:'',
                transactionRef:''
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }

    }



    RenderIncomeAccount=()=>{
        const incomeAccountListing = this.state.incomeAccountListing;
        const optionsincomeAccountListing=[];
        if(incomeAccountListing && incomeAccountListing!=="404")
        {
            incomeAccountListing.map((item, key)=>
            optionsincomeAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return  <TextInput
                    label="Liability Account"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select liability account...." 
                    options={optionsincomeAccountListing}
                    onChange={this.onChangeIncomeAccount}
                    leftComponent={<i className="fa fa-university" onClick={this.getIncomeAccounts}></i>}
                    loading={!incomeAccountListing?true:false}
                    />
        
        }

   

    RenderPaymentAccount=()=>{
        const paymentAccountListing = this.state.paymentAccountListing;
        const optionsPaymentAccountListing=[];
        if(paymentAccountListing && paymentAccountListing!=="404")
        {
            paymentAccountListing.map((item, key)=>
                optionsPaymentAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return  <TextInput
                    label="Funds Payment Account"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select where the funds are being deducted from to make payment e.g. bank...." 
                    options={optionsPaymentAccountListing}
                    onChange={this.onChangePaymentAccount}
                    leftComponent={<i className="fa fa-university" onClick={this.getPaymentAccounts}></i>}
                    loading={!paymentAccountListing?true:false}
                    />
        
        }

    RenderPayee=()=>{
        const payeeListing = this.state.payeeListing;
        const optionsPayeeListing=[{label:"Add new  Payee", value:"add"}];
        if(payeeListing && payeeListing!=="404")
        {
            payeeListing.map((item, key)=>
                optionsPayeeListing.push({label:item.title, value:item.payee_id})
            )
        }
        
        return   <TextInput
                        label="Funds Origin"
                        type="select" 
                        leftIcon="fa fa-truck"
                        placeholder="Select payee e.g M.O.E, Rexum Foundation, etc. ...." 
                        options={optionsPayeeListing}
                        onChange={this.onChangePayee}
                        leftComponent={<i className="fa fa-truck" onClick={this.getPayees}></i>}
                        loading={!payeeListing?true:false}
                        />
        
        }

    

        RenderTag=()=>{
            const tagListing = this.state.tagListing;
            const optionsTagListing=[{label:"Create new tag", value:"add"}, {label:"- No tag -", value:"0"}];
            if(tagListing && tagListing!=="404")
            {
                tagListing.map((item, key)=>
                    optionsTagListing.push({label:item.tag_name, value:item.tag_id})
                )
            }
            
            return  <TextInput
                        label="Tag Transaction"
                        type="select" 
                        leftIcon="fa fa-tag"
                        placeholder="Transaction is not tagged..." 
                        options={optionsTagListing}
                        onChange={this.onChangeTag}
                        leftComponent={<i className="fa fa-tag" onClick={this.getTags}></i>}
                        loading={!tagListing?true:false}
                        />
            
            }
   
    render() {
        return (
            <PageContainer title="Pay Current Liability">
                <div className="row">
                    <div className="col-md-12">
                        <CallOut title="Return borrowed funds" description="Pay back the money you used borrowed to facilitate your business e.g. Capital, borrowed funds, etc"/>
                       
                        <div className="card">
            {/* <div className="card-header"></div> */}

            <div className="card-body">
           
                        <form className="form-horizontal" method="post" onSubmit={this.onConfirmTransacion}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}


                           <this.RenderIncomeAccount/>

                           <TextInput
                            type="number"
                            label="Amount"
                            placeholder="Amount"
                            leftIcon="fa fa-credit-card"
                            value={this.state.amount}
                            onChange={this.onChangeAmount} 
                            required
                            />
                            <TextInput
                                type="textarea"
                                label="Payment Description"
                                placeholder="E.g. Enter payment reason"
                                leftIcon="fa fa-list"
                                value={this.state.description}
                                onChange={this.onChangeDescription} 
                                required
                            />

                          
                        


                            <div className="row">&nbsp;</div>


                            <TextInput
                                type="date"
                                label="Transaction Date"
                                placeholder="Transaction Date"
                                leftIcon="fa fa-calendar"
                                value={this.state.transactionDate}
                                onChange={this.onChangeDate} 
                                required
                            />

                            
                             <TextInput
                                type="text"
                                label="Transaction Ref"
                                placeholder="Transaction Ref"
                                leftIcon="fa fa-hashtag"
                                value={this.state.transactionRef}
                                onChange={this.onChangeReference} 
                                required
                            />
                            <this.RenderPaymentAccount/>


                          

                            <this.RenderPayee/>
                            <this.RenderTag/>
                           
        

       
        

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                               {!this.state.loading && <button type="submit" className="btn btn-primary">Settle Liability</button>}
                            </div>

                        </form> 

   

                    </div>
                    </div>

                    </div>{/** body end */}

                

                </div>
           </PageContainer>
        );
    }
}

export default PayLiabilityPage;