import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import AlertError from '../../Components/Common/AlertError'
import CallOut from '../../Components/Common/CallOut'
import PageNav from '../../Components/Common/PageNav'
import TextInput from '../../Components/Common/TextInput'
import PageContainer from '../../Components/Headings/PageContainer'
import NewFeesGroup from '../../Components/SchoolFees/NewFeesGroup'
import PopClassFeesGroup from '../../Components/SchoolFees/PopClassFeesGroup'
import PopUpdateFeesGroup from '../../Components/SchoolFees/PopUpdateFeesGroup'
import SchoolContext from '../../Context/SchoolContext'
import functions from '../../util/functions'

class FeesGroupPage extends Component {

  static contextType = SchoolContext

  state = {
   groupId:false,
   modal:false,
   groupInfo: false
  }



  handleGroupAssign=()=>{

    this.setState({
      modal:false
    }, ()=>{

      this.setState({
        modal:<PopClassFeesGroup groupId={this.state.groupId}/>
    }, ()=>{functions.openPopup("mdl-group-fees-assign")})

    })

   
  }



  handleGroupEdit=()=>{

    this.setState({
      modal:false
    }, ()=>{

      this.setState({
        modal:<PopUpdateFeesGroup groupId={this.state.groupId} record={this.state.groupInfo}/>
    }, ()=>{functions.openPopup("mdl-update-fees-group")})

    })

   
  }






  RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
                {this.state.groupInfo && <> <li>
                    <Link to={
                                {
                                    pathname:`/school/fees/structure/${this.state.groupId}`,
                                    state:{groupId:this.state.groupId}
                                }
                            } 
                          className="btn ripple btn-primary mb-1">
                        <i className="fa fa-cog mr-1"></i>&nbsp;Manage Group</Link>
                </li>

                    <li>
                        <Link to="#" className="btn ripple btn-warning mb-1" onClick={this.handleGroupAssign}>
                        <i className="fa fa-users mr-1"></i>&nbsp;Assign to Group</Link>
                    </li>


                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.handleGroupEdit}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Edit Group</Link>
                    </li>
                    
                    </>}
               
            </ol>
           

            
        </div>
      
    )
  }

  

  onSelectRecord = (groupId, groupInfo) => {
    this.setState({ groupId, groupInfo })
  }


  render() {

    const transactions = this.context.feesGroups

    return (
      <>
       

        <PageContainer 
          title='Fees Groups'
          headerNav={<this.RenderControls/>}
          >

          {this.state.modal}
          <CallOut
            title='Fees Groups'
            description='Also known as fees catered for. These can contain transactions that the school handles or pays for in a given percentage.'
          />
          <div className='row'>
            <div className='col-md-7'>
              <div className='card'>
                <div className='card-header border-transparent'>
                  <h3 className='card-title'>All billing batches</h3>
                </div>

                <div className='card-body'>
                 


                <div className='table table-responsive table-ellipsis'>
          {Array.isArray(transactions)  && (
            <table className='table table-bordere text-nowrap mb-0'>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Fees Group</th>
                  <th>Amount</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((item, key) => (
                  <tr
                    className={`${item.group_info.id === this.state.groupId
                        ? 'flag-row'
                        : 'hot-tr'
                      }`}
                    style={{ cursor: 'default' }}
                    key={key}
                    onClick={() => this.onSelectRecord(item.group_info.id, item.group_info)}
                  >
                    <td>{key + 1}</td>
                    <td>{item.group_info.group_name}</td>
                    <td>{item.structure?item.structure.total_fees.amount_p:0}</td>
                    <td>{item.group_info.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {transactions === '404' && <AlertError type="info" message="No fees group found"/>}
        </div>





                </div>
              </div>
            </div>

            <div className='col-md-5'>
                      

                    <NewFeesGroup/>


            </div>
          </div>
        </PageContainer>
      </>
    )
  }
}

export default FeesGroupPage
