import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ContentHeader extends Component {


    nav=()=>{
        if(this.props.nav)
        {
            return this.props.nav
        }
        return <ol className="breadcrumb float-sm-right">
        {/* <li className="breadcrumb-item"><Link to="/">Home</Link></li> */}
        <li className="breadcrumb-item active">{this.props.subtitle} {this.props.titleElement}</li>
        </ol>
    }

    render() {
        return (
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">{this.props.title}</h1>
                    </div>
                    <div className="col-sm-6 d-flex flex-row justify-content-end">
                        <this.nav/>
                    </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ContentHeader;