import React, { Component } from 'react';
import ajaxAccountChart from '../../remote/ajaxAccountChart';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import CallOut from '../Common/CallOut';
import TextInput from '../Common/TextInput';


class ImportAccount extends Component {

    state={
        info:'',
        loading:false,
        fileName:'',
        actualCsvFile:''
    }


   
    onChangeFile = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
             this.setState({
                actualCsvFile:{file:  e.target.result}
             })
         }
         
       }
    

       onConfirm = async (event) => {
        event.preventDefault();
        const {fileName, actualCsvFile}=this.state;
        if (fileName.length > 0) {
            this.setState({
                loading: true
            })

            const server_response=await ajaxAccountChart.importAccounts(actualCsvFile);
            this.setState({
                loading: false
            })
            if(server_response.status==="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.message} />,
                    fileName:'',
                    actualCsvFile:''
                })
            }else{
                this.setState({
                    info: <AlertError message={server_response.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Select CSV file and try again"} />
            })
        }
    }


  
    render() {
        return (
           <div>
                <CallOut 
                    title="Note: The accounts must be from a csv file arranged in the format:-" 
                    description="AccountNumber, AccountName, AccountDescription, AccountType(1=Income, 2=Asset, 3=Asset, 4=Liability), Sub Account (Account number)"/>

               <form method="post" className="form-horizontal" onSubmit={this.onConfirm}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}
               <TextInput
                            type="file"
                            label="Select CSV file"
                            placeholder="Select CSV file"
                            leftIcon="fa fa-file"
                            value={this.state.accountTitle}
                            onChange={this.onChangeAccountTitle} 
                            required/>
                 

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                                <div className="col-md-7">
                                {!this.state.loading && <button type="submit" className="btn btn-primary">Import</button>}
                                </div>
                            </div>

                        </form> 

   

                    </div>



        );
    }
}

export default ImportAccount;