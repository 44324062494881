import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AccountTimelineItem extends Component {


    render() {
        const account=this.props.account;

        return (
            <div>
                <i className={`fas fa-university ${this.props.iconBackground}`}></i>

               <div className="timeline-item">
               <span className="time"><i className="far fa-hastag"></i> #{account.account_id + ' - '+ account.account_number}</span>

               <h3 className="timeline-header"><Link to="#">{account.account_name} </Link> 
                   {account.main_account?" - " + account.main_account.account_name: ""} 
                </h3>

               <div className="timeline-body">
                {account.account_number } - 
                { " " + account.description==="none"?"No description":account.description}
               </div>

               <div className="timeline-footer">
                   <Link to="#" className="btn btn-primary btn-sm">{account.balance.amount_p}</Link>
               </div>
               </div>
             
           </div>
        );
    }
}

export default AccountTimelineItem;