import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountsContext from '../../Context/AccountsContext';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopConfigureDiscount=()=>{

    const [info, setInfo] = useState("")
    const [loading, setLoading] = useState(false)
    const [cashAccount, setCashAccount] = useState("");
    const [expenseAccount, setExpenseAccount] = useState("");
    const [defaultExpenseAccount, setDefaultExpenseAccount] = useState(false);
    const [defaultCashAccount, setDefaultCashAccount] = useState(false);

    const { expenseAccountsListing, cashAccountListing} = useContext(AccountsContext);
    const { listFeesConfig, feesConfigListing } = useContext(SchoolContext);

    useEffect(()=>{
        if(feesConfigListing.discount_expense_account)
        {
            setDefaultExpenseAccount(feesConfigListing.discount_expense_account.account_name);
            setExpenseAccount(feesConfigListing.discount_expense_account.account_id)
        }

        if(feesConfigListing.discount_cash_account)
        {
            setDefaultCashAccount(feesConfigListing.discount_cash_account.account_name);
            setCashAccount(feesConfigListing.discount_cash_account.account_id)
        }
    }, [feesConfigListing])

  
    const onChangeReeceivable=(item)=>{
            setCashAccount(item.value)
    }


    const onChangeExpenseAccount=(item)=>{
        setExpenseAccount(item.value)
    }


    const confirm=async(event)=>{
        event.preventDefault();
  
        if(cashAccount>0 && expenseAccount>0)
        {   
            setInfo(<ActivityLoader/>)
            setLoading(true)
            const server_response = await ajaxSchool.configureFeesDiscount(
                {
                    "cash_account":cashAccount,
                    "expense_account":expenseAccount
                  }
            );
            setLoading(false)

            if(server_response.status==="OK")
            {
                listFeesConfig();
                setInfo(<AlertSuccess message={server_response.message}/>)
                
            }else{
                setInfo(<AlertError message={server_response.message}/>)
        
            }

        }else{
           
            setInfo(<AlertError message={dictionary._completeFields}/>)
        
        }
    }





        const optionsExpense=[];
        if(Array.isArray(expenseAccountsListing))
        {
            expenseAccountsListing.map((item, key)=>
                optionsExpense.push({label:item.account_name, value:item.account_id})
            )
        }


        const optionsCashAccount=[];
        if(Array.isArray(cashAccountListing))
        {
            cashAccountListing.map((item, key)=>
                optionsCashAccount.push({label:item.account_name, value:item.account_id})
            )
        }


        return (
            
        <SystemModal id="mdl-config-discount" title="Configure Fees Discounting">
        <form method="post" className="form-horizontal" onSubmit={confirm}>
               {info}

        
                <TextInput
                    type="select"
                    label="Expense Account"
                    placeholder={ defaultExpenseAccount || "Select expense account"}
                    leftIcon="fa fa-arrow-up"
                    onChange={onChangeExpenseAccount}
                    options={optionsExpense} 
                    required
                    wrap
                    />



                <TextInput
                    type="select"
                    label="Cash Account"
                    placeholder={ defaultCashAccount || "Select Cash Account"}
                    leftIcon="fa fa-exchange-alt"
                    onChange={onChangeReeceivable}
                    options={optionsCashAccount} 
                    required
                    wrap
                    />
            </form> 
            
            <div className="modal-footer no-border">
                <button className="btn btn-success" type='submit' onClick={confirm} disabled={loading}>
                   {loading?<ActivityLoader inline size={20}/> : "Save COnfiguration"}
                </button>
            </div>

   

   

    </SystemModal>
        );
    }


export default PopConfigureDiscount;