import React, { Component } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import PageContainer from '../../Components/Headings/PageContainer';
import ajaxAsset from '../../remote/ajaxAsset';
import ajaxExpenses from '../../remote/ajaxExpenses';
import ajaxPayee from '../../remote/ajaxPayee';
import ajaxTag from '../../remote/ajaxTag';

class DepreciateAssetPage extends Component {

    state={
        info:'',
        loading:false,
        assetAccountListing:false,
        expenseAccountListing:false,
        payeeListing:false,
        tagListing:false,
        expenseAccount:'',
        paymentAccount:'',
        amount:'',
        description:'',
        transactionRef:'',
        transactionDate:'',
        payee:'',
        tag:'0'
    }


    componentDidMount() {
        this.getAssetAccounts();
        this.getIncomeAccounts();
        this.getPayees();
        this.getTags();
    }

    onClickNewVendor=()=>{
        
        window.open('/vendor/new', '_blank').focus();

    }

    onClickNewTag=()=>{
        
        window.open('/tags/new', '_blank').focus();

    }


    onChangeExpenseAccount=(item)=>{
        this.setState({
            expenseAccount:item.value
        })
    }

    onChangeAssetAccount=(item)=>{
        this.setState({
            paymentAccount:item.value
        })
    }

    onChangePayee=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewVendor();
        }else{
            this.setState({
                payee:item.value
            })
        }
    }

    onChangeTag=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewTag();
        }else{
            this.setState({
                tag:item.value
            })
        }
    }


    onChangeAmount=(event)=>{
        this.setState({amount:event.target.value})
    }
    onChangeDescription=(event)=>{
        this.setState({description:event.target.value})
    }
    onChangeReference=(event)=>{
        this.setState({transactionRef:event.target.value})
    }
    onChangeDate=(event)=>{
        this.setState({transactionDate:event.target.value})
    }


    getAssetAccounts=()=>{
        this.setState({
            assetAccountListing:false
        }, async()=>{
            const server_response = await ajaxAsset.listAssetAccounts();
            if(server_response.status==="OK")
            {
                this.setState({ assetAccountListing:server_response.details })
            }else{
                this.setState({
                    assetAccountListing:"404"
                })
            }
        })
      
    }

    getIncomeAccounts=()=>{
        this.setState({
            expenseAccountListing:false
        }, async()=>{
            const server_response = await ajaxExpenses.listExpenseAccounts();
            if(server_response.status==="OK")
            {
                this.setState({ expenseAccountListing:server_response.details })
            }else{
                this.setState({
                    expenseAccountListing:"404"
                })
            }
        })
      
    }


    getPayees=()=>{
        this.setState({
            payeeListing:false
        }, async()=>{
            const server_response = await ajaxPayee.listPayees();
            if(server_response.status==="OK")
            {
                this.setState({ payeeListing:server_response.details })
            }else{
                this.setState({
                    payeeListing:"404"
                })
            }
        })
      
    }

    getTags=()=>{
        this.setState({
            tagListing:false
        }, async()=>{
            const server_response = await ajaxTag.listTags();
            if(server_response.status==="OK")
            {
                this.setState({ tagListing:server_response.details })
            }else{
                this.setState({
                    tagListing:"404"
                })
            }
        })
      
    }

    onConfirmTransacion=async(event)=>{
        event.preventDefault();

        const {amount, expenseAccount, paymentAccount, description, transactionDate, transactionRef, payee, tag}=this.state;
        if(amount<=0)
        {
            this.setState({
                info:<AlertError message="Enter amount and try again"/>
            })

            return false;
        }

        if(expenseAccount.length<1 || paymentAccount.length<1)
        {
            this.setState({
                info:<AlertError message="Select both asset and expense account"/>
            })

            return false;
        }

        if(payee.length<1)
        {
            this.setState({
                info:<AlertError message="Select valuer - i.e. the company/person who valued the asset."/>
            })

            return false;
        }

        this.setState({
            loading:true
        })
        const server_response = await ajaxAsset.depreciateValue(
            paymentAccount, expenseAccount, amount, transactionDate, description, payee, transactionRef, tag
        );
        this.setState({
            loading:false
        })

        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>,
                amount:'',
                description:'',
                transactionRef:''
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }

    }



    RenderExpenseAccount=()=>{
        const expenseAccountListing = this.state.expenseAccountListing;
        const optionsIncomeAccountListing=[];
        if(expenseAccountListing && expenseAccountListing!=="404")
        {
            expenseAccountListing.map((item, key)=>
                optionsIncomeAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return  <TextInput
                    label="Expense account"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select an account to track the loss in value" 
                    options={optionsIncomeAccountListing}
                    onChange={this.onChangeExpenseAccount}
                    leftComponent={<i className="fa fa-university" onClick={this.getIncomeAccounts}></i>}
                    loading={!expenseAccountListing?true:false}
                    />
        
        }

   

    RenderAssetAccount=()=>{
        const expenseAccountListing = this.state.assetAccountListing;
        const optionsIncomeAccountListing=[];
        if(expenseAccountListing && expenseAccountListing!=="404")
        {
            expenseAccountListing.map((item, key)=>
                optionsIncomeAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return  <TextInput
                    label="Asset Account"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select the asset account...." 
                    options={optionsIncomeAccountListing}
                    onChange={this.onChangeAssetAccount}
                    leftComponent={<i className="fa fa-university" onClick={this.getIncomeAccounts}></i>}
                    loading={!expenseAccountListing?true:false}
                    />
        
        }

    RenderPayee=()=>{
        const payeeListing = this.state.payeeListing;
        const optionsPayeeListing=[{label:"Add Valuer...", value:"add"}];
        if(payeeListing && payeeListing!=="404")
        {
            payeeListing.map((item, key)=>
                optionsPayeeListing.push({label:item.title, value:item.payee_id})
            )
        }
        
        return   <TextInput
                        label="Asset Valuer"
                        type="select" 
                        leftIcon="fa fa-truck"
                        placeholder="Select asset valuer" 
                        options={optionsPayeeListing}
                        onChange={this.onChangePayee}
                        leftComponent={<i className="fa fa-truck" onClick={this.getPayees}></i>}
                        loading={!payeeListing?true:false}
                        />
        
        }

    

        RenderTag=()=>{
            const tagListing = this.state.tagListing;
            const optionsTagListing=[{label:"Create new tag", value:"add"}, {label:"- No tag -", value:"0"}];
            if(tagListing && tagListing!=="404")
            {
                tagListing.map((item, key)=>
                    optionsTagListing.push({label:item.tag_name, value:item.tag_id})
                )
            }
            
            return  <TextInput
                        label="Tag Transaction"
                        type="select" 
                        leftIcon="fa fa-tag"
                        placeholder="Transaction is not tagged..." 
                        options={optionsTagListing}
                        onChange={this.onChangeTag}
                        leftComponent={<i className="fa fa-tag" onClick={this.getTags}></i>}
                        loading={!tagListing?true:false}
                        />
            
            }
   
    render() {
        return (
            <PageContainer title="Depreciate Asset">
                <div className="row">
                    <div className="col-md-12">
                        <CallOut title="Assets" description="Record a loss in asset value to keep track of its current value."/>
                       
                        <div className="card">
            {/* <div className="card-header"></div> */}

            <div className="card-body">
           
                        <form className="form-horizontal" method="post" onSubmit={this.onConfirmTransacion}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}


                           
                           <this.RenderAssetAccount/>
                           <TextInput
                            type="number"
                            label="Lost Amount"
                            placeholder="By how much has the asset depreciated ?"
                            leftIcon="fa fa-credit-card"
                            value={this.state.amount}
                            onChange={this.onChangeAmount} 
                            required
                            />
                            <TextInput
                                type="textarea"
                                label="Depreciation Description"
                                placeholder="Describe the details of this depreciation"
                                leftIcon="fa fa-list"
                                value={this.state.description}
                                onChange={this.onChangeDescription} 
                                required
                            />

                          
                        


                            <div className="row">&nbsp;</div>


                            <TextInput
                                type="date"
                                label="Transaction Date"
                                placeholder="Transaction Date"
                                leftIcon="fa fa-calendar"
                                value={this.state.transactionDate}
                                onChange={this.onChangeDate} 
                                required
                            />

                            
                             <TextInput
                                type="text"
                                label="Transaction Ref"
                                placeholder="Transaction Ref"
                                leftIcon="fa fa-hashtag"
                                value={this.state.transactionRef}
                                onChange={this.onChangeReference} 
                                required
                            />
                          


                          <this.RenderExpenseAccount/>

                            <this.RenderPayee/>
                            <this.RenderTag/>
                           
        

       
        

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                               {!this.state.loading && <button type="submit" className="btn btn-primary">Save Depreciation</button>}
                            </div>

                        </form> 

   

                    </div>
                    </div>

                    </div>{/** body end */}

                

                </div>
           </PageContainer>
        );
    }
}

export default DepreciateAssetPage;