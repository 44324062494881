import React, { Component } from 'react';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SelectSearch from '../Common/SelectSearch';
import TopBar from '../Common/TopBar';
import PageTitle from '../Headings/PageTitle';
import ajaxExpenses from '../../remote/ajaxExpenses';
import ajaxPayee from '../../remote/ajaxPayee';
import ajaxTag from '../../remote/ajaxTag';
import ajaxIncome from '../../remote/ajaxIncome';
import ajaxLedger from '../../remote/ajaxLedger';
import { Link } from 'react-router-dom';
import TextInput from '../Common/TextInput';

class EditLedger extends Component {

    state={
        info:'',
        loading:false,
        targetAccountListing:false,
        paymentAccountListing:false,
        payeeListing:false,
        tagListing:false,
        targetAccount:'',
        amount:'',
        description:'',
        transactionRef:'',
        transactionDate:'',
        payee:'',
        tag:'',
        popup:false,
        currentRecord:this.props.record,
        targetAccountTitle:'',
        tagTitle:'',
        payeeTitle:''
    }


    componentDidMount() {
        // this.getTargetAccounts();
        // this.getPaymentAccounts();
        this.getPayees();
        this.getTags();
        const record=this.state.currentRecord;
        this.setState({
            amount:record.amount.amount,
            description:record.description,
            transactionDate:record.transaction_date.db,
            transactionRef:record.transaction_ref,
            payee:record.payee?record.payee.payee_id:0,
            targetAccount:record.target_account?record.target_account.account_id:0,
            tag:record.tag?record.tag.tag_id:0,
            payeeTitle:record.payee?record.payee.title:false,
            tagTitle:record.tag?record.tag.tag_name:false

        })

    }


    onClickNewVendor=()=>{
        
        window.open('/vendor/new', '_blank').focus();

    }

    onClickNewTag=()=>{
        
        window.open('/tags/new', '_blank').focus();

    }



    onChangeTargetAccount=(item)=>{
        this.setState({
            targetAccount:item.value
        })
    }

    onChangePaymentAccount=(item)=>{
        this.setState({
            paymentAccount:item.value
        })
    }

    onChangePayee=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewVendor();
        }else{
            this.setState({
                payee:item.value
            })
        }
    }

    onChangeTag=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewTag();
        }else{
            this.setState({
                tag:item.value
            })
        }
    }

    onChangeAmount=(event)=>{
        this.setState({amount:event.target.value})
    }
    onChangeDescription=(event)=>{
        this.setState({description:event.target.value})
    }
    onChangeReference=(event)=>{
        this.setState({transactionRef:event.target.value})
    }
    onChangeDate=(event)=>{
        this.setState({transactionDate:event.target.value})
    }


    getTargetAccounts=()=>{
        this.setState({
            targetAccountListing:false
        }, async()=>{
            if(this.state.currentRecord.target_account)
            {   
                const target_account = this.state.currentRecord.target_account;
                this.setState({
                    targetAccountTitle:target_account.account_name
                })
                var server_response;
                if(target_account.account_category.category_id==="2")
                {
                     server_response = await ajaxExpenses.listExpenseAccounts();

                }else if(target_account.account_category.category_id==="1"){
                     server_response = await ajaxIncome.listIncomeAccounts();
                }else{
                    alert("Transaction type not supported for editing, contact admin")
                    return false;
                }
                
                if(server_response.status==="OK")
                {
                    this.setState({ targetAccountListing:server_response.details })
                }else{
                    this.setState({
                        targetAccountListing:"404"
                    })
                }

            }
        })
      
    }

    getPaymentAccounts=()=>{
        this.setState({
            paymentAccountListing:false
        }, async()=>{
            const server_response = await ajaxExpenses.listPaymentAccounts();
            if(server_response.status==="OK")
            {
                this.setState({ paymentAccountListing:server_response.details })
            }else{
                this.setState({
                    paymentAccountListing:"404"
                })
            }
        })
      
    }


    getPayees=()=>{
        this.setState({
            payeeListing:false
        }, async()=>{
            const server_response = await ajaxPayee.listPayees();
            if(server_response.status==="OK")
            {
                this.setState({ payeeListing:server_response.details })
            }else{
                this.setState({
                    payeeListing:"404"
                })
            }
        })
      
    }


    getTags=()=>{
        this.setState({
            tagListing:false
        }, async()=>{
            const server_response = await ajaxTag.listTags();
            if(server_response.status==="OK")
            {
                this.setState({ tagListing:server_response.details })
            }else{
                this.setState({
                    tagListing:"404"
                })
            }
        })
      
    }

    onConfirmUpdate=async(event)=>{
        event.preventDefault();

        const {amount, description, transactionDate, transactionRef, payee, tag}=this.state;
        if(amount<=0)
        {
            this.setState({
                info:<AlertError message="Enter amount and try again"/>
            })

            return false;
        }

        // if(targetAccount.length<1)
        // {
        //     this.setState({
        //         info:<AlertError message="Select target account and try again"/>
        //     })

        //     return false;
        // }

        if(payee.length<1)
        {
            this.setState({
                info:<AlertError message="Select payee and try again"/>
            })

            return false;
        }

        this.setState({
            loading:true
        })
        const server_response = await ajaxLedger.updateLedger(
            this.state.currentRecord.core_grouping_id, amount, transactionDate, description, payee, transactionRef, tag
        );
        this.setState({
            loading:false
        })

        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }

    }



    RendertargetAccount=()=>{
        const targetAccountListing = this.state.targetAccountListing;
        const optionstargetAccountListing=[];
        if(targetAccountListing && targetAccountListing!=="404")
        {
            targetAccountListing.map((item, key)=>
            optionstargetAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return <TextInput
                    label="Target Account"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder={this.state.targetAccountTitle} 
                    options={optionstargetAccountListing}
                    onChange={this.onChangeTargetAccount}
                    leftComponent={<i className="fa fa-university" onClick={this.getTargetAccounts}></i>}
                    loading={!targetAccountListing?true:false}
                    />
                       
        }

   


    RenderPayee=()=>{
        const payeeListing = this.state.payeeListing;
        const optionsPayeeListing=[{label:"Add new  Payee", value:"add"}];
        if(payeeListing && payeeListing!=="404")
        {
            payeeListing.map((item, key)=>
                optionsPayeeListing.push({label:item.title, value:item.payee_id})
            )
        }
        
        return  <TextInput
                    label="Payee"
                    type="select" 
                    leftIcon="fa fa-truck"
                    placeholder={this.state.payeeTitle || "Select payee e.g supplier, uber driver, etc...."} 
                    options={optionsPayeeListing}
                    onChange={this.onChangePayee}
                    leftComponent={<i className="fa fa-truck" onClick={this.getPayees}></i>}
                    loading={!payeeListing?true:false}
                    />
                                
                                
                      
        }



        RenderTag=()=>{
            const tagListing = this.state.tagListing;
            const optionsTagListing=[{label:"Create new tag", value:"add"}, {label:"- No tag -", value:"0"}];
            if(tagListing && tagListing!=="404")
            {
                tagListing.map((item, key)=>
                    optionsTagListing.push({label:item.tag_name, value:item.tag_id})
                )
            }
            
            return   <TextInput
                    label="Tag Transaction"
                    type="select" 
                    leftIcon="fa fa-tag"
                    placeholder={this.state.tagTitle || "Transaction is not tagged"} 
                    options={optionsTagListing}
                    onChange={this.onChangeTag}
                    leftComponent={<i className="fa fa-tag" onClick={this.getPayees}></i>}
                    loading={!tagListing?true:false}
                    />    
                                    
            }
    
   
    render() {
        return (

         <div className="card">
            {this.props.onClickTab && <div className="card-header">
              
                 <ol className="breadcrumb float-sm-lef">
                    <li className="breadcrumb-item"><Link to="#" onClick={()=>this.props.onClickTab("main")}>Transactions</Link></li>
                    <li className="breadcrumb-item active">Edit Ledger</li>
                </ol>

            </div>}

            <div className="card-body">
           
                        <form method="post" class="form-horizontal" onSubmit={this.onConfirmUpdate}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}

                 
                

                           {/* <this.RendertargetAccount/> */}
                           <TextInput
                            type="text"
                            label="Amount"
                            placeholder="Amount"
                            leftIcon="fa fa-credit-card"
                            value={this.state.amount}
                            onChange={this.onChangeAmount} 
                            required
                            />

                             <TextInput
                                type="textarea"
                                label="Transaction Description"
                                placeholder="Transaction Description"
                                leftIcon="fa fa-list"
                                value={this.state.description}
                                onChange={this.onChangeDescription} 
                                required
                            />
                            
                            <div className="row">&nbsp;</div>

                           <TextInput
                                type="date"
                                label="Transaction Date"
                                placeholder="Transaction Date"
                                leftIcon="fa fa-calendar"
                                value={this.state.transactionDate}
                                onChange={this.onChangeDate} 
                                required
                            />

                            
                             <TextInput
                                type="text"
                                label="Transaction Ref"
                                placeholder="TTransaction Ref"
                                leftIcon="fa fa-hashtag"
                                value={this.state.transactionRef}
                                onChange={this.onChangeReference} 
                                required
                            />




                

                            <this.RenderPayee/>
                            <this.RenderTag/>
        
                           
        
        

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                               {!this.state.loading && <button type="submit" className="btn btn-primary">Save Changes</button>}
                            </div>

                        </form> 

   

                    </div>
            </div>
                   
                   
        );
    }
}

export default EditLedger;