
import React from "react";
import ajaxSchool from "../../../remote/ajaxSchool";
import TextInput from "../../Common/TextInput";
class StudentInputField extends React.Component{


    state={
        studentListing:false,
        studentId:''

    }



  onChangeStudent = item => {
    this.setState({
      studentId: item.value
    }, ()=>this.props.onChange(item.record))
  }


    findStudent=async(hint)=>{
        const server_reponse  = await ajaxSchool.searchStudent(hint);
        if(server_reponse.status === "OK"){
            this.setState({
              studentListing:server_reponse.details
            })
        }else{
          this.setState({
            studentListing:"404"
          })
        }
    }



    RenderStudent = () => {
        const studentListing = this.state.studentListing
        const optionStudent = []
          Array.isArray(studentListing) && studentListing.map((item, key) =>
            optionStudent.push({
              label: item.last_name + ' ' + item.first_name + ' - ' + item.reg_no + ' (' + item.class.class_name + ')',
              value: item.id,
              record:item
            })
          )
        
    
        return (
          <TextInput
            label='Student'
            type='select'
            leftIcon='fa fa-user'
            placeholder='Type & Select student....'
            options={optionStudent}
            onChange={this.onChangeStudent}
            leftComponent={
              <i className='fa fa-user'></i>
            }
            loading={!studentListing ? true : false}
            onType={(e)=>{this.findStudent(e.target.value)}}
            wrap={this.props.wrap}
          />
        )
      }
    
  


    render(){
        return <this.RenderStudent/>

    }

   

}


export default StudentInputField