import React from 'react'
import { Link } from 'react-router-dom'

class TitleValueCard extends React.Component {

    render() {
        return (

            <div className="col-12 col-sm-6 col-md-3">
            <div className="info-box">
             {this.props.icon}
              <div className="info-box-content">
                <span className="info-box-text">{this.props.title}</span>
                <span className="info-box-number">
                  {this.props.value}
                  <small>{this.props.description}</small>
                </span>
              </div>
            </div>
          </div>
        
        )
    }

}

export default TitleValueCard
