import React from "react";

const LoginAd=(props)=>{

    if(process.env.REACT_APP_COMPANY_TYPE*1===1){

        return <div className='login-ad'>
          
        <h1>Welcome to YEXP <br/>Accounting</h1>
        <ul className='functions-list'>
          <li>Keep track of your company financial position</li>
          <li>Easily monitor company revenue</li>
          <li>Easily monitor company expenses</li>
          <li>Keep track of your payables</li>
          <li>Keep track of your receivables and assets</li>
        </ul>
    </div>

      }else{

        return <div className='login-ad'>
          
        <h1>Welcome to YEXP <br/>School Accounting</h1>
        <ul className='functions-list'>
          <li>Includes student invoicing</li>
          <li>Easily monitor school fees payment</li>
          <li>Pay via mobile money and update student ledger in realtime</li>
          <li>Monitor daily school income and expenditure</li>
          <li>Monitor school financial position</li>
        </ul>
    </div>
      }
}


export default LoginAd