import React, { useEffect, useState } from "react";
import ActivityLoader from "../Common/ActivityLoader";
import AlertError from "../Common/AlertError";
import AlertSuccess from "../Common/AlertSuccess";
import SystemModal from "../Common/SystemModal";

const LoadingWidget=(props)=>{


    const [info, setInfo] = useState(false)

    useEffect(()=>{
        remoteCall()
    }, [])



    const remoteCall=async()=>{
  
           
            setInfo(<ActivityLoader/>)
            const server_response = await props.ajaxCall();
            if(server_response.status==="OK")
            {
               
                setInfo(<AlertSuccess message={server_response.message}/>)
                
            }else{
                
                setInfo(<AlertError message={server_response.message}/>)
                
            }

        }
    





    return(
        <SystemModal id="mdl-loader" title={props.title}>
       

            {info}
   

        </SystemModal>)


}


export default LoadingWidget;