import React, { Component, useContext } from 'react'
import { Link } from 'react-router-dom'
import SchoolContext from '../../Context/SchoolContext'
// import Chart from 'react-apexcharts'
import ActivityLoader from '../Common/ActivityLoader'
import AlertError from '../Common/AlertError'
import CardTitle from '../Common/CardTitle'

const RecentStudents =()=> {

    const { recentStudents } = useContext(SchoolContext);

        return (
            <div className="card">
                <CardTitle
                    title="Recent added students"
                    subtitle="Recently added synchronized students."
                />

                <div className="card-body p-0 overflow-auto h-200">
                    <div className="table-responsive">
                        {Array.isArray(recentStudents) && <table className="table m-0">
                            <thead>
                                <tr>
                                    <th>Reg No</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Class</th>
                                    <th>Added on</th>
                                </tr>
                            </thead>
                            <tbody>
                               {recentStudents.map((item, key)=> <tr key={key}>
                                    <td> 
                                        <Link to={
                                                {
                                                    pathname:`/school/fees/students/profile/${item.id}`,
                                                    state:{studentId:item.id}
                                                }
                                        }>{item.reg_no}</Link></td>
                                    <td>{item.first_name}</td>
                                    <td>{item.last_name}</td>
                                    <td><span className="badge badge-warning">{item.class.class_name}</span></td>
                                    <td>
                                        <div className="sparkbar" data-color="#00a65a" data-height="20"> {item.created_at.short_date + " " + item.created_at.time} </div>
                                    </td>
                                </tr>)}
                               
                            </tbody>
                        </table>}
                        {!recentStudents && <ActivityLoader/>}
                        {recentStudents==="404" && <AlertError type="info"  message="No recent student found. Click Sync students to add students"/>}
                    </div>
                </div>
                <div className="card-footer clearfix">
                    <Link to="#" className="btn btn-sm btn-info float-left">Sync Student</Link>
                    <Link to="/school/fees/students" className="btn btn-sm btn-secondary float-right">View All Students</Link>
                </div>
            </div>
        )
    
}

export default RecentStudents
