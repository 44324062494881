/* eslint-disable import/no-anonymous-default-export */

import apiCall from "./apiCall"



export default {


    async fetchTotalFeesInvoiced(data){
        let response = await apiCall("fees/billing/total", data);
        return response;
    },

    async fetchTotalFeesPaid(data){
        let response = await apiCall("fees/payment/total", data);
        return response;
    },

    async fetchCollectionPercentage(data){
        let response = await apiCall("fees/payment/percentage", data);
        return response;
    },

    async fetchTotalStudentsPopulation(data){
        let response = await apiCall("fees/student/total", data);
        return response;
    },

    async fetchTotalStudentsBilled(data){
        let response = await apiCall("fees/student/total/billed", data);
        return response;
    },

    async fetchStatement(data){
        let response = await apiCall("fees/statement", data);
        return response;
    },

    async fetchStudentStatement(data){
        let response = await apiCall("fees/student/statement", data);
        return response;
    },

    async fetchStudentBalance(student_id){
        let response = await apiCall("fees/student/statement", {"student_id":student_id});
        return response;
    },

    async payFees(data){
        let response = await apiCall("fees/payment/new", data);
        return response;
    },

    async addBatch(batch_name, start_date, end_date){
        let data={
            "batch_name":batch_name,
            "start_date":start_date,
            "end_date":end_date
        }
        let response = await apiCall("fees/billing/batch/add", data);
        return response;
    },

    async updateStudentFeesGroup(student_id, group_id){
        let data={
            "student_id":student_id,
            "fees_group_id":group_id
        }
        let response = await apiCall("fees/student/group/update", data);
        return response;
    },

    async billStudent(student_id){
        let response = await apiCall("fees/student/bill", {"student_id":student_id});
        return response;
    },

    async syncStudents(class_id){
        let response = await apiCall("fees/student/sync", {"ref_class_id":class_id});
        return response;
    },


    async syncClasses(){
        let response = await apiCall("fees/student/class/sync", {"source":"admin"});
        return response;
    },

    async getGroupStructure(group_id){
        let response = await apiCall("fees/structure/info", {"fees_group_id":group_id});
        return response;
    },

    async defineGroupStructure(group_id, vote_id, amount){
        let data = {
            "amount":amount,
            "vote_id":vote_id,
            "fees_group_id":group_id
          }
        let response = await apiCall("fees/structure/define", data);
        return response;
    },

    async createFeesGroup(group_name, description){
        let data = {
            "group_name":group_name,
            "description":description
          }
        let response = await apiCall("fees/group/add", data);
        return response;
    },


    async createFeesVote(vote_name, income_account, cash_account){
        let data = {
            "vote_name":vote_name,
            "income_account":income_account,
            "cash_account":cash_account
          }
        let response = await apiCall("fees/structure/votes/add", data);
        return response;
    },


    async getCurrentBatch(){
    
        let response = await apiCall("fees/batch/current", []);
        return response;
    },

    async getClassInvoiceBreakdown(data){
    
        let response = await apiCall("fees/invoice/breakdown/class", data);
        return response;
    },

    async fetchFeesGroups(){
    
        let response = await apiCall("fees/group/list", []);
        return response;
    },

    async fetchFeesGroupStructure(){
    
        let response = await apiCall("fees/group/structure/list", []);
        return response;
    },


    async fetchRecentStudents(){
    
        let response = await apiCall("fees/student/list/recent", []);
        return response;
    },

    async getFeesGraph(data){
    
        let response = await apiCall("fees/graph", data);
        return response;
    },

    async fetchAllStudents(data){
    
        let response = await apiCall("fees/student/list/all", data);
        return response;
    },

    async fetchAllClasses(){
    
        let response = await apiCall("fees/student/classes/list", []);
        return response;
    },

    async fetchBiillingBatches(){
    
        let response = await apiCall("fees/batch/list", []);
        return response;
    },

    async fetchFeesGroupInfo(group_id){
    
        let data={"fees_group_id":group_id}
        let response = await apiCall("fees/group/info", data);
        return response;
    },

    async fetchFeesVotes(){
    
        let response = await apiCall("fees/votes/list", []);
        return response;
    },
    
    async searchStudent(hint){
    
        let data={"hint":hint}
        let response = await apiCall("fees/student/search", data);
        return response;
    },


    async fetchRecentPayments(data){
    
        let response = await apiCall("fees/payment/recent", data);
        return response;
    },

    async fetchRecurringDiscounts(data){
    
        let response = await apiCall("fees/discount/recurring/list", data);
        return response;
    },


    async createDiscount(data){
    
        let response = await apiCall("fees/discount/add", data);
        return response;
    },

    async fetchRecentDiscounts(data){
    
        let response = await apiCall("fees/discount/recent", data);
        return response;
    },


    async configureFeesDiscount(data){
    
        let response = await apiCall("fees/config/discount", data);
        return response;
    },


    async fetchFeesConfig(data){
    
        let response = await apiCall("fees/config/list", data);
        return response;
    },
    

    async fetchQuickStat(){
    
        let response = await apiCall("fees/stat", []);
        return response;
    },

    async fetchFeesDefaulters(data){
    
        let response = await apiCall("fees/defaulters", data);
        return response;
    },

    async fetchStudentProfile(student_id){
    
        let response = await apiCall("fees/student/info", {"student_id":student_id});
        return response;
    },


    async importSchoolPay(data){
    
        let response = await apiCall("fees/schoolpay/import", data);
        return response;
    },


    async fetchBillingRecords(data){
    
        let response = await apiCall("fees/billing/list", data);
        return response;
    },


    async addCustomBill(studentId, voteId, amount, description){

        const data = {
            'student_id':studentId, 
            'vote_id':voteId, 
            'amount':amount, 
            'description':description
        }
    
        let response = await apiCall("fees/billing/custom/add", data);
        return response;
    },




    async syncResidence(){
        let response = await apiCall("fees/residence/sync", {"source":"admin"});
        return response;
    },


    async fetchResidences(){
    
        let response = await apiCall("fees/residence/list", []);
        return response;
    },

    async fetchBillingConfig(){
    
        let response = await apiCall("fees/billing/config/list", []);
        return response;
    },


    async createBillingConfig(data){
    
        let response = await apiCall("fees/billing/config/add", data);
        return response;
    },


    async autoBillStudents(data){
    
        let response = await apiCall("fees/billing/auto", data);
        return response;
    },


    async updateFeesGroup(data){
    
        let response = await apiCall("fees/group/update", data);
        return response;
    },


    async updateBatch(batch_id, batch_name, start_date, end_date){
        let data={
            "batch_id": batch_id,
            "batch_name":batch_name,
            "start_date":start_date,
            "end_date":end_date
        }
        let response = await apiCall("fees/billing/batch/update", data);
        return response;
    },
    

    async activateBilling(studentId){
    
        const data={
            "student_id":studentId
        }
        let response = await apiCall("fees/student/activate", data);
        return response;
    },

    async deactivateBilling(studentId){
    
        const data={
            "student_id":studentId
        }
        let response = await apiCall("fees/student/deactivate", data);
        return response;
    },

    async deleteFeesVote(voteId){
    
        const data={
            "vote_id":voteId
        }
        let response = await apiCall("fees/vote/delete", data);
        return response;
    },


    async declineBill(studentId){
    
        const data={
            "student_id":studentId
        }
        let response = await apiCall("fees/student/unbill", data);
        return response;
    },


    async switchBillingBatch(batchId){
    
        const data={
            "batch_id":batchId
        }
        let response = await apiCall("fees/batch/current/switch", data);
        return response;
    },

    
    async deleteBillingConf(configId){
    
        const data={
            "config_id":configId
        }
        let response = await apiCall("fees/billing/config/delete", data);
        return response;
    },
    

    async autoAssignFeesGroup(configId){
    
        const data={
            "config_id":configId
        }
        let response = await apiCall("fees/feesgroup/assign/auto", data);
        return response;
    },
    




} 