import React from 'react';

class CardTitle extends React.Component {

    render() {

        return (

            <div className="card-header d-flex align-items-center justify-content-between">

                {/* <h3 className="card-title">Recently Fees Groups</h3> */}

                <div className='d-flex flex-column'>
                    <h3 className="card-title mb-1">{this.props.title}</h3>
                    <p className="text-muted card-sub-title">{this.props.subtitle}</p>
                </div>

                {this.props.filter &&
                    <div className="card-option d-flex">


                        <div className="card-option-title ml-auto">
                           {this.props.filter}
                        </div>
                    </div>
                }

                {/* {this.props.tools &&
                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>
                        <button type="button" className="btn btn-tool" data-card-widget="remove">
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                } */}
            </div>

        )
    }

}

export default CardTitle;
