import React from 'react'

class LoadingPage extends React.Component {

    state = {
      progress:10
    }


    componentDidMount() {
       setInterval(() => {
           if(this.state.progress<90){
                this.setState({
                    progress:this.state.progress + 10
                })
            }
       }, 1000);
    }


    render() {

        return (

            <div className="page">
                <div className="main-content">
                    <div className="container-fluid">

                        <div style={{display:"flex", justifyContent:"center", alignContent:"center", alignItems:"center", flexDirection:"column", marginTop:"10%"}}>
                            <div className="progress mg-b-10" style={{width:"100%"}}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" 
                                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.progress + "%"}}>{this.state.progress}%
                                </div>
                            </div>
                            <div><b>Loading YEXP Accounting...</b></div>

                        </div>
                       
                    </div>
                </div>
            
            </div>

        );

    }
}

export default LoadingPage;
