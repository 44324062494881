import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ajaxAsset from '../../remote/ajaxAsset';
import ActivityLoader from '../Common/ActivityLoader';

class AssetBalances extends Component {

    state={
        balanceList:false,
        info:''
    }

    componentDidMount() {

        this.getBalances();
    }


    getBalances=async()=>{

        const server_response = await ajaxAsset.listAssetBalances();
        if(server_response.status==="OK")
        {
            this.setState({
                balanceList:server_response.details
            })
        }else{
            this.setState({
                balanceList:"404",
                info:server_response.message
            })
        }
    }

    render() {
        const list = this.state.balanceList;
        return (

            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Balance Accounts</h3>

                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                  </button>
                  {/* <button type="button" className="btn btn-tool" data-card-widget="remove"><i className="fas fa-times"></i>
                  </button> */}
                </div>
              </div>


              <div className="card-body overflow-auto h-200">
              {!list && <ActivityLoader/>}
              {list==="404" && <h5 className="ml-1">{this.state.info}</h5>}
              <ul className="nav nav-pills flex-column">
              {list && list!=="404" && list.map((item, key)=>
                  <li className="nav-item" key={key}>
                    <Link to={
                                {
                                    pathname:`/reports/account-statement/${item.account_id}`,
                                    state:{accountId:item.account_id}
                                }
                            } >
                      {item.account_name}
                      <span className="float-right text-success">
                        {/* <i className="fas fa-arrow-up text-sm"></i> */}
                        {item.balance.amount_p}
                     </span>
                    </Link>
                  </li>)}
                 
                </ul>

              </div>  
              
            </div>

        );
    }
}

export default AssetBalances;