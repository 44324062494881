import React, { Component } from 'react';
import { AccountsProvider } from './AccountsContext';
import { AuthProvider } from './AuthContext';
import { ConfigProvider } from './ConfigContext';
import { InvoicingProvider } from './InvoicingContext';
import { SchoolBillingProvider } from './SchoolBillingContext';
import { SchoolProvider } from './SchoolContext';
import { SchoolDefaulterProvider } from './SchoolDefaulterContext';
import { SchoolDiscountProvider } from './SchoolDiscountContext';
import { SchoolPaymentProvider } from './SchoolPaymentContext';
import { SearchProvider } from './SearchContext';
import { ThemeProvider } from './ThemeContext';

class SuperProvider extends Component {
    render() {
        return (
           <ThemeProvider>
            <AuthProvider>
                <ConfigProvider>
                    <SchoolProvider>
                        <AccountsProvider>
                            <SchoolPaymentProvider>
                                <SchoolDiscountProvider>
                                    <SchoolDefaulterProvider>
                                        <SchoolBillingProvider>
                                            <InvoicingProvider>
                                                <SearchProvider>
                                                  {this.props.children}
                                                </SearchProvider>
                                            </InvoicingProvider>
                                        </SchoolBillingProvider>
                                    </SchoolDefaulterProvider>
                                </SchoolDiscountProvider>
                            </SchoolPaymentProvider>
                        </AccountsProvider>
                    </SchoolProvider>
                </ConfigProvider>
            </AuthProvider>
            </ThemeProvider>
                
            );
    }
}

export default SuperProvider;