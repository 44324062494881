import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import AlertError from '../../Components/Common/AlertError'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import CallOut from '../../Components/Common/CallOut'
import PageNav from '../../Components/Common/PageNav'
import TextInput from '../../Components/Common/TextInput'
import WarnDelete from '../../Components/Common/WarnDelete'
import PageContainer from '../../Components/Headings/PageContainer'
import FeesVotes from '../../Components/SchoolFees/FeesVotes'
import NewFeesGroup from '../../Components/SchoolFees/NewFeesGroup'
import PopCreateFeesVote from '../../Components/SchoolFees/PopCreateFeesVote'
import PopDefineStructure from '../../Components/SchoolFees/PopDefineStructure'
import SchoolContext from '../../Context/SchoolContext'
import ajaxSchool from '../../remote/ajaxSchool'
import functions from '../../util/functions'

class BillStructurePage extends Component {

  static contextType = SchoolContext

  state = {
   groupId:'',
   groupInfo:false,
   recordId:false,
   modal:false,
   voteId:false,
   info:false
  }


  componentDidMount() {


    if(!this.props.match.params.groupId)
    {
        this.props.history.push('/');
    }else{
        this.setState({
            groupId:this.props.match.params.groupId
        }, ()=>{
            this.getGroupInfo();

        })
    }

}

handleVoteSelection=(voteId)=>{
  this.setState({voteId})
}

getGroupInfo =async()=>{
    const server_response = await ajaxSchool.fetchFeesGroupInfo(this.state.groupId)
    if(server_response.status==="OK")
    {
        this.setState({groupInfo:server_response.details})
    }else{
         this.props.history.push('/');
    }
}


handleAddVote=()=>{
    
      this.setState({modal:false}, ()=>{
        this.setState({modal:<PopCreateFeesVote/>}, ()=>{
          functions.openPopup("mdl-fees-vote")
        })
      })
     
   
}


handleDefine=()=>{
  this.setState({modal:false}, ()=>{
    this.setState({modal:<PopDefineStructure 
      refresh={this.getGroupInfo} 
      groupId={this.state.groupId}/>}, ()=>{
    functions.openPopup("mdl-define-structure")
    })

  })

  
  
}


handleDeleteVote=()=>{

  this.setState({
    modal:false
}, ()=>{

    this.setState({
        modal: <WarnDelete 
        title="Delete Fees Vote" 
        description={"Confirm that you want to delete the selected fees vote. Make sure it's not defined in any fees group. Note that you can remove a vote from a fees group by assigning it zero amount."}
        id="mdl_delete_vote"
        buttonType="danger"
        onConfirm={this.deleteVote}
        buttonLabel="Confirm Delete"
        />
    }, ()=>{functions.openPopup("mdl_delete_vote")})

})
}



deleteVote =async()=>{
  const server_response = await ajaxSchool.deleteFeesVote(this.state.voteId)
  functions.closePopup("mdl_delete_vote")
  if(server_response.status==="OK")
  {
      this.setState({info:<AlertSuccess message={server_response.message}/>})
      this.context.listFeesVotes()
  }else{
    this.setState({info:<AlertError message={server_response.message}/>})
  }
}


  RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
                 <li>
                    <Link to="#" className="btn ripple btn-primary mb-1" onClick={this.handleAddVote}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Create Vote</Link>
                </li>
                <li>
                    <Link to="#" className="btn ripple btn-success mb-1" onClick={this.handleDefine}>
                        <i className="fa fa-plus mr-1"></i>&nbsp;Define Structure</Link>
                </li>

                {this.state.voteId && <li>
                    <Link to="#" className="btn ripple btn-danger mb-1" onClick={this.handleDeleteVote}>
                        <i className="fa fa-trash mr-1"></i>&nbsp;Delete Vote</Link>
                </li>}
               
            </ol>
           

            
        </div>
      
    )
  }

  

  onSelectRecord = (recordId) => {
    this.setState({ recordId })
  }


  render() {

    const groupInfo = this.state.groupInfo
    return (
      <>
       

        {groupInfo && groupInfo!=="404" && <PageContainer 
          title='Manage Fees Group'
          headerNav={<this.RenderControls/>}
          >
            {this.state.modal}
          <CallOut
            title={groupInfo.group_info.group_name}
            description={groupInfo.group_info.description}
          />
          {this.state.info}
          <div className='row'>
            <div className='col-md-7'>
              <div className='card'>
                <div className='card-header border-transparent'>
                  <h3 className='card-title'>Fees Structure</h3>
                </div>

                <div className='card-body'>
                 


                <div className='table table-responsive table-ellipsis'>
          {groupInfo.structure &&  Array.isArray(groupInfo.structure.votes)  && (
            <table className='table table-bordere text-nowrap mb-0'>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Vote Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {groupInfo.structure.votes.map((item, key) => (
                  <tr
                    className={`${item.id === this.state.recordId
                        ? 'flag-row'
                        : 'hot-tr'
                      }`}
                    style={{ cursor: 'default' }}
                    key={key}
                    onClick={() => this.onSelectRecord(item.id)}
                  >
                    <td>{key + 1}</td>
                    <td>{item.vote.vote_name}</td>
                    <td>{item.amount.amount_c}</td>
                   
                  </tr>
                ))}
                <tr>
                    <td>&nbsp;</td>
                    <td><b>Total</b></td>
                    <td><b>{groupInfo.structure.total_fees.amount_p}</b></td>
                </tr>
              </tbody>
            </table>
          )}
          {groupInfo && !groupInfo.structure === '404' && <AlertError type="info" message="No fees structure found for fees class"/>}
        </div>





                </div>
              </div>
            </div>

            <div className='col-md-5'>
                      

                   <FeesVotes onSelect={this.handleVoteSelection}/>


            </div>
          </div>
        </PageContainer>}
        {!groupInfo && <ActivityLoader/>}
      </>
    )
  }
}

export default BillStructurePage
