import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SystemNotify extends Component {

	state={
		totalMessages:0,
		totalNotifications:0,
		totalTasks:0
	}

    render() {
        return <li className="nav-item dropdown">
					<Link className="nav-link" data-toggle="dropdown" to="#">
						<i className="far fa-bell"></i>
						{/* <span className="badge badge-warning navbar-badge">15</span> */}
					</Link>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">No Notifications</span>
						<div className="dropdown-divider"></div>
						{/*
						<a href="#" className="dropdown-item">
						<i className="fas fa-envelope mr-2"></i> 4 new messages
						<span className="float-right text-muted text-sm">3 mins</span>
						</a>
						
						<a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
						*/}
					</div>
			</li>
        
    }
}

export default SystemNotify;