import React from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../Common/ActivityLoader';

const LoadMoreButton =(props)=>{

    return(
        <ul className="pagination justify-content-end">
                     {!props.loading && <li className={`page-item`}>
                        <Link
                            className="page-link"
                            to="#"
                            onClick={props.action}>
                               {props.title || "Load older records"} &nbsp;&nbsp;
                                <i className='fa fa-chevron-right'/> 
                            </Link>
                        </li>}
                       {props.loading && <li className={`page-item`}>
                            <Link
                            className="page-link"
                            to="#" style={{color:"#000"}}>
                            Loading more... &nbsp;&nbsp;<ActivityLoader size={20} inline/>
                            </Link>
                        </li>}
                       
                        </ul>
    )

}

export default LoadMoreButton