import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AccountListing from '../../Components/AccountsChart/AccountListing';
import ImportAccount from '../../Components/AccountsChart/ImportAccount';
import NewAccount from '../../Components/AccountsChart/NewAccount';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';

class ChartOfAccountsPage extends Component {

    state={
        activeTab:"0",
    }


    setActiveTab=(value)=>{
        this.setState({
            activeTab:value
        })
    }


   
   
    render() {
        return (
            <PageContainer title="Chart Of Accounts">
            <div className="row">
                <div className="col-md-12">

                <div className="card card-primary card-outline card-tabs">
              <div className="card-header p-0 pt-1 border-bottom-0">
                <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true">Accounts Listing</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false">New Account</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="custom-tabs-three-messages-tab" data-toggle="pill" href="#custom-tabs-three-messages" role="tab" aria-controls="custom-tabs-three-messages" aria-selected="false">Import Accounts</a>
                  </li>
                 
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-three-tabContent">
                  <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                         <AccountListing/>
                  </div>
                  <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                         <NewAccount/>
                  </div>
                  <div className="tab-pane fade" id="custom-tabs-three-messages" role="tabpanel" aria-labelledby="custom-tabs-three-messages-tab">
                        <ImportAccount/>
                  </div>
                 
                </div>
              </div>



            </div>



                

                </div>
            </div>
        </PageContainer>
         
        );
    }
}

export default ChartOfAccountsPage;