import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import TableHeader from '../../Components/Common/TableHeader';
import PageContainer from '../../Components/Headings/PageContainer';
import LoadMoreButton from '../../Components/NavMenus/LoadMoreButton';
import InvoicingContext from '../../Context/InvoicingContext';

class InvoicingPage extends Component {

    static contextType = InvoicingContext

    state={
        ledgerId:false,
        modal:false,
        loading:false,
        info:'',
        record:false
    }
   

    // RenderLedgers = () => {
    //     const transactions = this.state.transactions;
    //     return (
    //         <>
    //             <ReportFilter
    //                 title="Manage ledger entries"
    //                 filterSelection={this.filterTransactions}
    //             />
               
    //         </>
    //     )
    // }


    onSelectRecord=(ledgerId, record)=>{
        this.setState({ledgerId, record})
    }

    handleRefresh=()=>{
        this.context.getInvoicingTransactions()
    }

   





    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                    {this.state.record && <li> <Link to={
                                                {
                                                    pathname:`/expenses/invoicing/pay/${this.state.record.id}`,
                                                    state:{record:this.state.record}
                                                }
                                                }
                                                className="btn ripple btn-warning mb-1"
                                                >
                            <i className="fa fa-handshake mr-1"></i>&nbsp;Pay Invoice</Link>
                        </li>}
                    <li>
                        <Link to="/expenses/invoicing/new" className="btn ripple btn-success mb-1">
                            <i className="fa fa-edit mr-1"></i>&nbsp;New Invoice</Link>
                    </li>
                    
                    <li>
                        <Link to="#" className="btn ripple btn-secondary mb-1" onClick={this.handleRefresh}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

      
    

     


    render() {

        const {transactionListing, metaData, loading, goToNextPage} = this.context;

        return (
            <PageContainer 
                title="Vendor Invoicing"
                headerNav={<this.RenderControls/>}
                >
                {this.state.modal}
                <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card ">
                            <div className="card-body map-card">

                        <TableHeader
                            title="Incoming Invoices"
                            subtitle="List of all suppliers/creditors invoice records"
                        />

                        <LoadMoreButton
                          title={metaData.current_page + " of " + metaData.total_pages}
                          action={goToNextPage}
                          loading={loading}
                        />

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                          <div className="table-responsive table-ellipsi">
                            <div className="card-body p-0">
                                {this.state.info}
                                    {!transactionListing && <ActivityLoader />}
                            {Array.isArray(transactionListing) && <table className="table table-bordered text-nowrap mb-0 datatabl" id='table-billing'>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Invoice Date</th>
                                        <th>Vendor</th>
                                        <th>Invoice Ref</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        <th>Expense Account</th>
                                        <th>L/R</th>
                                        <th>Added On</th>
                                        <th>Added By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionListing.map((item, key) =>

                                        <tr
                                            className={`${item.ledger_id === this.state.ledgerId ? 'flag-row' : 'hot-tr'}`}
                                            style={{ cursor: 'default' }}
                                            key={key}
                                            onClick={() => this.onSelectRecord(item.ledger_id, item)}
                                            id={"invoice" + item.id}
                                        >
                                            <td>{key+1}</td>
                                            <td>{item.invoice_date.short_date}</td>
                                            <td>{item.vendor.title}</td>
                                            <td>{item.invoice_ref}</td>
                                            <td>{item.description}</td>
                                            <td>{item.amount.amount_c}</td>
                                            <td>{item.invoice_balance.amount_c}</td>
                                            <td>{item.expense_account.account_name}</td>
                                            <td>#{item.ledger_id}</td>

                                            <td>{item.created_on.short_date + " " + item.created_on.time}</td>
                                            <td>{item.created_by.last_name + " " + item.created_by.first_name}</td>

                                        </tr>

                                    )}

                                </tbody>
                            </table>}

                            {transactionListing === '404' && <AlertError type="info" message="No invoice records found"/>}

                        
                        
                            </div>
                            </div>
                        </div>
                       
                       
                    </div>
                </div>
                </div>

                </div>
            </PageContainer>

        );
    }
}

export default InvoicingPage;
