import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';
import ajaxIncome from '../../remote/ajaxIncome';
import ajaxPayee from '../../remote/ajaxPayee';

class NewVendorPage extends Component {

    state={
        info:'',
        loading:false,
        vendorName:'',
        description:'',
        address:'',
        phoneNumber:'',
        emailAddress:''
    }


   onChangeVendorName=(event)=>{
        this.setState({
            vendorName:event.target.value
        })
   }
   onChangeDescription=(event)=>{
        this.setState({
            description:event.target.value
        })
    }

    onChangeAddress=(event)=>{
        this.setState({
            address:event.target.value
        })
    }

    onChangePhoneNumber=(event)=>{
        this.setState({
            phoneNumber:event.target.value
        })
    }

    onChangeEmail=(event)=>{
        this.setState({
            emailAddress:event.target.value
        })
    }

   
    onSave=async(event)=>{
        event.preventDefault();

        const {vendorName, description, address, phoneNumber, emailAddress}=this.state;
        

        if(vendorName.length<1 || description.length<1)
        {
            this.setState({
                info:<AlertError message="Vendor name and business description are required"/>
            })

            return false;
        }


        this.setState({
            loading:true
        })
        const server_response = await ajaxPayee.addVendor(
            vendorName, description, address, phoneNumber, emailAddress
        );
        this.setState({
            loading:false
        })

        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>,
                vendorName:'',
                description:'',
                address:'',
                phoneNumber:'',
                emailAddress:''
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }

    }


    RenderControls=()=>{
        return (
          <Link to="/vendor/import" className="btn ripple btn-warning mb-1">
            <i className="fa fa-upload mr-1"></i>&nbsp;Import Vendors</Link>
        )
      }


   
    render() {
        return (
        
             <PageContainer title="New Vendor">
            <div className="row">
                <div className="col-md-12">
                    <CallOut title="Info" description="Add new vendor for your transaction payees and funds sources."/>
                   
                    <div className="card">
                {/* <div className="card-header"></div> */}

                <div className="card-body">
            
                    <form className="form-horizontal" onSubmit={this.onSave}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}

                        <TextInput
                            type="text"
                            label="Vendor Name"
                            placeholder="Vendor Name"
                            leftIcon="fa fa-user"
                            value={this.state.vendorName}
                            onChange={this.onChangeVendorName} 
                            required
                            />

                        <TextInput
                            type="textarea"
                            label="Business Description"
                            placeholder="Business Description"
                            leftIcon="fa fa-list"
                            value={this.state.description}
                            onChange={this.onChangeDescription} 
                            required
                            />

                           


                            <div className="row">&nbsp;</div>

                            <TextInput
                            type="text"
                            label="Vendor Address"
                            placeholder="Vendor Address"
                            leftIcon="fa fa-map-pin"
                            value={this.state.address}
                            onChange={this.onChangeAddress} 
                            />
        
                          

                            <TextInput
                            type="text"
                            label="Phone Number"
                            placeholder="Phone Number"
                            leftIcon="fa fa-phone"
                            value={this.state.phoneNumber}
                            onChange={this.onChangePhoneNumber} 
                            />

                        <TextInput
                            type="email"
                            label="Email Address"
                            placeholder="Email Address"
                            leftIcon="fa fa-envelope"
                            value={this.state.emailAddress}
                            onChange={this.onChangeEmail} 
                            />
                          

                          

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                               {!this.state.loading && <button type="submit" className="btn btn-primary">Save Vendor</button>}
                            </div>

                        </form> 

   

                    </div>
                    </div>

                    </div>{/** body end */}

                

                </div>
           </PageContainer>
        );
    }
}

export default NewVendorPage;