import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import TableHeader from '../../Components/Common/TableHeader';
import PageContainer from '../../Components/Headings/PageContainer';
import LoadMoreButton from '../../Components/NavMenus/LoadMoreButton';
import SchoolPaymentContext from '../../Context/SchoolPaymentContext';

class PaymentsPage extends Component {

    static contextType = SchoolPaymentContext

    state={
        ledgerId:false,
        modal:false,
        loading:false,
        queuedStudent:false,
        info:''
    }
   



    onSelectRecord=(ledgerId)=>{
        this.setState({ledgerId})
    }

    handleRefresh=()=>{
        this.context.getSchoolPaymentTransactions()
    }

    




    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    <li>
                        <Link to="#" className="btn ripple btn-secondary mb-1" onClick={this.handleRefresh}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

     


    render() {

        const {transactionListing, metaData, loading, goToNextPage} = this.context;

        return (
            <PageContainer 
                title="School Fees Payments"
                headerNav={<this.RenderControls/>}
                >
                {this.state.modal}
                <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card ">
                            <div className="card-body map-card">

                        <TableHeader
                            title="Recent Transactions"
                            subtitle="List of all school fees payments in order of the most recent."
                        />

                        <LoadMoreButton
                          title={metaData.current_page + " of " + metaData.total_pages}
                          action={goToNextPage}
                          loading={loading}
                        />

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                          <div className="table-responsive table-ellipsi">
                            <div className="card-body p-0">
                                {this.state.info}
                                    {!transactionListing && <ActivityLoader />}
                            {Array.isArray(transactionListing) && <table className="table table-bordered text-nowrap mb-0 datatabl" id='table-students'>
                                <thead>
                                    <tr>
                                        <th>Transaction Date</th>
                                        <th>Reg No</th>
                                        <th>Student Name</th>
                                        <th>Billing Period</th>
                                        <th>Amount Paid</th>
                                        <th>Description</th>
                                        <th>Reference</th>
                                        <th>Fees Vote</th>
                                        <th>Processed by</th>
                                        <th>Created At</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionListing.map((item, key) =>

                                        <tr
                                            className={`${item.ledger_id === this.state.ledgerId ? 'flag-row' : 'hot-tr'}`}
                                            style={{ cursor: 'default' }}
                                            key={key}
                                            onClick={() => this.onSelectRecord(item.ledger_id, item)}
                                            id={"student" + item.id}
                                        >
                                            <td>{item.transaction_date.short_date}</td>
                                            <td>{item.student.reg_no}</td>
                                            <td>{item.student.last_name + " " + item.student.first_name}</td>
                                            <td>{item.batch? item.batch.batch_name : "N.A"}</td>
                                            <td>{item.debit.amount_p}</td>
                                            <td>{item.description}</td>
                                            <td>{item.reference + " | " + item.ledger_grouping}</td>
                                            <td>{item.vote?item.vote.vote_name: "N.A"}</td>
                                            <td>{item.created_by.first_name + ' ' + item.created_by.last_name}</td>
                                            <td>{item.created_at.short_date + " " + item.created_at.time}</td>
                                        </tr>

                                    )}

                                </tbody>
                            </table>}
                        
                        
                            </div>
                            </div>
                        </div>
                       
                       
                    </div>
                </div>
                </div>

                </div>
            </PageContainer>

        );
    }
}

export default PaymentsPage;
