import React, { Component } from 'react';
import ajaxConfig from '../../remote/ajaxConfig';
import ActivityLoader from '../Common/ActivityLoader';
class Signatory extends Component {

    state={
        list:false
    }

    componentDidMount() {
        this.get_signatories();
    }

    get_signatories=async()=>{
        const server_response = await ajaxConfig.get_active_signatories();
        if(server_response.status==="OK")
        {
            this.setState({
                list:server_response.details
            })
        }else{
            this.setState({
                list:"404"
            })
        }
    }

    render() {
        const list = this.state.list
        return (
            <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:"30px"}}>
                {list && list!=="404" && list.map((item, key)=>
                 <div key={key}>
                    <div style={{fontSize:'12px'}}>{item.designation}</div>
                    <div style={{marginTop:'10px'}}>.......................</div>
                    <div style={{fontWeight:'bold', fontSize:'12px'}}>{item.last_name + ' ' + item.first_name}</div>
                </div>
                )}
                {!list && <ActivityLoader/>}
            </div>
        );
    }
}


export default Signatory;