import React, { Component, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopUpdateFeesGroup=(props)=>{

    const [feesGroupId, setFeesGroupId] = useState(props.groupId);
    const [loading, setLoading] = useState(false)
    const [groupName, setGroupName] = useState(props.record.group_name);
    const [description, setDescription] = useState(props.record.description);
    const [info, setInfo] = useState("");
    const {listFeesGroups} = useContext(SchoolContext);


   
   
    const onSave=async(event)=>{
        event.preventDefault();
    
        if(groupName.length>0 && description.length>0)
        {
            setInfo(<ActivityLoader/>)
            const server_response = await ajaxSchool.updateFeesGroup({
                "group_name":groupName, 
                "description": description,
                "group_id": feesGroupId
            });
    
            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
    
                listFeesGroups()
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }
        }else{
            setInfo(<AlertError message={dictionary._completeFields}/>)
        }
    }
    
   

   








        return (
            
    <SystemModal id="mdl-update-fees-group" title="Edit Fees Group">
        <form method="post" className="form-horizontal" onSubmit={onSave}>
               {info}



               <TextInput
                      type='text'
                      label='Title'
                      placeholder='Group reference title'
                      leftIcon='fa fa-tag'
                      value={groupName}
                      onChange={(e)=>setGroupName(e.target.value)}
                      required
                    />


                    <TextInput
                      type='textarea'
                      label='Description'
                      placeholder='Description'
                      leftIcon='fa fa-list'
                      value={description}
                      onChange={(e)=>setDescription(e.target.value)}
                      required
                    />
              
              
            </form> 
            
            <div className="modal-footer no-border">
               
                <button type="submit" className="btn btn-primary" onClick={onSave}>Save Changes</button>
            </div>

   

   

    </SystemModal>
        );
    
}

export default PopUpdateFeesGroup;