import React, { Component } from 'react';
import functions from '../../util/functions';
import LetterHead from '../Securities/LetterHead';
import Signatory from '../Securities/Signatory';
import ReportFilter from './ReportFilter';

class TrialBalance extends Component {


    state={
        showLevel0:true,
        showLevel1:true,
        showLevel2:false,
        openingDate:'',
        closingDate:''
    }

    toggleLeveL1=()=>{
        this.setState({
            showLevel1:!this.state.showLevel1
        }, ()=>{
            if(!this.state.showLevel1){
                this.setState({
                    showLevel2:false
                })
            }
        })
    }

    toggleLeveL2=()=>{

        this.setState({
            showLevel2:!this.state.showLevel2
        })
    }

    printStatement=()=>{

        functions.printElement("doc_statement_fin");
    }



    filterTransactions=(openingDate, closingDate)=>{
        this.setState({
          openingDate,
          closingDate
        }, ()=>this.props.changeDateView(openingDate, closingDate))
    }


    render() {
    
        const statement=this.props.data;

        return (
            <div className="row">
            <div className="col-lg-12 col-md-12">
            <ReportFilter 
                title={statement.header.statement_title}
                filterSelection={this.filterTransactions}
                print={this.printStatement}
                currentPeriodSelection={statement.header.period}
                />
                <div className="car">
                    <div className="card-body p-30">
                        <div className="d-lg-flex">
                            {/* <h2 className="card-title mb-1">{statement.header.statement_title}</h2> */}
                            <div className="ml-auto">
                                <p className="mb-1"><span className="font-weight-bold">Period Starting :</span> {statement.header.period.opening.short_date}</p>
                                <p className="mb-0"><span className="font-weight-bold">Period Ending :</span> {statement.header.period.closing.short_date}</p>
 
                            </div>
                        </div>
                        <hr className="mg-b-40"/>
                        
                        <div className="table-responsive mg-t-40" id="doc_statement_fin">
                            {!this.props.intervalView && <LetterHead title={statement.header.description}/>}
                            <table className="table table-invoice table-bordered" style={{fontSize:'12px'}}>
                                <tbody>
                                    <tr>
                                        <th className="wd-20p tx-left">Account title</th>
                                        <th className="wd-40p tx-right">Debit</th>
                                        <th className="tx-right">Credit</th>
                            
                                    </tr>



                                    {/** ASSETS */}
                               
                                   {statement.assets.account_listing &&  statement.assets.account_listing.map((incomeAccount, key)=><>
                                        <tr key={key}>{/**LEVEL 0 */}
                                            <td>
                                                <span className={`${incomeAccount.sub_accounts && this.state.showLevel1?"main-item":""}`}>{incomeAccount.account_name}</span>
                                            </td>
                                            <td className="tx-right">
                                                <span className={`${!this.state.showLevel1?"sho":"hide"}`}>{incomeAccount.balance.amount_p}</span>
                                            </td>
                                            <td className="tx-right"></td>
                                        </tr>
                                        {incomeAccount.sub_accounts && incomeAccount.sub_accounts.map((subItem, subKey)=> <>
                                            <tr key={subKey} className={`${this.state.showLevel1?"sho":"hide"}`}>{/**LEVEL 1 */}
                                                 <td>
                                                     <span className={`${subItem.sub_accounts && this.state.showLevel2?"main-sub-item":"sub-item"}`}>
                                                         {subItem.account_name}
                                                     </span>
                                                </td>
                                                 <td className="tx-right">
                                                    <span className={`${!this.state.showLevel2?"sho":"hide"}`}>{subItem.balance.amount_p}</span>
                                                </td>
                                                 <td className="tx-right"></td>
                                            </tr>

                                            {subItem.sub_accounts && subItem.sub_accounts.map((subSubItem, subSubKey)=>

                                                <tr key={subSubKey}  className={`${this.state.showLevel2?"sho":"hide"}`}>{/**LEVEL 2 */}
                                                <td>
                                                    <span className={`${subSubItem.sub_accounts?"sub-sub-item":"sub-sub-item"}`}>
                                                        {subSubItem.account_name}
                                                    </span>
                                               </td>
                                                <td className="tx-right">{subSubItem.balance.amount_p}</td>
                                                <td className="tx-right"></td>
                                              </tr>
                                            
                                            )}

                                            </>

                                        )}
                                        
                                        </>
                                        )}





                                         {/** LIABILITIES */}

                                     {statement.liabilities.account_listing &&  statement.liabilities.account_listing.map((expenseAccount, ekey)=><>
                                        <tr key={ekey}>{/** LEVEL 0 */}
                                         <td>
                                            <span className={`${expenseAccount.sub_accounts && this.state.showLevel1?"main-item":""}`}>{expenseAccount.account_name}</span>
                                        </td>
                                        <td className="tx-right"></td>
                                        <td className="tx-right">
                                            <span className={`${!this.state.showLevel1?"sho":"hide"}`}>{expenseAccount.balance.amount_p}</span>
                                        </td>
                                        </tr>


                                        {expenseAccount.sub_accounts && expenseAccount.sub_accounts.map((esubItem, esubKey)=><>
                                            <tr key={esubKey}  className={`${this.state.showLevel1?"sho":"hide"}`}>{/**LEVEL 1 */}
                                                 <td>
                                                     <span className={`${esubItem.sub_accounts && this.state.showLevel2?"main-sub-item":"sub-item"}`}>
                                                         {esubItem.account_name}
                                                     </span>
                                                </td>
                                                <td className="tx-right"></td>
                                                 <td className="tx-right">
                                                    <span className={`${!this.state.showLevel2?"sho":"hide"}`}>
                                                     {esubItem.balance.amount_p}
                                                    </span>
                                                </td>
                                            </tr>

                                           {esubItem.sub_accounts && esubItem.sub_accounts.map((eeitem, eekey)=>
                                              <tr key={eekey}  className={`${this.state.showLevel2?"sho":"hide"}`}>{/**LEVEL 2 */}
                                                <td>
                                                    <span className={`${eeitem.sub_accounts?"sub-sub-item":"sub-sub-item"}`}>
                                                        {eeitem.account_name}
                                                    </span>
                                               </td>
                                               <td className="tx-right"></td>
                                                <td className="tx-right">{eeitem.balance.amount_p}</td>
                                              </tr>
                                           )} 


                                            </>

                                        )}                                        
                                        
                                        </>)}










                                    {/** INCOME */}
                               
                                   {statement.income.account_listing &&  statement.income.account_listing.map((incomeAccount, key)=><>
                                        <tr key={key}>{/**LEVEL 0 */}
                                            <td>
                                                <span className={`${incomeAccount.sub_accounts && this.state.showLevel1?"main-item":""}`}>{incomeAccount.account_name}</span>
                                            </td>
                                            <td className="tx-right"></td>
                                            <td className="tx-right">
                                                <span className={`${!this.state.showLevel1?"sho":"hide"}`}>{incomeAccount.balance.amount_p}</span>
                                            </td>
                                           
                                        </tr>
                                        {incomeAccount.sub_accounts && incomeAccount.sub_accounts.map((subItem, subKey)=> <>
                                            <tr key={subKey} className={`${this.state.showLevel1?"sho":"hide"}`}>{/**LEVEL 1 */}
                                                 <td>
                                                     <span className={`${subItem.sub_accounts && this.state.showLevel2?"main-sub-item":"sub-item"}`}>
                                                         {subItem.account_name}
                                                     </span>
                                                </td>
                                                <td className="tx-right"></td>
                                                 <td className="tx-right">
                                                    <span className={`${!this.state.showLevel2?"sho":"hide"}`}>{subItem.balance.amount_p}</span>
                                                </td>
                                                 
                                            </tr>

                                            {subItem.sub_accounts && subItem.sub_accounts.map((subSubItem, subSubKey)=>

                                                <tr key={subSubKey}  className={`${this.state.showLevel2?"sho":"hide"}`}>{/**LEVEL 2 */}
                                                <td>
                                                    <span className={`${subSubItem.sub_accounts?"sub-sub-item":"sub-sub-item"}`}>
                                                        {subSubItem.account_name}
                                                    </span>
                                               </td>
                                               <td className="tx-right"></td>
                                                <td className="tx-right">{subSubItem.balance.amount_p}</td>
                                              </tr>
                                            
                                            )}

                                            </>

                                        )}
                                        
                                        </>
                                        )}


                                    
                                    {/** EXPENSES */}

                                     {statement.expenses.account_listing &&  statement.expenses.account_listing.map((expenseAccount, ekey)=><>
                                        <tr key={ekey}>{/** LEVEL 0 */}
                                         <td>
                                            <span className={`${expenseAccount.sub_accounts && this.state.showLevel1?"main-item":""}`}>{expenseAccount.account_name}</span>
                                        </td>
                                        <td className="tx-right">
                                            <span className={`${!this.state.showLevel1?"sho":"hide"}`}>{expenseAccount.balance.amount_p}</span>
                                        </td>
                                        <td className="tx-right"></td>

                                        </tr>


                                        {expenseAccount.sub_accounts && expenseAccount.sub_accounts.map((esubItem, esubKey)=><>
                                            <tr key={esubKey}  className={`${this.state.showLevel1?"sho":"hide"}`}>{/**LEVEL 1 */}
                                                 <td>
                                                     <span className={`${esubItem.sub_accounts && this.state.showLevel2?"main-sub-item":"sub-item"}`}>
                                                         {esubItem.account_name}
                                                     </span>
                                                </td>
                                                 <td className="tx-right">
                                                    <span className={`${!this.state.showLevel2?"sho":"hide"}`}>
                                                     {esubItem.balance.amount_p}
                                                    </span>
                                                </td>
                                                <td className="tx-right"></td>

                                            </tr>

                                           {esubItem.sub_accounts && esubItem.sub_accounts.map((eeitem, eekey)=>
                                              <tr key={eekey}  className={`${this.state.showLevel2?"sho":"hide"}`}>{/**LEVEL 2 */}
                                                <td>
                                                    <span className={`${eeitem.sub_accounts?"sub-sub-item":"sub-sub-item"}`}>
                                                        {eeitem.account_name}
                                                    </span>
                                               </td>
                                                <td className="tx-right">{eeitem.balance.amount_p}</td>
                                                <td className="tx-right"></td>

                                              </tr>
                                           )} 


                                            </>

                                        )}                                        
                                        
                                        </>)}
                                       
                                       
                                       
                                       
                                       
                                       
                                       
                                       
                                       
                                        <tr>
                                            <th className="tx-left">TOTAL</th>
                                            <th className="tx-right">{statement.total_debits.amount_p}</th>
                                            <th className="tx-right">{statement.total_credits.amount_p}</th>
                                        </tr>

                               
                                   
                                </tbody>

                                
                            </table>
                            <Signatory/>
                        </div>
                    </div>
                   {!this.props.intervalView && <div className="card-foote text-right p-30">
                        <button type="button" className="btn ripple btn-success mb-1" onClick={this.toggleLeveL1}><i className="fa fa-list mr-1"></i>Level 1</button> &nbsp;&nbsp;
                        <button type="button" className="btn ripple btn-warning mb-1" onClick={this.toggleLeveL2}><i className="fa fa-list mr-1"></i>Level 2</button> &nbsp;&nbsp;
                        <button type="button" className="btn ripple btn-primary mb-1" onClick={this.printStatement}><i className="fa fa-print mr-1"></i> Print Statement</button>
                    </div>}
                </div>
            </div>
        </div>
        );
    }
}

export default TrialBalance;