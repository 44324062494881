import React from 'react';
import UINCDLetterHead from './UINCDLetterHead';
import DefaultLetterHead from './DefaultLetterHead';
const LetterHead =(props)=>{

     
        const HeaderOptions=()=>{

            const remote_link = window.localStorage.getItem("yexp@remote");
            if(remote_link === "https://accountingapi.araknerd.com"){
                return <UINCDLetterHead {...props} />
            }
            switch(process.env.REACT_APP_COMPANY_CODE){

                case "UINCD":
                    return <UINCDLetterHead {...props} />
                default:
                    return <DefaultLetterHead {...props}/>

            }
        }


        return (
            <div>{HeaderOptions()}</div>
        );
    }


export default LetterHead;