import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import WarnDelete from '../../Components/Common/WarnDelete';
import PageContainer from '../../Components/Headings/PageContainer';
import PopAssignFeesGroup from '../../Components/SchoolFees/PopAssignFeesGroup';
import ProfileCard from '../../Components/SchoolFees/Student/ProfileCard';
import StudentFeeStatement from '../../Components/SchoolFees/Student/StudentFeeStatement';
import LoadingWidget from '../../Components/Widget/LoadingWidget';

import ajaxSchool from '../../remote/ajaxSchool';
import functions from '../../util/functions';
import ButtonGroup from '../../Components/Common/Controls/ButtonGroup';
import PopChangeBatch from '../../Components/SchoolFees/PopChangeBatch';
import ActivityLoader from '../../Components/Common/ActivityLoader';

class StudentProfile extends Component {

    state={
        studentId:false,
        profileInfo:false,
        batchId: false,
        modal:false,
        info:false,
        loading:false
    }


    componentDidMount() {


        if(!this.props.match.params.studentId)
        {
            this.props.history.push('/');
        }else{
            this.setState({
                studentId:this.props.match.params.studentId
            }, ()=>{
                this.getStudentInfo();
            })
        }
    
    }



getStudentInfo =async()=>{
    this.setState({
        profileInfo:false
    })
    const server_response = await ajaxSchool.fetchStudentProfile(this.state.studentId)
    if(server_response.status==="OK")
    {
        this.setState({profileInfo:server_response.details})
    }else{
         this.props.history.push('/');
    }
}


terminateBilling=async()=>{

    const server_response = await ajaxSchool.deactivateBilling(this.state.studentId)
    functions.closePopup("mdl_terminate_bill")
    if(server_response.status==="OK")
    {
        this.setState({
            info:<AlertSuccess message={server_response.message}/>
        })
        this.getStudentInfo();
    }else{
        this.setState({
            info:<AlertError message={server_response.message}/>
        })
    }
}


activateBilling=async()=>{

    const server_response = await ajaxSchool.activateBilling(this.state.studentId)
    functions.closePopup("mdl_activate_bill")

    if(server_response.status==="OK")
    {
        this.setState({
            info:<AlertSuccess message={server_response.message}/>
        })
        this.getStudentInfo();
    }else{
        this.setState({
            info:<AlertError message={server_response.message}/>
        })
    }
}



declineBill=async()=>{

    const server_response = await ajaxSchool.declineBill(this.state.studentId)
    functions.closePopup("mdl_decline_bill")

    if(server_response.status==="OK")
    {
        this.setState({
            info:<AlertSuccess message={server_response.message}/>
        })
        this.getStudentInfo();
    }else{
        this.setState({
            info:<AlertError message={server_response.message}/>
        })
    }
}



handleAutoBill=()=>{
    this.setState({
        modal:false
    }, ()=>{

        this.setState({
            modal:<LoadingWidget 
                        title="Billing Student" 
                        ajaxCall={()=>ajaxSchool.billStudent(this.state.studentId)}
                        />
        }, ()=>{functions.openPopup("mdl-loader")})

    })
  
}


handleFeesGroup=()=>{
    this.setState({
        modal:<PopAssignFeesGroup studentId={this.state.studentId} liveUpdate={false}/>
    }, ()=>{functions.openPopup("mdl-assign-fees-group")})
}


handleTerminateBilling=()=>{
    this.setState({
        modal:false
    }, ()=>{

        this.setState({
            modal: <WarnDelete 
            title="Deactivate Billing" 
            description={"Confirm that you want to deactivate this student. The student will nolonger be invoiced."}
            id="mdl_terminate_bill"
            buttonType="danger"
            onConfirm={this.terminateBilling}
            buttonLabel="Deactivate"
            />
        }, ()=>{functions.openPopup("mdl_terminate_bill")})

    })
   
}

handleActivateBilling=()=>{
    this.setState({
        modal:false
    }, ()=>{

        this.setState({
            modal: <WarnDelete 
            title="Activate Billing" 
            description={"Confirm that you want to activate this student. The student will now be active for invoicing."}
            id="mdl_activate_bill"
            buttonType="success"
            onConfirm={this.activateBilling}
            buttonLabel="Activate"
            />
        }, ()=>{functions.openPopup("mdl_activate_bill")})

    })
   
}



handleDeclineBill=()=>{
    this.setState({
        modal:false
    }, ()=>{

        this.setState({
            modal: <WarnDelete 
            title="Decline Bill" 
            description={"Confirm that you want to cancel the student's billing for this current batch. Previous batches will not be affected."}
            id="mdl_decline_bill"
            buttonType="success"
            onConfirm={this.declineBill}
            buttonLabel="Decline Bill"
            />
        }, ()=>{functions.openPopup("mdl_decline_bill")})

    })
   
}





RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
              
                <li>
                        <ButtonGroup title="Options">

                          {this.state.profileInfo.status*1===1 && <>
                            <Link to="#" className="dropdown-item" onClick={this.handleAutoBill}>
                                <i className="fa fa-edit mr-1 text-primary"></i>&nbsp;Bill Student</Link>
                            
                   
                            <Link to="#" className="dropdown-item" onClick={this.handleFeesGroup}>
                            <i className="fa fa-users mr-1 text-primary"></i>&nbsp;Fees Group</Link>

                            <Link to="#" className="dropdown-item" onClick={this.handleDeclineBill}>
                                <i className="fa fa-times mr-2 text-danger"></i>&nbsp;Decline Bill</Link>

                            </>}
                            <Link to="#" className="dropdown-item" onClick={this.handleStatmentHistory}>
                            <i className="fa fa-calendar mr-1 text-primary"></i>&nbsp;Payment History</Link>


                            <Link to="#" className="dropdown-item" onClick={this.getStudentInfo}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>

                          
                        </ButtonGroup>

                    </li>
            </ol>
           

            
        </div>
      
    )
  }



  handleStatmentHistory=()=>{
    this.setState({
        modal:<PopChangeBatch onConfirm={this.changeBatch}/>
    }, ()=>{functions.openPopup("mdl-change-batch")})
}


changeBatch=(batchId)=>{

    functions.closePopup("mdl-change-batch")
    this.setState({
        loading:true
    }, ()=>{
        this.setState({
            batchId:batchId,
            loading:false,
            modal:false
        })

       
    })


}

    render() {
        return (

            <PageContainer 
            
                title='Student Profile'
                headerNav={<this.RenderControls/>}
                
                >
                {this.state.modal}
                {/* <CallOut
                    title='Students Profile'
                    description='Shows the students information and their fees profiling.'
                /> */}

                <div className="row">
                    <div className="col-md-3">
                        {this.state.profileInfo && 
                            <ProfileCard 
                                profile={this.state.profileInfo}
                                methods={{
                                    terminateBilling:this.handleTerminateBilling,
                                    activateBilling:this.handleActivateBilling
                                }}
                            />}

                    </div>

                    <div className="col-md-9">
                        {this.state.info}
                        <div className="card">
                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                    <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Fees Statement</a></li>
                                    {/* <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li> */}
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content">
                                   

                                    <div className="active tab-pane" id="timeline">

                                      {this.state.studentId && this.state.profileInfo && !this.state.loading && <StudentFeeStatement data={{student_id:this.state.studentId, batch_id:this.state.batchId}}/>}
                                        {this.state.loading && <ActivityLoader/>}
                                    </div>


                                    <div className="tab-pane" id="settings">
                                        {/** Content goes here */}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>

        )
    }

}

export default StudentProfile;
