import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import AlertError from '../../../Components/Common/AlertError';
import AlertSuccess from '../../../Components/Common/AlertSuccess';
import GrantPermission from '../../../Components/Configuration/User/GrantPermission';
import PageContainer from '../../../Components/Headings/PageContainer';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import ajaxUser from '../../../remote/ajaxUser';
import { loadTables, refreshTables, removeTables } from '../../../util/cleanTables';
import functions from '../../../util/functions';
class UserPermissions extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:"",
        page:1,
        metaData:false,
        pagination:false,
        currentRecord:false,
        accountInfo:false,
        mdlCrud:false,
        userId:"",
        userDetails:false,
        loading:false
    }

    componentDidMount() {
    
        if(!this.props.match.params.user_id)
		{
			this.props.history.push('/');
		}else{
			this.setState({
				userId:this.props.match.params.user_id
			}, ()=>{
                this.listTransactions();

			})
        }


    }


    listTransactions=async()=>{
      const server_response = await ajaxUser.listUserPermissions(this.state.userId);
      if(server_response.status==="OK")
      {
        this.setState({
            transactions:server_response.details.list,
            userDetails:server_response.details.user,
            info:<AlertError type="info" message={server_response.message}/>
        })
      }else{
        this.setState({
            transactions:"404",
            info:<AlertError type="info" message={server_response.message}/>

        })
      }
    }

    componentWillUnmount()
    {
        // removeTables();
    }


   
    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }


    onClickGrant=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<GrantPermission userId={this.state.userId} refresh={this.listTransactions}/>
            }, ()=>functions.openPopup("mdl-permissions"))
        })
    }


    onClickDeny=async(permission_id)=>{
        this.setState({
            loading:true
        })
        const server_response = await ajaxUser.denyUserPermission(this.state.userId, permission_id);
        this.setState({
            loading:false
        })
        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>
            }, ()=>this.listTransactions())
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }
    }
  

  RenderTransactions=()=>{
        const transactions = this.state.transactions;
      return(
       
                    <div className="table-responsive">
                    {transactions && transactions!=="404" && <table className="table table-bordered table-striped datatable">
									
                        <thead>
                            <tr>
                            <th>No.</th>                                   
                            <th>Code</th> 
                            <th>Description</th> 
                            <th>Date Added</th> 
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((item, key)=>
                            <tr 
                            // className={`${item.id===this.state.transactionId?'flag-row':'hot-tr'}`}
                            style={{cursor:'default'}}
                            // onClick={()=>this.onSelectRecord(item.id, item)}
                            key={key}>
                                <td>{key+1}</td>
                                <td>{item.permission.code}</td>
                                <td>{item.permission.description}</td>
                                <td>{item.created_at.when}</td>
                                <td>
                                <button style={{margin:0}} className="btn btn-sm ripple btn-danger mb-1" onClick={()=>this.onClickDeny(item.permission.id)}  disabled={this.state.loading}>
                                    <i className="fa fa-times mr-1"></i>&nbsp;Deny</button>
                                </td>
                            </tr>
                        )} 
                        </tbody>
                    </table>
                    }
        
                        {!transactions && <ActivityLoader/>}
        
        
        
                    </div>
        
                )
           
  }


  RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
               
                 <li>
                    <Link to="#" className="btn ripple btn-secondary mb-1" onClick={this.onClickGrant}>
                        <i className="fa fa-plus mr-1"></i>&nbsp;Grant Permission</Link>
                </li>
                <li>
                    <Link to="/configurations/user" className="btn ripple btn-primary mb-1">
                        <i className="fa fa-user mr-1"></i>&nbsp;Users</Link>
                </li>
                <li>
                    <Link to="/configurations" className="btn ripple btn-dark mb-1">
                        <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                </li>
            </ol>
           

            
        </div>
      
    )
  }




  


    render() {
       const userDetails = this.state.userDetails;
        return (
          <PageContainer 
            title="User Permissions"
            headerNav={<this.RenderControls/>}
            >
          <div className="row">
              <div className="col-md-12">
                  {this.state.mdlCrud}
              <div className="card">
                  <div className="card-header border-transparent">
                  {userDetails &&  <h3 className="card-title">{userDetails.last_name + ' ' + userDetails.first_name} @{userDetails.username}</h3> }
                  </div>

                <div className="card-body">
                {this.state.info}

					          <this.RenderTransactions/>

                            
                </div>
              </div>

						
          </div>
          </div>
          </PageContainer>
        );
    }
}

export default UserPermissions;