import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import CallOut from '../../Components/Common/CallOut';
import PageContainer from '../../Components/Headings/PageContainer';
import AccountTimelineItem from '../../Components/Report/AccountTimelineItem';
import BalanceSheet from '../../Components/Report/BalanceSheet';
import FinancialPeriod from '../../Components/Report/FinancialPeriod';
import IncomeStatement from '../../Components/Report/IncomeStatement';
import TrialBalance from '../../Components/Report/TrialBalance';
import ajaxAsset from '../../remote/ajaxAsset';
import ajaxExpenses from '../../remote/ajaxExpenses';
import ajaxIncome from '../../remote/ajaxIncome';
import ajaxLiability from '../../remote/ajaxLiability';
import ajaxReport from '../../remote/ajaxReport';
import ajaxTag from '../../remote/ajaxTag';

class ReportDashboard extends Component {

    state={
        tagList:false,
        incomeList:false,
        expenseList:false,
        assetList:false,
        liabilityList:false,
        statement:"NO_SELECTION",
        loading:false,
        reportType:false,
        selectedPeriodView:null,
        openingDate:'',
        closingDate:'',
        tagId:''
    }

    componentDidMount() {
        this.getTags();
        this.getIncome();
        this.getExpenses();
        this.getAssets();
        this.getLiabilities();
    }

    getTags=async ()=>{
        const server_response = await ajaxTag.listTags();
        if(server_response.status==="OK")
        {
            this.setState({
                tagList:server_response.details
            })
        }else{
            this.setState({
                tagList:"404"
            })
        }
    }

    getIncome=async ()=>{
        const server_response = await ajaxIncome.listIncomeBalances();
        if(server_response.status==="OK")
        {
            this.setState({
                incomeList:server_response.details
            })
        }else{
            this.setState({
                incomeList:"404"
            })
        }
    }

    getExpenses=async ()=>{
        const server_response = await ajaxExpenses.listExpenseBalances();
        if(server_response.status==="OK")
        {
            this.setState({
                expenseList:server_response.details
            })
        }else{
            this.setState({
                expenseList:"404"
            })
        }
    }


    getAssets=async ()=>{
        const server_response = await ajaxAsset.listAssetBalances();
        if(server_response.status==="OK")
        {
            this.setState({
                assetList:server_response.details
            })
        }else{
            this.setState({
                assetList:"404"
            })
        }
    }

    getLiabilities=async ()=>{
        const server_response = await ajaxLiability.listLiabilityBalances();
        if(server_response.status==="OK")
        {
            this.setState({
                liabilityList:server_response.details
            })
        }else{
            this.setState({
                liabilityList:"404"
            })
        }
    }

    RenderIncome=()=>{
        const incomeList= this.state.incomeList;
            return  <>
                {!incomeList && <ActivityLoader/>}
           
                {incomeList && incomeList!=="404" && incomeList.map((item, key)=>
                <AccountTimelineItem key={key} account={item} iconBackground="bg-success"/>
                )}
                          
        </>
    }


    RenderExpenses=()=>{
        const expenseList = this.state.expenseList;

        return  <>
            {!expenseList && <ActivityLoader/>}
        
                         {expenseList && expenseList!=="404" && expenseList.map((item, key)=>
                          <AccountTimelineItem key={key} account={item} iconBackground="bg-purple"/>)}
                          
        
    </>
    }

    RenderAssets=()=>{
        const assetList = this.state.assetList;

        return  <>
            {!assetList && <ActivityLoader/>}
       
                         {assetList && assetList!=="404" && assetList.map((item, key)=>
                         <AccountTimelineItem key={key} account={item} iconBackground="bg-secondary"/>
                         )}
                          
    </>
    }


    RenderLiabilities=()=>{
        const liabilityList = this.state.liabilityList;

        return  <>
            {!liabilityList && <ActivityLoader/>}
        
                         {liabilityList && liabilityList!=="404" && liabilityList.map((item, key)=>
                         <AccountTimelineItem key={key} account={item} iconBackground="bg-danger"/>)}
                        
    </>
    }

    RenderTags=()=>{
        const tagList = this.state.tagList;
        if(tagList && tagList!=="404"){
        return  <div className="card">
        <div className="card-header">
            <h3 className="card-title">
                <i className="fa fa-tag txt-danger" aria-hidden="true"></i> Tags Income Reports
                    <span style={{float:'right', marginLeft:'20px'}}>{this.state.loading && <ActivityLoader inline size={25}/>} </span>
            </h3>

            <div className="card-tools">
            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
            </button>
            </div>
        </div>
        <div className="card-body p-0">
            <ul className="nav nav-pills flex-column">
            {tagList.map((item, key)=>
                <li key={key} className="nav-item">
                    <Link className="nav-link" to="#" onClick={()=>this.generateTagIncomeStatement(item.tag_id)}>
                        <i className="fa fa-file"></i> {item.tag_name}</Link>
                </li>
            )}
            </ul>
        </div>
        </div>
        
       
        }else{
            return <ActivityLoader/>
        }

    }



    generateHighIncomeStatement=async()=>{
        this.setState({
            loading:true,
            statement:false
        })
        const server_response = await ajaxReport.getHighIncomeStatement(this.state.selectedPeriodView, this.state.tagId, this.state.openingDate, this.state.closingDate);
        this.setState({
            loading:false,
            reportType:"INCOME"
        })
        if(server_response.status==="OK")
        {  this.setState({
                statement:server_response.details
            }) 
        }
    }


    generateTagIncomeStatement=async(tag_id)=>{
        this.setState({
            loading:true,
            statement:false,
            tagId:tag_id
        })
        const server_response = await ajaxReport.getHighIncomeStatement(this.state.selectedPeriodView, this.state.tagId, this.state.openingDate, this.state.closingDate);
        this.setState({
            loading:false,
            reportType:"INCOME"
        })
        if(server_response.status==="OK")
        {  this.setState({
                statement:server_response.details
            }) 
        }
    }

    generateTrialBalance=async()=>{
        this.setState({
            loading:true,
            statement:false
        })
        const server_response = await ajaxReport.getTrialBalance(this.state.selectedPeriodView, this.state.openingDate, this.state.closingDate);
        this.setState({
            loading:false,
            reportType:"TRIAL_BALANCE"

        })
        if(server_response.status==="OK")
        {  this.setState({
                statement:server_response.details
            }) 
        }
    }


    generateBalanceSheet=async()=>{
        this.setState({
            loading:true,
            statement:false
        })
        const server_response = await ajaxReport.getBalanceSheet(this.state.selectedPeriodView, this.state.openingDate, this.state.closingDate);
        this.setState({
            loading:false,
            reportType:"BALANCE_SHEET"

        })
        if(server_response.status==="OK")
        {  this.setState({
                statement:server_response.details
            }) 
        }
    }


    changeViewPeriod=(period_info)=>{
        this.setState({
            selectedPeriodView:period_info.period_id,
            openingDate:period_info.opening_date.db,
            closingDate:period_info.closing_date.db
        })
    }


    changeReportDateView=(openingDate, closingDate)=>{
        this.setState({
            openingDate,
            closingDate
        }, ()=>{
            switch(this.state.reportType){
                case "INCOME":
                    this.generateHighIncomeStatement();
                break;
                case "BALANCE_SHEET":
                    this.generateBalanceSheet();
                break;
                case "TRIAL_BALANCE":
                    this.generateTrialBalance();
                break;
                default:
                    this.generateHighIncomeStatement();
                break;
            } 
        })
    }

   
    RenderPeriodPane=()=>{
        return  <div className="tab-pane" id="settings">

        {/** Change period view */}

            <FinancialPeriod onChangePeriod={this.changeViewPeriod}/>            
      
    </div>
    }



    RenderActiveAccountsPane=()=>{
        return  <div className="active tab-pane" id="timeline">
        {/* <!-- The timeline --> */}
        <div className="timeline timeline-inverse">
          {/* <!-- timeline time label --> */}
          <div className="time-label">
            <span className="bg-success">
              Income
            </span>
          </div>
          <this.RenderIncome/>

          <div className="time-label">
            <span className="bg-purple">
              Expenses
            </span>
          </div>
          <this.RenderExpenses/>
          
          <div className="time-label">
            <span className="bg-secondary">
              Assets
            </span>
          </div>
          <this.RenderAssets/>

          <div className="time-label">
            <span className="bg-danger">
              Liabilities
            </span>
          </div>
          <this.RenderLiabilities/>
          
        </div>
        </div>
    }

    RenderReportsPane=()=>{
        return  <div className="tab-pane" id="activity">
        {this.state.statement && this.state.statement!=="NO_SELECTION" && <>

        {this.state.reportType==="INCOME" && <IncomeStatement data={this.state.statement} changeDateView={this.changeReportDateView}/> }
        {this.state.reportType==="TRIAL_BALANCE" && <TrialBalance data={this.state.statement} changeDateView={this.changeReportDateView}/> }
        {this.state.reportType==="BALANCE_SHEET" && <BalanceSheet data={this.state.statement} changeDateView={this.changeReportDateView}/> }

        </>}
        {this.state.statement==="NO_SELECTION" && <CallOut title="No report selected" description="To generate a report, click on financial report from your left hand panel."/>}
        {!this.state.statement && <ActivityLoader/>}
    </div>
    }
    

    render() {

        return (
            <PageContainer title="Reports">
                <div className="row">

                    <div className="col-md-4 overflow-auto h-300">

                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fa fa-folder txt-primary" aria-hidden="true"></i> Financial Reports
                                            <span style={{float:'right', marginLeft:'20px'}}>{this.state.loading && <ActivityLoader inline size={25}/>} </span>
                                    </h3>

                                    <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                                    </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="nav nav-pills flex-column">
                    
                                    <li  className="nav-item"><Link className="nav-link" to="#" onClick={this.generateHighIncomeStatement}><i className="fa fa-file"></i> Income Statement</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="#" onClick={this.generateBalanceSheet}><i className="fa fa-file"></i> Balance Sheet </Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="#" onClick={this.generateTrialBalance}><i className="fa fa-file"></i> Trial Balance </Link></li>

                                
                                    </ul>
                                </div>
                                </div>


                        <this.RenderTags/>

                    </div>




                    <div className="col-md-8 overflow-auto h-300">


                            <div className="card">
                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                <li className="nav-item"><a className="nav-link active" href="#timeline" data-toggle="tab">Active Accounts</a></li>
                                <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Financial Period</a></li>
                                <li className="nav-item"><a className="nav-link" href="#activity" data-toggle="tab">Report Viewer</a></li>

                                </ul>
                            </div>
                            {/* <!-- /.card-header --> */}
                            <div className="card-body">
                                <div className="tab-content">



                                            <div className="active tab-pane" id="timeline">
                                                {/* <!-- The timeline --> */}
                                                <div className="timeline timeline-inverse">
                                                {/* <!-- timeline time label --> */}
                                                <div className="time-label">
                                                    <span className="bg-success">
                                                    Income
                                                    </span>
                                                </div>
                                                <this.RenderIncome/>

                                                <div className="time-label">
                                                    <span className="bg-purple">
                                                    Expenses
                                                    </span>
                                                </div>
                                                <this.RenderExpenses/>
                                                
                                                <div className="time-label">
                                                    <span className="bg-secondary">
                                                    Assets
                                                    </span>
                                                </div>
                                                <this.RenderAssets/>

                                                <div className="time-label">
                                                    <span className="bg-danger">
                                                    Liabilities
                                                    </span>
                                                </div>
                                                <this.RenderLiabilities/>
                                                
                                                </div>
                                            </div>




                                {/* <this.RenderActiveAccountsPane/> */}
                                <this.RenderPeriodPane/>
                                <this.RenderReportsPane/>
                                {/* <!-- /.tab-pane --> */}
                                </div>
                                {/* <!-- /.tab-content --> */}
                            </div>
                                    {/* <!-- /.card-body --> */}
                            </div>
         



                    </div>












               

            </div>
            </PageContainer>

        
        );
    }
}

export default ReportDashboard;