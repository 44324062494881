import React, { Component } from 'react';
import TextInput from '../Common/TextInput';

class ReportFilter extends Component {

    state={
        openingDate:'',
        closingDate:'',
        WarnRequired:false
    }

    componentDidMount() {
        if(this.props.currentPeriodSelection)
        {
            this.setState({
                openingDate: this.props.currentPeriodSelection.opening.db,
                closingDate: this.props.currentPeriodSelection.closing.db
            })
        }
    }

    onChangeOpeningDate=(event)=>{
        this.setState({
            openingDate:event.target.value
        })
    }

    onChangeClosingDate=(event)=>{
        this.setState({
            closingDate:event.target.value
        })
    }

    onApply=(event)=>{

        event.preventDefault();
        const {openingDate, closingDate } = this.state

        if(openingDate.length>0 && closingDate.length>0)
        {
            this.props.filterSelection(openingDate, closingDate);
        }
    }


    render() {
        return (
            <div className="card">
            <div className="card-header border-transparent">
              <h3 className="card-title">{this.props.title}</h3>

              <div className="card-tools">
                <button type="button" className="btn btn-primary" data-card-widget="collapse">
                  <i className="fas fa-filter"></i> Filter</button> &nbsp;

                  {this.props.print && <button type="button" className="btn btn-secondary" onClick={this.props.print}>
                  <i className="fas fa-print"></i> Print</button> }

              </div>
            </div>
            <div className="card-body p-0" style={{display:'none'}}>
                <div className="row ml-2">
                    <form method='post' className='col-12' onSubmit={this.onApply}>
                        <div className='row'>
                      <div className="col-4">
                            <TextInput
                                          type="date"
                                          label="Date from"
                                          placeholder="Opening Date"
                                          leftIcon="fa fa-calendar"
                                          onChange={this.onChangeOpeningDate} 
                                          required
                                          wrap
                                          value={this.state.openingDate}
                                          labelStyle={{fontSize:"12px", fontWeight:"normal"}}
                                          />
                        </div>
                        <div className="col-4">
                                      <TextInput
                                          type="date"
                                          label="Date to"
                                          placeholder="Closing Date"
                                          leftIcon="fa fa-calendar"
                                          onChange={this.onChangeClosingDate} 
                                          required
                                          wrap
                                          value={this.state.closingDate}
                                          labelStyle={{fontSize:"12px", fontWeight:"normal"}}
                                          />
                        </div>

                        <div className="col-4">
                             <button type="submit" className="btn btn-success" style={{marginTop:"35px"}}>Apply</button>
                        </div>
                        </div>
                        </form>
                </div>
            </div>

            </div>

        );
    }
}

export default ReportFilter;