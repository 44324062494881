import React, { Component, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountsContext from '../../Context/AccountsContext';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopCreateFeesVote=()=>{

    const [info, setInfo] = useState("")
    const [loading, setLoading] = useState(false)
    const [voteName, setVoteName] = useState("");
    const [cashAccount, setCashAccount] = useState("");
    const [incomeAccount, setIncomeAccount] = useState("")

    const {listFeesVotes} = useContext(SchoolContext);
    const { incomeAccountsListing, accountsReceivableListing} = useContext(AccountsContext);

  
    const onChangeReeceivable=(item)=>{
            setCashAccount(item.value)
    }


    const onChangeIncome=(item)=>{
        setIncomeAccount(item.value)
    }


    const confirm=async(event)=>{
        event.preventDefault();
  
        if(voteName.length>0 && cashAccount>0 && incomeAccount>0)
        {   
            setInfo(<ActivityLoader/>)
            const server_response = await ajaxSchool.createFeesVote(voteName, incomeAccount, cashAccount);
            if(server_response.status==="OK")
            {
                listFeesVotes()
                setVoteName("")
                setInfo(<AlertSuccess message={server_response.message}/>)
                
            }else{
                setInfo(<AlertError message={server_response.message}/>)
        
            }

        }else{
           
            setInfo(<AlertError message={dictionary._completeFields}/>)
        
        }
    }





        const optionsIncome=[];
        if(Array.isArray(incomeAccountsListing))
        {
            incomeAccountsListing.map((item, key)=>
                optionsIncome.push({label:item.account_name, value:item.account_id})
            )
        }


        const optionsReceivables=[];
        if(Array.isArray(accountsReceivableListing))
        {
            accountsReceivableListing.map((item, key)=>
                optionsReceivables.push({label:item.account_name, value:item.account_id})
            )
        }


        return (
            
        <SystemModal id="mdl-fees-vote" title="New Fees Vote">
        <form method="post" className="form-horizontal" onSubmit={confirm}>
               {info}

                <TextInput
                    type="text"
                    label="Vote Name"
                    placeholder="Enter Vote Name"
                    leftIcon="fa fa-tag"
                    onChange={(e)=>setVoteName(e.target.value)}
                    required
                    value={voteName}
                    wrap
                    />
        
                <TextInput
                    type="select"
                    label="Income Account"
                    placeholder="Select income account"
                    leftIcon="fa fa-arrow-down"
                    onChange={onChangeIncome}
                    options={optionsIncome} 
                    required
                    wrap
                    />



                <TextInput
                    type="select"
                    label="Accounts Receivable"
                    placeholder="Select Accounts Receivable"
                    leftIcon="fa fa-exchange-alt"
                    onChange={onChangeReeceivable}
                    options={optionsReceivables} 
                    required
                    wrap
                    />
            </form> 
            
            <div className="modal-footer no-border">
                <button className="btn btn-success" type='submit' onClick={confirm} disabled={loading}>
                   {loading?<ActivityLoader inline size={20}/> : "Save Vote"}
                </button>
            </div>

   

   

    </SystemModal>
        );
    }


export default PopCreateFeesVote;