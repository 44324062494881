import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

class PopAssignFeesGroup extends Component {

    static contextType = SchoolContext

    state={
       groupId:"",
       loading:false,
       info:"",
       record:""
    }


   
    onChangeGroup=(item)=>{
        this.setState({
            groupId:item.value,
            record:item.item
        })
    }




    confirmAssign=async(event)=>{
        event.preventDefault();
  
        const { groupId }=this.state;
        if(groupId.length>0)
        {   
            this.setState({info:<ActivityLoader/>})
            const server_response = await ajaxSchool.updateStudentFeesGroup(this.props.studentId, groupId);
            if(server_response.status==="OK")
            {
                if(this.props.liveUpdate){
                    document.getElementById('fGroup'+this.props.studentId).innerHTML=this.state.record.group_info.group_name

                }

                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={"Select class and try again! You can click 'sync classes' to populate classes."}/>
            })
        }
    }


   


    render() {


        const feesGroup = this.context.feesGroups;
        const optionsGroupList=[];
        if(Array.isArray(feesGroup))
        {
            feesGroup.forEach((item, key)=>{
                var amount = item.structure?item.structure.total_fees.amount_c : 0;
                optionsGroupList.push({label:item.group_info.group_name + "(" + amount + ")", value:item.group_info.id, item:item})
            })
        }


        return (
            
    <SystemModal id="mdl-assign-fees-group" title="Assign Fees Group">
        <form method="post" className="form-horizontal" onSubmit={this.confirmAssign}>
               {this.state.info}
              
               <TextInput
                            type="select"
                            label="Select Group"
                            placeholder="Select student fees group"
                            leftIcon="fa fa-users"
                            onChange={this.onChangeGroup}
                            options={optionsGroupList} 
                            required
                            wrap
                            />
            </form> 
            
            <div className="modal-footer no-border">
                
                <button type="submit" className="btn btn-primary" onClick={this.confirmAssign}>Assign Group</button>
            </div>

   

   

    </SystemModal>
        );
    }
}

export default PopAssignFeesGroup;