import React, { useContext, useEffect, useState } from 'react';
import ajaxUtility from '../remote/ajaxUtility';

const SearchContext = React.createContext();

export const SearchConsumer=SearchContext.Consumer;

export const SearchProvider=(props)=> {

   const [searchResults, setSearchResults] = useState('404');
   const [query, setQuery] = useState('');

   useEffect(()=>{
    if(query.length>0) listTransactions();
   }, [query])


   const listTransactions=async()=>{
    setSearchResults(false)
    const server_response = await ajaxUtility.searchSystem(query);
    if(server_response.status==="OK")
    {
       setSearchResults(server_response.details)
    
    }else{
       setSearchResults('404')
    }
}
console.log(query)
   
        return (
           <SearchContext.Provider value={
               {
                  searchResults,
                  query,
                  setQuery
                }
               }>
               {props.children}
           </SearchContext.Provider>
        );
    
}

export default SearchContext;