import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import TableHeader from '../../Components/Common/TableHeader';
import PageContainer from '../../Components/Headings/PageContainer';
import PopBillStudent from '../../Components/SchoolFees/PopBillStudent';
import PopCustomBill from '../../Components/SchoolFees/PopCustomBill';
import SchoolBillingContext from '../../Context/SchoolBillingContext';
import ajaxSchool from '../../remote/ajaxSchool';
import functions from '../../util/functions';
import ButtonGroup from '../../Components/Common/Controls/ButtonGroup';
import WarnDelete from '../../Components/Common/WarnDelete';

class BillingConfigPage extends Component {

    static contextType = SchoolBillingContext

    state={
        recordId:false,
        record:false,
        modal:false,
        loading:false,
        queuedStudent:false,
        info:''
    }
   


    onSelectRecord=(recordId, record)=>{
        this.setState({recordId, record})
    }

    handleRefresh=()=>{
        this.context.getBillingConfig()
    }



    handleAutoBill=()=>{
        this.setState({
            modal:<PopBillStudent/>
        }, ()=>{functions.openPopup("mdl-auto-bill")})
    }




    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>

                    <li>
                        <ButtonGroup title="Options">
                           {this.state.recordId && <>
                                <Link to="#" className="dropdown-item" onClick={this.handleDeleteConfig}>
                                    <i className="fa fa-trash-alt mr-1"></i>&nbsp;Delete Config</Link>

                                 <Link to="#" className="dropdown-item" onClick={this.handleAssignGroup}>
                                    <i className="fa fa-users mr-1"></i>&nbsp;Assign Group </Link>
                                
                                </>}
                            
                            <Link to="/school/fees/billing" className="dropdown-item">
                              <i className="fa fa-money-bill mr-1"></i>&nbsp;Billing Center</Link>

                            <Link to="#" className="dropdown-item" onClick={this.handleRefresh}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>

                          
                        </ButtonGroup>

                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    




      handleDeleteConfig=()=>{
        this.setState({
            modal:false
        }, ()=>{
    
            this.setState({
                modal: <WarnDelete 
                title="Delete Billing Configuration" 
                description={"Confirm that you want to delete this billing configuration. The students willing classes will not be affected."}
                id="mdl_delete_bill_config"
                buttonType="danger"
                onConfirm={this.deleteConf}
                buttonLabel="Confirm Delete"
                />
            }, ()=>{functions.openPopup("mdl_delete_bill_config")})
    
        })
       
    }



    handleAssignGroup=()=>{
        this.setState({
            modal:false
        }, ()=>{
    
            this.setState({
                modal: <WarnDelete 
                title="Assign Fees Group" 
                description={"Confirm that you want to assign all students that match the selection this billing class. This will override currently assigned fees groups for students who match the selection."}
                id="mdl_auto_assign_bill"
                buttonType="danger"
                onConfirm={this.autoAssignFeesGroup}
                buttonLabel="Assign Students"
                />
            }, ()=>{functions.openPopup("mdl_auto_assign_bill")})
    
        })
       
    }


    deleteConf=async()=>{

        const server_reponse = await ajaxSchool.deleteBillingConf(this.state.recordId);
        this.context.getBillingConfig();
        functions.closePopup("mdl_delete_bill_config")
        if(server_reponse.status==="OK"){
            this.setState({
                info:<AlertSuccess message={server_reponse.message}/>
            }, ()=>{
                setTimeout(() => {
                        this.setState({info:''})
                }, 5000);
            })
        }else{

                this.setState({
                        info:<AlertError message={server_reponse.message}/>
                    }, ()=>{
                        setTimeout(() => {
                                this.setState({info:''})
                        }, 5000);
                    })

                }

      }



      autoAssignFeesGroup=async()=>{

        const server_reponse = await ajaxSchool.autoAssignFeesGroup(this.state.recordId);
        this.context.getBillingConfig();
        functions.closePopup("mdl_auto_assign_bill")
        if(server_reponse.status==="OK"){
            this.setState({
                info:<AlertSuccess message={server_reponse.message}/>
            }, ()=>{
                setTimeout(() => {
                        this.setState({info:''})
                }, 5000);
            })
        }else{

                this.setState({
                        info:<AlertError message={server_reponse.message}/>
                    }, ()=>{
                        setTimeout(() => {
                                this.setState({info:''})
                        }, 5000);
                    })

                }

      }


      

     


    render() {

        const { billingConfig } = this.context


        return (
            <PageContainer 
                title="Billing Configurations"
                headerNav={<this.RenderControls/>}
                >
                {this.state.modal}
                <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card ">
                            <div className="card-body map-card">

                        <TableHeader
                            title="Manage Billing"
                            subtitle="This sections will help you control how each student is assigned a billing class when auto-billing. You can also automatically assign billing classes to a group of students who match a billing configuration."
                        />

                       

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                          <div className="table-responsive table-ellipsi">
                            <div className="card-body p-0">
                                {this.state.info}
                            {Array.isArray(billingConfig) && 
                            <table className="table table-bordered text-nowrap mb-0 datatabl" id='table-billing-config'>
                            <thead>
                                <tr>
                                    <th>Class</th>
                                    <th>Residence</th>
                                    <th>Gender</th>
                                    <th>Billing Class</th>
                                    <th>Description</th>
                                </tr>
                          </thead>
                            <tbody>
                                { Array.isArray(billingConfig) && billingConfig.map((item, key)=>
                                    <tr 
                                        key={key}
                                        className={`${item.id === this.state.recordId ? 'flag-row' : 'hot-tr'}`}
                                        style={{ cursor: 'default' }}

                                        onClick={() => this.onSelectRecord(item.id, item)}
                                        id={"billconfig" + item.id}
                                        >
                                        <td>{item.class.class_name}</td>
                                        <td>{item.residence.residence_name}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.fees_group.group_name}</td>
                                        <td>{item.fees_group.description}</td>
                                    </tr>
                                )}

                            </tbody>
                            </table>}

                            {billingConfig === "404" && <AlertError message="No billing configuration was found. Please go to 'Fees Group' and assign each fees group"/>}
               
                            {!billingConfig && <ActivityLoader/>}
                        
                            {billingConfig === "404" && <AlertError message="No billing configuration was found. Please go to 'Fees Group' and assign each fees group"/>}

                        </div>
                      </div>
                   </div>
                  </div>
                 </div>
                 </div>

                </div>
            </PageContainer>

        );
    }
}

export default BillingConfigPage;
