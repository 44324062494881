import React, { Component } from 'react';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
// import SelectSearch from '../../Components/Common/SelectSearch';
import TextInput from '../../Components/Common/TextInput';
// import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
// import PageTitle from '../../Components/Headings/PageTitle';
import ajaxExpenses from '../../remote/ajaxExpenses';
import ajaxPayee from '../../remote/ajaxPayee';
import ajaxTag from '../../remote/ajaxTag';
import dictionary from '../../util/dictionary';

class PayInvoicePage extends Component {

    state={
        info:'',
        loading:false,
        expenseAccountListing:false,
        paymentAccountListing:false,
        payeeListing:false,
        tagListing:false,
        expenseAccount:'',
        paymentAccount:'',
        amount:'',
        description:'',
        transactionRef:'',
        transactionDate:'',
        payee:'',
        tag:'0',
        popup:false,
        invoiceRecord:false
    }

    componentDidMount() {


        if(!this.props.match.params.invoiceId)
        {
            this.props.history.push('/expenses/invoicing');
        }else{
            this.setState({
                invoiceRecord:this.props.location.state.record
            }, ()=>{
                console.log(this.state.invoiceRecord)
            })
        }




        this.getExpenseAccounts();
        this.getPaymentAccounts();
        this.getPayees();
        this.getTags();
    
    }


   


    onClickNewVendor=()=>{
        
        window.open('/vendor/new', '_blank').focus();

    }

    onClickNewTag=()=>{
        
        window.open('/tags/new', '_blank').focus();

    }



    onChangeExpenseAccount=(item)=>{
        this.setState({
            expenseAccount:item.value
        })
    }

    onChangePaymentAccount=(item)=>{
        this.setState({
            paymentAccount:item.value
        })
    }

    onChangePayee=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewVendor();
        }else{
            this.setState({
                payee:item.value
            })
        }
    }

    onChangeTag=(item)=>{
        if(item.value==="add")
        {
            this.onClickNewTag();
        }else{
            this.setState({
                tag:item.value
            })
        }
    }

    onChangeAmount=(event)=>{
        this.setState({amount:event.target.value})
    }
    onChangeDescription=(event)=>{
        this.setState({description:event.target.value})
    }
    onChangeReference=(event)=>{
        this.setState({transactionRef:event.target.value})
    }
    onChangeDate=(event)=>{
        this.setState({transactionDate:event.target.value})
    }


    getExpenseAccounts=()=>{
        this.setState({
            expenseAccountListing:false
        }, async()=>{
            const server_response = await ajaxExpenses.listExpenseAccounts();
            if(server_response.status==="OK")
            {
                this.setState({ expenseAccountListing:server_response.details })
            }else{
                this.setState({
                    expenseAccountListing:"404"
                })
            }
        })
      
    }

    getPaymentAccounts=()=>{
        this.setState({
            paymentAccountListing:false
        }, async()=>{
            const server_response = await ajaxExpenses.listPaymentAccounts();
            if(server_response.status==="OK")
            {
                this.setState({ paymentAccountListing:server_response.details })
            }else{
                this.setState({
                    paymentAccountListing:"404"
                })
            }
        })
      
    }


    getPayees=()=>{
        this.setState({
            payeeListing:false
        }, async()=>{
            const server_response = await ajaxPayee.listPayees();
            if(server_response.status==="OK")
            {
                this.setState({ payeeListing:server_response.details })
            }else{
                this.setState({
                    payeeListing:"404"
                })
            }
        })
      
    }


    getTags=()=>{
        this.setState({
            tagListing:false
        }, async()=>{
            const server_response = await ajaxTag.listTags();
            if(server_response.status==="OK")
            {
                this.setState({ tagListing:server_response.details })
            }else{
                this.setState({
                    tagListing:"404"
                })
            }
        })
      
    }

    onConfirmExpense=async(event)=>{
        event.preventDefault();

        const {amount, expenseAccount, paymentAccount, description, transactionDate, transactionRef, payee, tag}=this.state;
        if(amount<=0 || transactionDate.length<=0 || description.length<=0 || transactionRef.length<=0 )
        {
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })

            return false;
        }

        if(paymentAccount.length<1)
        {
            this.setState({
                info:<AlertError message="Select payment track account and try again"/>
            })

            return false;
        }

        

        this.setState({
            loading:true
        })
        const server_response = await ajaxExpenses.PayInvoice({
            "invoice_id":this.state.invoiceRecord.id,
            "payment_date":transactionDate,
            "amount":amount,
            "description":description,
            "cash_account":paymentAccount,
            "payment_ref": transactionRef
          }
        );
        this.setState({
            loading:false
        })

        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>,
                amount:'',
                description:'',
                transactionRef:''
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }

    }



    RenderExpenseAccount=()=>{
        const expenseAccountListing = this.state.expenseAccountListing;
        const optionsExpenseAccountListing=[];
        if(expenseAccountListing && expenseAccountListing!=="404")
        {
            expenseAccountListing.map((item, key)=>
            optionsExpenseAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return <TextInput
                    label="Expense Category"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select expense account...." 
                    options={optionsExpenseAccountListing}
                    onChange={this.onChangeExpenseAccount}
                    leftComponent={<i className="fa fa-university" onClick={this.getExpenseAccounts}></i>}
                    loading={!expenseAccountListing?true:false}
                    />
        
        }

   

    RenderPaymentAccount=()=>{
        const paymentAccountListing = this.state.paymentAccountListing;
        const optionsPaymentAccountListing=[];
        if(paymentAccountListing && paymentAccountListing!=="404")
        {
            paymentAccountListing.map((item, key)=>
                optionsPaymentAccountListing.push({label:item.account_number + ' ' + item.account_name, value:item.account_id})
            )
        }
        
        return   <TextInput
                    label="Payment Track Account"
                    type="select" 
                    leftIcon="fa fa-university"
                    placeholder="Select payment track account...." 
                    options={optionsPaymentAccountListing}
                    onChange={this.onChangePaymentAccount}
                    leftComponent={<i className="fa fa-university" onClick={this.getPaymentAccounts}></i>}
                    loading={!paymentAccountListing?true:false}
                    />
        
        }

    RenderPayee=()=>{
        const payeeListing = this.state.payeeListing;
        const optionsPayeeListing=[{label:"Add new  Payee", value:"add"}];
        if(payeeListing && payeeListing!=="404")
        {
            payeeListing.map((item, key)=>
                optionsPayeeListing.push({label:item.title, value:item.payee_id})
            )
        }
        
        return   <TextInput
                        label="Vendor/Supplier"
                        type="select" 
                        leftIcon="fa fa-truck"
                        placeholder="Select vendor e.g supplier, UMEME etc...." 
                        options={optionsPayeeListing}
                        onChange={this.onChangePayee}
                        leftComponent={<i className="fa fa-truck" onClick={this.getPayees}></i>}
                        loading={!payeeListing?true:false}
                        />
        
        }



        RenderTag=()=>{
            const tagListing = this.state.tagListing;
            const optionsTagListing=[{label:"Create new tag", value:"add"}, {label:"- No tag -", value:"0"}];
            if(tagListing && tagListing!=="404")
            {
                tagListing.map((item, key)=>
                    optionsTagListing.push({label:item.tag_name, value:item.tag_id})
                )
            }
            
            return  <TextInput
                        label="Tag Transaction"
                        type="select" 
                        leftIcon="fa fa-tag"
                        placeholder="Transaction is not tagged..." 
                        options={optionsTagListing}
                        onChange={this.onChangeTag}
                        leftComponent={<i className="fa fa-tag" onClick={this.getTags}></i>}
                        loading={!tagListing?true:false}
                        />
            
            }
    
   
    render() {

        const record = this.state.invoiceRecord

        return (
            <PageContainer title="Pay Invoice">
                <div className="row">
                    {record && <div className="col-md-12">
                        <CallOut 
                            title={record.vendor.title} 
                            description={"Reference: " + record.invoice_ref + " Invoice Date: " + record.invoice_date.short_date + " Description: "+ record.description + " amount: " + record.amount.amount_c}/>
                       
                        <div className="card">
            {/* <div className="card-header"></div> */}

            <div className="card-body">
           
                        <form class="form-horizontal" method="post" onSubmit={this.onConfirmExpense}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}


                         

                            <TextInput
                                type="date"
                                label="Payment Date"
                                placeholder="Payment Date"
                                leftIcon="fa fa-calendar"
                                value={this.state.transactionDate}
                                onChange={this.onChangeDate} 
                                required
                            />

                            
                             <TextInput
                                type="text"
                                label="Payment Ref"
                                placeholder="Payment Reference/Voucher No./Cheque No"
                                leftIcon="fa fa-hashtag"
                                value={this.state.transactionRef}
                                onChange={this.onChangeReference} 
                                required
                            />



                        <TextInput
                            type="text"
                            label="Amount Paid"
                            placeholder="Amount Paid"
                            leftIcon="fa fa-credit-card"
                            value={this.state.amount}
                            onChange={this.onChangeAmount} 
                            required
                            />
                            <TextInput
                                type="textarea"
                                label="Payment Description"
                                placeholder="Payment Description"
                                leftIcon="fa fa-list"
                                value={this.state.description}
                                onChange={this.onChangeDescription} 
                                required
                            />




                            <div className="row">&nbsp;</div>                           
                           



                           
                            <this.RenderPaymentAccount/>


                            

                            {/* <this.RenderTag/> */}
        
                           
        

       
        

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                               {!this.state.loading && <button type="submit" className="btn btn-primary">Save Payment</button>}
                            </div>

                        </form> 

   

                    </div>
                    </div>

                    </div>}{/** body end */}

                    {!record && <ActivityLoader/>}

                </div>

        </PageContainer>
        
        );
    }
}

export default PayInvoicePage;