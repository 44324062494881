import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class InfoWidget extends Component {
    render() {
        return (
            <Link to={this.props.href || "#"} className="col-md-3 col-sm-6 col-12">
            <div className={`info-box bg-gradient-${this.props.type || 'info'}`}>
              {this.props.icon}

              <div className="info-box-content">
                <span className="info-box-text">{this.props.title}</span>
                <span className="info-box-number">{this.props.value}</span>

                <div className="progress">
                  <div className="progress-bar" style={{width: "70%"}}></div>
                </div>
                <span className="progress-description">
                  {this.props.description}
                </span>
              </div>
            </div>
          </Link>
        );
    }
}

export default InfoWidget;