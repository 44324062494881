import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import AlertError from '../../../Components/Common/AlertError';
import PageNav from '../../../Components/Common/PageNav';
import EditSignatory from '../../../Components/Configuration/Signatory/EditSignatory';
import NewSignatory from '../../../Components/Configuration/Signatory/NewSignatory';
import PageContainer from '../../../Components/Headings/PageContainer';
import ajaxConfig from '../../../remote/ajaxConfig';
import functions from '../../../util/functions';

class ListSignatory extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:"",
        page:1,
        metaData:false,
        pagination:false,
        currentRecord:false,
        accountInfo:false,
        mdlCrud:false
    }

    componentDidMount() {

        this.listTransactions();
    }

    listTransactions=async()=>{
        const server_response = await ajaxConfig.filter_signatories(this.state.page);
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details.list,
                metaData:server_response.details.meta
            }, ()=>this.paginate())
        }else{
            this.setState({
                transactions:"404",
                info:<AlertError type="info" message={server_response.message}/>
            })
        }
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }



 //==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


  onChangeStatus=async(status, id)=>{

    const newStatus = (status>0?0:1);

    const server_response = await ajaxConfig.update_signatory_status(id, newStatus);
    if(server_response.status==="OK")
    {
        this.listTransactions();
    }
        
  }


  RenderTransactions=()=>{
        const transactions = this.state.transactions;
      return(
        <>


            <div className="table table-responsive table-ellipsis">
            {transactions && transactions!=="404" && <table className="table table-bordere text-nowrap mb-0">
                <thead>
                    <tr>
                    <th>No.</th>                                   
                    <th>Name</th> 
                    <th>Position</th> 
                    <th>Created by</th>
                    <th>Last updated</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map((item, key)=>
                    <tr 
                    className={`${item.id===this.state.transactionId?'flag-row':'hot-tr'}`}
                    style={{cursor:'default'}}
                    onClick={()=>this.onSelectRecord(item.id, item)}
                    key={key}>
                        <td>{this.state.metaData.offset_count+key+1}</td>
                        <td>{item.last_name + ' ' + item.first_name}</td>
                        <td>{item.designation}</td>
                        <td>{item.created_by.username}</td>
                        <td>{item.updated_at.short_date + ' ' + item.updated_at.time}</td>
                        <td>
                            <label className="switch" onClick={()=>this.onChangeStatus(item.status, item.id)}>
                                <input type="checkbox" checked={item.status*1}/>
                                    <span className="slider">
                                        <span className='switch-text'>ON</span>
                                    </span>
                                </label>

                        </td>
                    </tr>
                )} 
                </tbody>
            </table>}

                {!transactions && <ActivityLoader/>}
                {this.state.pagination}



            </div>


    
    </>

      )
  }


  RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
                {this.state.currentRecord && <li>
                    <Link to="#" className="btn ripple btn-warning mb-1" onClick={this.editSignatory}>
                        <i className="fa fa-pen mr-1"></i>&nbsp;Edit Signatory</Link>
                </li>}
                <li>
                    <Link to="#" className="btn ripple btn-success mb-1" onClick={this.newSignatory}>
                    <i className="fa fa-edit mr-1"></i>&nbsp;Create New</Link>
                </li>
                <li>
                    <Link to="/configurations" className="btn ripple btn-dark mb-1">
                        <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                </li>
            </ol>
           

            
        </div>
      
    )
  }



  newSignatory=()=>{
      this.setState({
          mdlCrud:false
      }, ()=>{
          this.setState({
              mdlCrud:<NewSignatory refresh={this.listTransactions}/>
          }, ()=>functions.openPopup("mdl-new-signatory"))
      })
  }

  editSignatory=()=>{
    this.setState({
        mdlCrud:false
    }, ()=>{
        this.setState({
            mdlCrud:<EditSignatory refresh={this.listTransactions} record={this.state.currentRecord}/>
        }, ()=>functions.openPopup("mdl-edit-signatory"))
    })
}

    render() {
       

        return (
          <PageContainer 
            title="Report Signatories"
            headerNav={<this.RenderControls/>}
            >
          <div className="row">
              <div className="col-md-12">
                  {this.state.info}
                  {this.state.mdlCrud}
              <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Report Signatories</h3>
                  </div>

                <div className="card-body">
					          <this.RenderTransactions/>
                </div>
              </div>

						
          </div>
          </div>
          </PageContainer>
        );
    }
}

export default ListSignatory;