import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import AlertError from '../../../Components/Common/AlertError';
import PageContainer from '../../../Components/Headings/PageContainer';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import { beautifyTable } from '../../../util/script/customTables';
class ListPermissions extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:"",
        page:1,
        metaData:false,
        pagination:false,
        currentRecord:false,
        accountInfo:false,
        mdlCrud:false,
        initialize:true

    }

    componentDidMount() {
    
        var tableElement = document.getElementsByClassName("datatable");
        if(tableElement.length>0)
        {
            beautifyTable(tableElement[0].getAttribute('id'), true, false)
            // console.log(tableElement[0].getAttribute('id'))
        }
    }

   
    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }


    initializeTable=(func)=>{
        if(this.state.initialize)
        {
            this.setState({
                initialize:false
            }, ()=>{
                func([])
            })
          

        }
    }

  RenderTransactions=()=>{
        // const transactions = this.state.transactions;
      return(
        <ConfigConsumer tableOptions={{exportFormat:"rrrr"}}>
            {props=>{
                const transactions = props.permissionListing;
                this.initializeTable(props.exportTable)
                return(
                    <div className="table-responsive">
                    {transactions && transactions!=="404" && 
                    <>
                        <table id={props.tableId} className="table table-bordered table-striped datatable">
									
                        <thead>
                            <tr>
                            <th>No.</th>                                   
                            <th>Code</th> 
                            <th>Description</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((item, key)=>
                            <tr 
                            // className={`${item.id===this.state.transactionId?'flag-row':'hot-tr'}`}
                            style={{cursor:'default'}}
                            // onClick={()=>this.onSelectRecord(item.id, item)}
                            key={key}>
                                <td>{key+1}</td>
                                <td>{item.code}</td>
                                <td>{item.description}</td>
    
                            </tr>
                        )} 
                        </tbody>
                    </table>
                    </>
                    }
        
                        {!transactions && <ActivityLoader/>}
        
        
        
                    </div>
        
                )
            }}
           

    
        </ConfigConsumer>


      )
  }


  RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
               
                <li>
                    <Link to="/configurations" className="btn ripple btn-dark mb-1">
                        <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                </li>
            </ol>
           

            
        </div>
      
    )
  }



    render() {
       
        return (
          <PageContainer 
            title="Permissions"
            headerNav={<this.RenderControls/>}
            >
          <div className="row">
              <div className="col-md-12">
                  {this.state.info}
                  {this.state.mdlCrud}
              <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">System Permissions</h3>
                  </div>

                <div className="card-body">
					          <this.RenderTransactions/>

                            
                </div>
              </div>

						
          </div>
          </div>
          </PageContainer>
        );
    }
}

export default ListPermissions;