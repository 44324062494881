/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";
import apiCall from "./apiCall";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   
    async loginUser(username, password)
    {

        let data={
            "username":username,
            "password":password
        }
        try{
            let response= await fetch(apiHost + 'user/auth/login',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async count_users()
    {

        let data={
            requester:"admin"
        }
        try{
            let response= await fetch(apiHost + 'user/count/all',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async filter_users(page)
    {

        let data={
            page:page
        }
        try{
            let response= await fetch(apiHost + 'user/list/all',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async createUser(username, firstName, lastName, email, phone, role) {
      let data = {
        'username':username, 
        'first_name':firstName, 
        'last_name':lastName, 
        'email_address':email, 
        'phone_number':phone, 
        'role_id':role
      }
      try {
        let response = await fetch(apiHost + 'user/create',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },

    async updateUser(userId, username, firstName, lastName, email, phone, role) {
        let data = {
          'username':username, 
          'first_name':firstName, 
          'last_name':lastName, 
          'email_address':email, 
          'phone_number':phone, 
          'role_id':role,
          'user_id':userId
        }
        try {
          let response = await fetch(apiHost + 'user/update',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

    async resetPassword(password, rePassword) {
        let data = {
          password: password,
          re_password:rePassword
        }
        try {
          let response = await fetch(apiHost + 'user/password/reset',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async countAllPermissions() {
        try {
          let response = await fetch(apiHost + 'config/permission/count',
            {
              method: 'GET',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              }
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },
      async listAllPermissions() {
        try {
          let response = await fetch(apiHost + 'config/permission/filter',
            {
              method: 'GET',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              }
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async listUserPermissions(userId) {
        let data = {
          user_id: userId
        }
        try {
          let response = await fetch(apiHost + 'user/permission/filter',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async grantUserPermission(userId, permissionId) {
        let data = {
          user_id: userId,
          permission_id:permissionId
        }
        try {
          let response = await fetch(apiHost + 'user/permission/grant',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async denyUserPermission(userId, permissionId) {
        let data = {
          user_id: userId,
          permission_id:permissionId
        }
        try {
          let response = await fetch(apiHost + 'user/permission/deny',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async getUserPermissionCodes(userId) {
        let data = {
          user_id: userId
        }
        try {
          let response = await fetch(apiHost + 'user/permission/codes',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },



      async captiveLogin(username, password) {
        let data = {
          username: username,
          password: password
        }
        
        try {
          let response = await fetch(dictionary.captiveHost + "login/captive",
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },








} 