import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ajaxLedger from '../../remote/ajaxLedger';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import SilentText from '../Common/SilentText';

class RecentLedgers extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:""
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions=async()=>{
        const server_response = await ajaxLedger.listRecentTransactions();
        if(server_response.status==="OK")
        {
            this.setState({transactions:server_response.details})
        }else{
            this.setState({
                transactions:"404",
                info:<AlertError type="light" message={server_response.message}/>
            })
        }
    }

    onSelectRecord=(transactionId)=>{
        this.setState({transactionId})
    }

    render() {
        const transactions = this.state.transactions;
        return (

            <div className="card">
            <div className="card-header border-transparent">
              <h3 className="card-title">Recent transactions</h3>

              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
                {/* <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times"></i>
                </button> */}
              </div>
            </div>

            <div className="card-body p-0">

                <div className="table table-responsive table-ellipsis">
                {!transactions && <ActivityLoader/>}
                {transactions && transactions!=="404" && <table className="table table-bordered table-striped">
                  <thead>
                        <tr>
                          <th>Trans Date</th>                                   
                          <th>Payee</th>
                          <th>Amount</th>
                          <th>Description</th>
                          <th>Account</th>
                      </tr>
                  </thead>
                  <tbody>

                       {transactions.map((item, key)=>

                        <tr 
                        //  className={`${item.transaction_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                         style={{cursor:'default'}}
                         key={key}
                         onClick={()=>this.onSelectRecord(item.transaction_id)}
                         >
                        <td>{item.transaction_date.short_date}
                            <br/><SilentText text={"Record date: "+item.created_at.short_date }/>
                        </td>

                        <td>{item.payee?item.payee.title:""}</td>
                        <td>{item.amount.amount_c}
                            {item.transaction_ref && <><br/><SilentText text={"Ref: " + item.transaction_ref}/></>}
                        </td>
                        <td>
                            <SilentText text={item.description}/>
                        </td>
                        <td>
                            <i className='fa fa-university' style={{fontSize:'12px'}}/> {item.account.account_name}
                            <br/><i className='fa fa-university' style={{fontSize:'12px'}}/> <SilentText text={item.ledger_grouping?item.ledger_grouping.account.account_name:""}/>
                        </td>
            
                    </tr>
                   
                    )}

                  </tbody>

                    </table>}
                    {transactions==="404" && this.state.info}
                </div>
            </div>

            <div className="card-footer clearfix">
                <Link to="expenses/new" className="btn btn-sm btn-info float-left">Enter New Expense</Link>
                <Link to="/ledgers" className="btn btn-sm btn-secondary float-right">View All Ledgers</Link>
              </div>
        </div>

            
          
        );
    }
}

export default RecentLedgers;