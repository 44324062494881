import React, { useEffect, useState } from 'react';
import ajaxSchool from '../remote/ajaxSchool';

const SchoolDefaulterContext = React.createContext();

export const SchoolDefaulterConsumer = SchoolDefaulterContext.Consumer;

export const SchoolDefaulterProvider = (props) => {

    const [transactionListing, setTransactionListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);



    useEffect(()=>{
       getSchoolDefaulterTransactions();
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getSchoolDefaulterTransactions=async()=>{
        setLoading(true)
        setTransactionListing(false)
        const server_response = await ajaxSchool.fetchFeesDefaulters(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setTransactionListing(server_response.details.list);
            setMetaData(server_response.details.meta);
        }else{
            setTransactionListing("404");
            setMetaData(false);
        }
    }

  


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);

        const server_response = await ajaxSchool.fetchFeesDefaulters(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setTransactionListing(transactionListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "student_class":options.studentClass,
            "balance_filter":options.amountCriteria,
            "student_residence":options.studentResidence,
            "page":"1"
        })
        
    }



  
    return (
        <SchoolDefaulterContext.Provider value={{
            transactionListing,
            metaData,
            loading,
            goToNextPage,
            setData,
            resetData,
            filterData,
            getSchoolDefaulterTransactions
        }}
        >
            {props.children}
        </SchoolDefaulterContext.Provider>
    );
}


export default SchoolDefaulterContext;
