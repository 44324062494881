import React, { Component } from 'react'
// import Chart from 'react-apexcharts'
import ActivityLoader from '../Common/ActivityLoader'
import CardTitle from '../Common/CardTitle'
import AccountTimelineItem from '../Report/AccountTimelineItem'

class Basaries extends Component {
  state = {
    tagList: false,
    incomeList: false,
    expenseList: false,
    assetList: false,
    liabilityList: false,
    statement: 'NO_SELECTION',
    loading: false,
    reportType: false,
    selectedPeriodView: null,
    openingDate: '',
    closingDate: '',
    tagId: ''
  }

  RenderIncome = () => {
    const incomeList = this.state.incomeList
    return (
      <>
        {!incomeList && <ActivityLoader />}

        {incomeList &&
          incomeList !== '404' &&
          incomeList.map((item, key) => (
            <AccountTimelineItem
              key={key}
              account={item}
              iconBackground='bg-success'
            />
          ))}
      </>
    )
  }

  render() {
    return (
      <>
        <div className="card">
          <CardTitle
            title="Recent Fees group"
            subtitle="Recently added fees groups."
          />

          <div className="card-body p-0">
            <ul className="products-list product-list-in-card pl-2 pr-2">
              <li className="item">
                {/* <div className="product-img">
                  <img src="dist/img/default-150x150.png" alt="Product Image" className="img-size-50" />
                </div> */}
                <div className="product-info">
                  <a href="javascript:void(0)" className="product-title">Half Basaries
                    <span className="badge badge-warning float-right mr-3">$1800</span>
                  </a>
                  <span className="product-description">
                    Groups whose half fees are catered for.
                  </span>
                </div>
              </li>
              <li className="item">
                {/* <div className="product-img">
                  <img src="dist/img/default-150x150.png" alt="Product Image" className="img-size-50" />
                </div> */}
                <div className="product-info">
                  <a href="javascript:void(0)" className="product-title">Gender group
                    <span className="badge badge-primary float-right mr-3">$700</span>
                  </a>
                  <span className="product-description">
                    Groups whose percentage is catered for.
                  </span>
                </div>
              </li>
              <li className="item">
                {/* <div className="product-img">
                  <img src="dist/img/default-150x150.png" alt="Product Image" className="img-size-50" />
                </div> */}
                <div className="product-info">
                  <a href="javascript:void(0)" className="product-title">Top Students <span className="badge badge-danger float-right mr-3">
                    $350
                  </span>
                  </a>
                  <span className="product-description">
                    Group of top performing students.
                  </span>
                </div>
              </li>
              <li className="item">
                {/* <div className="product-img">
                  <img src="dist/img/default-150x150.png" alt="Product Image" className="img-size-50" />
                </div> */}
                <div className="product-info">
                  <a href="javascript:void(0)" className="product-title">Recess Term
                    <span className="badge badge-success float-right mr-3">$399</span>
                  </a>
                  <span className="product-description">
                    Group that has classes during holidays
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="card-footer text-center">
            <a href="/school/fees/fees-group" className="uppercase">View All Fees Groups</a>
          </div>
        </div>
        {/* <div className='card custom-card overflow-hidden'>
          <div className='card-body'>
            <div className='card-option d-flex'>
              <div>
                <h6 className='card-title mb-1'>Overview of Student Basaries</h6>
                <p className='text-muted card-sub-title'>
                  Defaulted over collected fees.
                </p>
              </div>
              <div className='card-option-title ml-auto'>
                <div className='btn-group p-0'>
                  <button className='btn btn-light btn-sm' type='button'>
                    This Term
                  </button>
                  <button className="btn btn-outline-light btn-sm" type="button">This Year</button>
                </div>
              </div>
            </div>
            <div>
              <div className='timeline timeline-inverse'>
                <div className='time-label'>
                  <span className='bg-success'>Income</span>
                </div>
                <this.RenderIncome />

                <div className='time-label'>
                  <span className='bg-purple'>Expenses</span>
                </div>
                <this.RenderIncome />
              </div>
            </div>
          </div>
        </div> */}
      </>
    )
  }
}

export default Basaries
