/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   

    async addBank(bankName, accountNumber, phoneNumber, email)
    {

        let data={
            "bank_name":bankName,
            "account_number":accountNumber,
            "phone_number":phoneNumber,
            "email":email
        }
        try{
            let response= await fetch(apiHost + 'bank/add',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },
    async listBanks()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'bank/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


    

} 