import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MainMenu extends Component {
    render() {
        return (
            <div className="col-12">
            <div className="wrap">
                <div className="bg-effect">
                    <h2>Menu</h2>
                    <ul className="tile-bt-list">
                    
                            <li><Link to="/dashboard" className="hvr-icon-grow-rotate menu-dashboard-icon col-1 bg-blue">
                                <span className="btn-text">Dashboard</span>
                            </Link></li>
                            <li><Link to="/vendor/list" className="hvr-icon-grow menu-vendor-icon col-1 bg-green">
                            <span className="btn-text">Vendors</span>
                            </Link></li>
                            <li><a href="#" className="hvr-icon-grow-rotate menu-bank-icon col-1 bg-yellow">
                            <span className="btn-text">Banking</span></a></li>

                            <li><Link to="/income/new" className="hvr-icon-grow-rotate menu-income-icon col-1 bg-green">
                            <span className="btn-text">Income</span></Link></li>

                            <li><Link to="/expenses/new" className="hvr-icon-grow-rotate menu-expense-icon col-1 bg-red">
                            <span className="btn-text">Expenses</span></Link></li>
                            <li><a href="#" className="hvr-icon-grow-rotate menu-asset-icon col-1 bg-blue">
                            <span className="btn-text">Assets</span></a></li>


                            <li><a href="#" className="hvr-icon-grow-rotate menu-equity-icon col-1 bg-yellow">
                                <span className="btn-text">Equity & Liabilities</span></a></li>
                            <li><a href="#" className="hvr-icon-grow-rotate menu-payroll-icon col-1 bg-green">
                            <span className="btn-text">Payroll</span></a></li>
                            <li><Link to="/accounts/chart" className="hvr-icon-grow-rotate menu-account-chart-icon col-1 bg-red">
                            <span className="btn-text">Chart Of Accounts</span></Link></li>

                            <li><Link to="/reports" className="hvr-icon-float-away menu-reports-icon col-1 bg-blue">
                            <span className="btn-text">Reports</span></Link></li>
                            <li><Link to="/config/users" className="hvr-icon-pop menu-user-management-icon col-1 bg-yellow">
                            <span className="btn-text">User Management</span></Link></li>

                            <li><Link to="/tags/list" className="hvr-icon-grow-rotate menu-tag-icon col-1 bg-blue">
                                <span className="btn-text">Tags</span>
                            </Link></li>
                        
                    </ul>
                </div>
            </div>
        </div>
    
        );
    }
}

export default MainMenu;