import React, { Component, useContext } from 'react'
import { Link } from 'react-router-dom'
import SchoolContext from '../../Context/SchoolContext'
// import Chart from 'react-apexcharts'
import ActivityLoader from '../Common/ActivityLoader'
import CardTitle from '../Common/CardTitle'

const ClassBreakDown =()=> {
 
    const {classBreakDown} = useContext(SchoolContext);

    return (
      <>
        <div className="card">
          <CardTitle
            title="Class Billing Summary"
            subtitle="Billing breakdown per class this term."
          />

          <div className="card-body p-0 overflow-auto" style={{height:"325px"}}>
            {Array.isArray(classBreakDown) && <ul className="products-list product-list-in-card pl-2 pr-2">
              {classBreakDown.map((item, key)=><li className="item" key={key}>
               
                <div className="product-info">
                  <Link to="#" className="product-title">{item.class_info.class_name} Class
                    <span className="badge badge-success float-right mr-3">UGX. {item.amount.amount_p}</span>
                  </Link>
                  <span className="product-description">
                    Expected amount from {item.class_info.class_name}
                  </span>
                </div>
              </li>)}
              
            </ul>}
            {!classBreakDown && <ActivityLoader/>}
          </div>
        
        </div>
       
      </>
    )
  
}

export default ClassBreakDown
