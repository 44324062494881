import React, { Component, useContext, useState } from 'react';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopEditFeesBatch=(props)=>{

    const [info, setInfo] = useState("")
    const [loading, setLoading] = useState(false)
    const [batchName, setBatchName] = useState(props.batchInfo.batch_name);
    const [starts, setStarts] = useState(props.batchInfo.start_date.db);
    const [ends, setEnds] = useState(props.batchInfo.end_date.db)

    const {listBillingBatches} = useContext(SchoolContext);



    const confirm = async(event)=>{
        event.preventDefault();

        if(batchName.length>0 && starts.length>0 && ends.length>0)
        {
            setLoading(true)
            const server_response = await ajaxSchool.updateBatch(props.batchInfo.id, batchName, starts, ends);
            setLoading(false)

            if(server_response.status==="OK")
            {
                setInfo(<AlertSuccess message={server_response.message}/>)
                listBillingBatches()
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }
        }else{
            setInfo(<AlertError message={dictionary._completeFields}/>)
        }
    }


   


    return (<SystemModal id="mdl-edit-batch" title="Update Billing Batch">
        <form method="post" className="form-horizontal" onSubmit={confirm}>
               {info}


               <TextInput
                      type='text'
                      label='Batch Title'
                      placeholder='Batch reference title'
                      leftIcon='fa fa-tag'
                      value={batchName}
                      onChange={(e)=>setBatchName(e.target.value)}
                      required
                    />

                    <div className='row'>&nbsp;</div>

                    <TextInput
                      type='date'
                      label='Batch Starts'
                      placeholder='Start Date'
                      leftIcon='fa fa-calendar'
                      value={starts}
                      onChange={(e)=>setStarts(e.target.value)}
                    />

                    <div className='row'>&nbsp;</div>

                    <TextInput
                      type='date'
                      label='Batch Ends'
                      placeholder='End Date'
                      leftIcon='fa fa-calendar'
                      value={ends}
                      onChange={(e)=>setEnds(e.target.value)}
                    />


                
        </form> 
            
        <div className="modal-footer no-border">
            <button className="btn btn-success" type='submit' onClick={confirm} disabled={loading}>
                {loading?<ActivityLoader inline size={20}/> : "Save Changes"}
            </button>
        </div>

   

   

    </SystemModal>
        );
    }


export default PopEditFeesBatch;