import React, { Component } from 'react';
import PageTitle from '../../Components/Headings/PageTitle';

class NotFoundPage extends Component {
    render() {
        return (
			<div id="page-wrapper">
            <div className="main-page">
                 <PageTitle title="Not found"/>
           			<div className="error-page">
					<div className="">
						<h2>404</h2>
					</div>
					<div className="">
					<h3><i className="fa fa-warning text-yellow"></i> Oops! Page Not Found.</h3>
						
					</div>
					<p>We could not find the page you were looking for. Please verify that the page exists in the system</p>
				</div>
			</div>
			</div>
        );
    }
}

export default NotFoundPage;