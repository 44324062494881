import React, { Component } from 'react';
import LeftSideBar from '../Components/Common/LeftSideBar';
import TopBar from '../Components/Common/TopBar';
import ContentHeader from '../Components/Headings/ContentHeader';
import AccountsPayables from '../Components/Home/AccountsPayables';
import AccountTypeSummary from '../Components/Home/AccountTypeSummary';
import AssetBalances from '../Components/Home/AssetBalances';
import DataOptionStatistics from '../Components/Home/DataOptionStatistics';
import RecentLedgers from '../Components/Home/RecentLedgers';

class DashboardPage extends Component {
    render() {
        return (
            <>
            <TopBar/>
            <LeftSideBar/>
            <div className="content-wrapper">
                <ContentHeader title="Dashboard"/>
                <section className="content">
                    <div className="container-fluid">

                <AccountTypeSummary/>

                <div className="row">


                    <div className="col-md-4">
                        <AssetBalances/>
                    </div>
                   
                    <div className="col-md-4">
                            <AccountsPayables/>
                    </div>

                    <div className="col-md-4">
                        <DataOptionStatistics/>
                    </div>


                    <div className="col-md-12">
                        <RecentLedgers/>
                    </div>
                   
                </div>


            </div>
            </section>
        </div>

         </>
        );
    }
}

export default DashboardPage;