import React, { useContext, useState } from 'react';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';

const NewFeesGroup=(props)=>{

const [info, setInfo] = useState("");
const [loading, setLoading] = useState(false)
const [groupName, setGroupName] = useState("");
const [description, setDescription] = useState("");

const {listFeesGroups} = useContext(SchoolContext);


const onSave=async(event)=>{
    event.preventDefault();

    if(groupName.length>0 && description.length>0)
    {
        setLoading(true)
        const server_response = await ajaxSchool.createFeesGroup(groupName, description);
        setLoading(false)

        if(server_response.status==="OK")
        {
            setInfo(<AlertSuccess message={server_response.message}/>)
            setGroupName("")
            setDescription("")
            listFeesGroups()
        }else{
            setInfo(<AlertError message={server_response.message}/>)
        }
    }else{
        setInfo(<AlertError message={dictionary._completeFields}/>)
    }
}




    return (
        <div className='card'>

                <div className='card-body'>
                  <form className='form-horizontal' onSubmit={onSave}>
                    {info}
                    {loading && <ActivityLoader />}

                    <TextInput
                      type='text'
                      label='Title'
                      placeholder='Group reference title'
                      leftIcon='fa fa-tag'
                      value={groupName}
                      onChange={(e)=>setGroupName(e.target.value)}
                      required
                    />


                    <TextInput
                      type='textarea'
                      label='Description'
                      placeholder='Description'
                      leftIcon='fa fa-list'
                      value={description}
                      onChange={(e)=>setDescription(e.target.value)}
                      required
                    />

                    <div className='row'>&nbsp;</div>

                 

                    <div className='row'>&nbsp;</div>

                    <div className='modal-footer no-border'>
                      {!loading && (
                        <button type='submit' className='btn btn-primary'>
                          Save Fees Group
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
    );
}

export default NewFeesGroup;