import React from 'react'
import { Link } from 'react-router-dom'
import functions from '../../util/functions';
import logo from '../../images/logo.png';
import { AuthConsumer } from '../../Context/AuthContext';
import { RenderSecure } from '../../util/script/RenderSecure';
class LeftSideBar extends React.Component{

    state={
        userId:functions.sessionGuard(),
        sideBarInitiated:true,
        activeLocationPath:window.location.pathname,
        activeRoot:''
    }
   
    componentDidMount () {

        const pathSections=this.state.activeLocationPath.split("/");
        this.setState({
            activeRoot: "/" + pathSections[1]
        })
      }



    render()
    {
        return(
          <AuthConsumer>
          {props=>{
            const activeRoot=this.state.activeRoot;
            // const path=this.state.activeLocationPath;
            const userInfo=functions.readUser();
            // const permissionList = props.permissionList;

        return(

            <aside className="main-sidebar sidebar-light-primary elevation-4">
            {/* Brand Logo */}
            <Link to="/" className="brand-link">
              <img src={logo} alt="YEXP" className="brand-image img-circle elevation-3" style={{opacity: ".8"}}/>
              <span className="brand-text font-weight-light">ACCOUNTING</span>
            </Link>
        
            {/* Sidebar */}
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img src={userInfo.photo} className="img-circle elevation-2" alt=" "/>
                </div>
                <div className="info">
                  <Link to="#" className="d-block">{userInfo.first_name + ' ' + userInfo.last_name}</Link>
                </div>
              </div>
        
              {/* Sidebar Menu */}
              <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                  {/* Add icons to the links using the .nav-icon class
                       with font-awesome or any other icon font library */}

                  <li className="nav-item">
                    <Link to="/" className={`nav-link ${(activeRoot==="/" || activeRoot==="/dashboard")?"active":""}`}>
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>
                        Dashboard
                      </p>
                    </Link>
                  </li>
                  <RenderSecure code='SCHOOL-FEES'>
                  <li className={`nav-item has-treeview ${activeRoot==="/school"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/school")?"active":""}`}>
                      <i className="nav-icon fa fa-handshake"></i>
                      <p>
                        School Fees
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/school/fees/overview" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Collection Overview</p>
                        </Link>
                      </li>


                      <li className="nav-item">
                        <Link to="/school/fees/billing" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Billing Center</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/school/fees/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>New Payment</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/school/fees/fees-defaulters" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Fees Defaulters</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/school/fees/fees-group" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Fees Group</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/school/fees/billing-batch" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Billing Batches</p>
                        </Link>
                      </li>
                      
                      <li className="nav-item">
                        <Link to="/school/fees/students" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Manage Students</p>
                        </Link>
                      </li>
    
                    </ul>
                  </li>
                </RenderSecure>

                <RenderSecure code='EXPENSE-ACCESS'>
                  <li className={`nav-item has-treeview ${activeRoot==="/expenses"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/expenses")?"active":""}`}>
                      <i className="nav-icon fas fa-upload"></i>
                      <p>
                        Track Expenses
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/expenses/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>New Expense</p>
                        </Link>
                      </li>


                      <li className="nav-item">
                        <Link to="/expenses/invoicing" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Vendor Invoicing</p>
                        </Link>
                      </li>
    
                    </ul>
                  </li>
                  </RenderSecure>

                  <RenderSecure code='INCOME-ACCESS'>
                  <li className={`nav-item has-treeview ${activeRoot==="/income"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/income")?"active":""}`}>
                      <i className="nav-icon fas fa-download"></i>
                      <p>
                        Track Income
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/income/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>New Income</p>
                        </Link>
                      </li>
    
                    </ul>
                  </li>
                  </RenderSecure>
                
                  <RenderSecure code='ASSET-ACCESS'>
                  <li className={`nav-item has-treeview ${activeRoot==="/asset"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/asset")?"active":""}`}>
                      <i className="nav-icon fas fa-desktop"></i>
                      <p>
                        Track Assets
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/asset/contra/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Transfer Funds</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/asset/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>New Asset</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/asset/appreciate" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Appreciate Asset</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/asset/depreciate" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Depreciate Asset</p>
                        </Link>
                      </li>
                      
    
                    </ul>
                  </li>
                  </RenderSecure>

                  <RenderSecure code='LIABILITY-ACCESS'>
                  <li className={`nav-item has-treeview ${activeRoot==="/liability"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/liability")?"active":""}`}>
                      <i className="nav-icon fas fa-briefcase"></i>
                      <p>
                        Track Liabilities
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/liability/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Add Borrowed Funds</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/liability/pay" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Return Borrowed Funds</p>
                        </Link>
                      </li>
                     
    
                    </ul>
                  </li>
                  </RenderSecure>


                  <li className={`nav-item has-treeview ${activeRoot==="/vendor"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/vendor")?"active":""}`}>
                      <i className="nav-icon fas fa-truck"></i>
                      <p>
                        Vendors / Payees
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/vendor/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>New Vendor</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/vendor/list" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>View Vendors</p>
                        </Link>
                      </li>
                     
                      <li className="nav-item">
                        <Link to="/vendor/import" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Import Vendors</p>
                        </Link>
                      </li>
    
                    </ul>
                  </li>


                  <li className={`nav-item has-treeview ${activeRoot==="/tags"?"menu-open":""}`}>
                    <Link to="#" className={`nav-link ${(activeRoot==="/tags")?"active":""}`}>
                      <i className="nav-icon fas fa-tag"></i>
                      <p>
                        Ledger Tags
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/tags/new" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>New Tag</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/tags/list" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>View Tags</p>
                        </Link>
                      </li>
                     
                      <li className="nav-item">
                        <Link to="/tags/import" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Import Tags</p>
                        </Link>
                      </li>
    
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/accounts/chart" className={`nav-link ${(activeRoot==="/accounts")?"active":""}`}>
                      <i className="nav-icon fas fa-university"></i>
                      <p>
                      Chart Of Accounts
                      </p>
                    </Link>
                  </li>


                  <RenderSecure code='CONFIG-ACCESS'>
                    <li className="nav-item">
                      <Link to="/configurations" className={`nav-link ${(activeRoot==="/configurations")?"active":""}`}>
                        <i className="nav-icon fas fa-cog"></i>
                        <p>
                        Configurations
                        </p>
                      </Link>
                    </li>
                  </RenderSecure>
                 
                
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
          </aside>
       
       )
    }}
    </AuthConsumer>)}

}

export default LeftSideBar