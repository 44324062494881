import React from "react";
import { Link } from "react-router-dom";

const CustomCard=(props)=>{


    return(
        <div className="col-sm-6 col-md-4" title={props.tooltip}>
        <Link to={props.href}>
            <div className="card custom-card pinned-card">
                <div className="card-body d-flex ml-1 mt-3 mb-3">
                    <div className={props.color}>
                        <i className={props.icon}></i>
                    </div>
                    <div className="ml-4">
                        <p className="mb-2 text-muted">{props.title}</p>
                        <h3 className="mb-0" style={{fontSize:props.titleSize}}>{props.value}</h3>
                    </div>
                </div>
            </div>
        </Link>
    </div>
    )

}


export default CustomCard;