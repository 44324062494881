import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import CallOut from '../../Components/Common/CallOut';
import TextInput from '../../Components/Common/TextInput';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';
import ajaxTag from '../../remote/ajaxTag';

class NewTagPage extends Component {

    state={
        info:'',
        loading:false,
        tagName:'',
        tagDescription:''
    }


    

    onChangeTagName=(event)=>{
        this.setState({tagName:event.target.value})
    }
    onChangeTagDescription=(event)=>{
        this.setState({tagDescription:event.target.value})
    }
    

    onSaveTag=async(event)=>{
        event.preventDefault();
        const {tagName, tagDescription} =this.state;
        this.setState({
            loading:true
        })
        const server_response = await ajaxTag.addTag(tagName, tagDescription);
        this.setState({
            loading:false
        })
        if(server_response.status==="OK")
        {
            this.setState({
                info:<AlertSuccess message={server_response.message}/>,
                tagName:"",
                tagDescription:""
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>
            })
        }
    }

    RenderControls=()=>{
        return (
          <Link to="/tags/import" className="btn ripple btn-warning mb-1">
            <i className="fa fa-upload mr-1"></i>&nbsp;Import Tags</Link>
        )
      }

    
   
    render() {
        return (
            <PageContainer title="New Tag">
            <div className="row">
                <div className="col-md-12">
                    <CallOut title="Info" description="Create a new tag to group your transactions. You can then extract individual income statements for each tag."/>
                   
                    <div className="card">
        {/* <div className="card-header"></div> */}

        <div className="card-body">
       
                    <form className="form-horizontal" method="post" onSubmit={this.onSaveTag}>
                                {this.state.info}
                                {this.state.loading && <ActivityLoader/>}

                        <TextInput
                            type="text"
                            label="Tag Name"
                            placeholder="Tag Name"
                            leftIcon="fa fa-tag"
                            value={this.state.tagName}
                            onChange={this.onChangeTagName} 
                            required
                            />

                             <TextInput
                                type="textarea"
                                label="Tag Description"
                                placeholder="Tag Description"
                                leftIcon="fa fa-list"
                                value={this.state.tagDescription}
                                onChange={this.onChangeTagDescription} 
                                required
                                />


                           


                            <div className="row">&nbsp;</div>


                           
        

                            <div className="row">&nbsp;</div>
                            
                            <div className="modal-footer no-border">
                               {!this.state.loading && <button type="submit" className="btn btn-primary">Save Tag</button>}
                            </div>

                        </form> 

   

                    </div>
                    </div>

                    </div>{/** body end */}

                

                </div>
            </PageContainer>
        );
    }
}

export default NewTagPage;