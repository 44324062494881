import React, { useContext } from 'react';
import ConfigContext from '../../Context/ConfigContext';
const UINCDLetterHead=(props)=>{

    const { clientProfile } = useContext(ConfigContext);


        return (
           <>

        {clientProfile && <table border="0" width="100%" style={{fontSize:"13px", fontFamily:"Arial", color:"#000000", borderBottom:"solid"}}>
                <tr>
                <td>Uganda Initiative for<br/>
                Integrated Management of <br/>Non-Communicable<br/>Diseases (UINCD)   </td>
                <td width="50%" style={{textAlign:"center"}}><img src={clientProfile.badge} alt="logo"/></td>
                <td style={{textAlign:"right"}}>Mulago Hospital Complex<br/>
                    Hospital Staff Road, Upper Mulago<br/>
                    {clientProfile.address}<br/>
                    &#x1F310;{clientProfile.website} &nbsp;
                    &#x2709;{clientProfile.email_address}
                </td>
                </tr>
            
                </table>}

                <span style={{float:"left", width:"100%", textAlign:"center", fontSize:"12px", marginTop:"5px", marginBottom:"20px", color:"#000000", fontWeight:"bold"}}>{props.title}</span>
                
            </>
        );
    }


export default UINCDLetterHead;