import React from 'react';
import ActivityLoader from './ActivityLoader';


class WarnDelete extends React.Component {
  state={
      buttonLabel:this.props.buttonLabel || "Confirm Delete",
      loading:false
  }

  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  render(){
    
      return (
        <div className="modal" id={this.props.id || "modal_delete"}>
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content tx-size-sm">
                <div className="modal-body">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                        <span aria-hidden="true">&times;</span></button> 
                        <i className="icon icon ion-ios-close-circle-outline txt-100 txt-danger lh-1 mg-t-20 d-inline-block"></i>
                    <h4 className="txt-danger mg-b-20">{this.props.title}</h4>
                    <p className="mg-b-20 mg-x-20">{this.props.description}</p>
                    
                  
                </div>

                 <div className="modal-footer justify-content-between">
                    {!this.state.loading && <>
                        <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                        <button 
                            type="button" 
                            className={`btn ripple btn-${this.props.buttonType || 'primary'}`} 
                            onClick={this.onConfirm}>{this.state.buttonLabel}</button>
                    </>}
                    {this.state.loading && <ActivityLoader/>}
                </div>


            </div>
        </div>
    </div>
      )
    
 
  }
}

export default WarnDelete;