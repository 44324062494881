import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import functions from '../../util/functions';

class ProfileControls extends Component {

    onlogout=()=>{

        localStorage.removeItem('yexp@user');
        window.localStorage.removeItem('yexp@remote');
        window.location.replace('/login');
    }

    render() {
        const userInfo=functions.readUser();

        return (
            <li className="nav-item dropdown">
            <Link className="nav-link" data-toggle="dropdown" to="#">
              <i className="far fa-user"></i>
              {/* <span className="badge badge-danger navbar-badge">3</span> */}
            </Link>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <Link to="#" className="dropdown-item">
                {/* Message Start */}
                <div className="media">
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      {userInfo.first_name}
                      <span className="float-right text-sm text-success"><i className="fa fa-circle"></i></span>
                    </h3>
                    <p className="text-sm">Logged in as {userInfo.username}</p>
                  </div>
                </div>
                {/* Message End */}
              </Link>
              <div className="dropdown-divider"></div>
              
              <Link to="#" onClick={this.onlogout} className="dropdown-item dropdown-footer">Logout</Link>
            </div>
          </li>
        );
    }
}

export default ProfileControls;