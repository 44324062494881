/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   
    async listTags()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'tag/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async addTag(tagName, description)
    {

        let data={
            tag_name:tagName,
            description:description
        }
        try{
            let response= await fetch(apiHost + 'tag/create',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async filterTags(page)
    {

        let data={
            "page":page,
        }
        try{
            let response= await fetch(apiHost + 'tag/filter',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    }, 
    
    async importTags(importFile)
    {

        let data={
            "tags_file":importFile,
        }
        try{
            let response= await fetch(apiHost + 'tag/import',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


} 