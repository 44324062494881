/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   
    async listRecentTransactions()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'ledger/recent',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async filterTransactions(page, openingDate, closingDate)
    {

        let data={
            page:page,
            opening_date:openingDate,
            closing_date:closingDate
        }
        try{
            let response= await fetch(apiHost + 'ledger/filter',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async updateLedger(ledgerId, amount, transactionDate, description, payee, transactionRef, tag)
    {

        let data={
            "ledger_id":ledgerId,
            "amount":amount,
            "transaction_date":transactionDate,
            "description":description,
            "payee":payee,
            "transaction_reference":transactionRef,
            "tag":tag
        }
        try{
            let response= await fetch(apiHost + 'ledger/update',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async importLedgers(ledgerFile)
    {

        let data={
            "ledger_file":ledgerFile,
            // "file_name":ledgerFile
        }
        try{
            let response= await fetch(apiHost + 'ledger/import',
            // let response= await fetch(apiHost + 'clean',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async deleteLedger(ledgerId)
    {

        let data={
            ledger_id:ledgerId
        }
        try{
            let response= await fetch(apiHost + 'ledger/delete',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async filterAccountTransactions(accountId, page, openingDate, closingDate)
    {

        let data={
            page:page,
            opening_date:openingDate,
            closing_date:closingDate,
            account_id:accountId
        }
        try{
            let response= await fetch(apiHost + 'ledger/account',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

} 