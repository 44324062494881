import React, { useEffect, useState } from 'react';
import ajaxAsset from '../remote/ajaxAsset';
import ajaxExpenses from '../remote/ajaxExpenses';
import ajaxIncome from '../remote/ajaxIncome';

const AccountsContext = React.createContext();

export const AccountsConsumer=AccountsContext.Consumer;

export const AccountsProvider=(props)=> {

    const [expenseAccountsListing, setExpenseAccountsListing] = useState(false);
    const [incomeAccountsListing, setIncomeAccountsListing] = useState(false);
    const [accountsReceivableListing, setAccountsReceivableListing] = useState(false);
    const [cashAccountListing, setCashAccountListing] = useState(false);


    useEffect(()=>{
        getExpenseAccounts();
        getIncomeAccounts();
        getAccountsReceivable();
        getCashAccounts();
    }, [])

    const getExpenseAccounts=async()=>{
            setExpenseAccountsListing(false)
            const server_response = await ajaxExpenses.listExpenseAccounts();
            if(server_response.status==="OK")
            {
                setExpenseAccountsListing(server_response.details)
            }else{
                setExpenseAccountsListing("404")
            }
       
    }


    const getIncomeAccounts=async()=>{
        setIncomeAccountsListing(false)
        const server_response = await ajaxIncome.listIncomeAccounts();
        if(server_response.status==="OK")
        {
            setIncomeAccountsListing(server_response.details)
        }else{
            setIncomeAccountsListing("404")
        }
   
    }


    const getAccountsReceivable=async()=>{
        setIncomeAccountsListing(false)
        const server_response = await ajaxAsset.fetchAccountsReceivable();
        if(server_response.status==="OK")
        {
            setAccountsReceivableListing(server_response.details)
        }else{
            setAccountsReceivableListing("404")
        }
   
    }


    const getCashAccounts=async()=>{
        setCashAccountListing(false)
        const server_response = await ajaxExpenses.listPaymentAccounts();
        if(server_response.status==="OK")
        {
            setCashAccountListing(server_response.details)
        }else{
            setCashAccountListing("404")
        }
   
    }


   
        return (
           <AccountsContext.Provider value={
               {
                  expenseAccountsListing,
                  incomeAccountsListing,
                  accountsReceivableListing,
                  cashAccountListing
                }
               }>
               {props.children}
           </AccountsContext.Provider>
        );
    
}

export default AccountsContext;