import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EditClientProfile from '../../Components/Client/EditClientProfile';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import InitialAvatar from '../../Components/Common/InitialAvatar';
import ConfigMenu from '../../Components/Configuration/ConfigMenu';
import PageContainer from '../../Components/Headings/PageContainer';
import ConfigContext, { ConfigConsumer } from '../../Context/ConfigContext';
import functions from '../../util/functions';

class ClientProfilePage extends Component {

    static contextType = ConfigContext;

    state={
        currentRecord:false,
        transactionId:'',
        mdlCrud:false
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }

    editRecord=()=>{
        this.setState({
            mdlCrud:false
        }, ()=>{
            this.setState({
                mdlCrud:<ConfigConsumer>
                    {props=>{
                        return(
                            <EditClientProfile refresh={props.getClientProfile}/>
                        )
                    }}
                </ConfigConsumer> 
            }, ()=>functions.openPopup("mdl-edit-school"))
        })
    }

    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>
                   
                    <li>
                        <Link to="#" className="btn ripple btn-success mb-1" onClick={this.editRecord}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Edit Profile</Link>
                    </li>
                    <li>
                        <Link to="/configurations" className="btn ripple btn-dark mb-1">
                            <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                    </li>
                </ol>
               
    
                
            </div>
          
        )
      }
    

    

    render() {
        const {  clientProfile } = this.context;
        return (
            <PageContainer 
                title="Company Profile"
                history={{title:"Configurations", link:"/configuration"}}
                headerNav={<this.RenderControls/>}
                >
                        {this.state.mdlCrud}
                        <div className="row">
                            <div className='col-md-9 col-sm-12'>

                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage your Company details: these appear on the reports letter head</h3>
                                    </div>
                                    <div className="card-body">
                                        {! clientProfile && <ActivityLoader/> }

               { clientProfile && <div className='row'> {/** container opens */}

                            <div className="col-lg-4 col-md-12 mt-2">
							<div className="card custom-card">
								<div className="card-body text-center">
									<div className="main-profile-overview widget-user-image text-center">
										<div className="main-img-user">
                                            {! clientProfile.badge && <InitialAvatar value="CP"/>}
											
											{ clientProfile.badge && <img 
													alt="badge"
													src={ clientProfile.badge}
													className="pointer"												
                                                />}
                                            
										</div>
									</div>
									<div className="item-user pro-user">
										<h4 className="pro-user-username text-dark mt-2 mb-0">{ clientProfile.initials}</h4>
										<p className="pro-user-desc text-muted mb-1">{ clientProfile.telephone}</p>
										
										 {/* <Link to="#" style={{color:"#FFFFFF"}}
											onClick="" 
										 	className="btn ripple btn-primary btn-sm">
												 <i className="far fa-edit mr-1"></i>Edit</Link> */}

											
									

									</div>
								</div>
								
							</div>


							<div className="card custom-card">
								<div className="card-header custom-card-header">
									<div>
										<h6 className="card-title mb-0">Accounts Summary</h6>
									</div>
								</div>
								<div className="card-footer p-2">
									<div className="row text-center">
										<div className="col-sm-6 border-right">
											<div className="description-block">
		  										<h5 className="description-header mb-1">...</h5>
												<span className="text-muted">Assets</span>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="description-block">
		 									 <h5 className="description-header mb-1">...</h5>
												<span className="text-muted">Liabilities</span>
											</div>
										</div>
									</div>

								</div>
							</div>
						
                        
                        </div>{/** Left */}

                        <div className="col-lg-8 col-md-12 mt-2">




                                
                        <div className="card custom-card">
                            <div className="">
                            <div className="main-content-body main-content-body-contacts">
                               
                                <div className="main-contact-info-body">
                                
                                <div className="media-list pb-lg-0">
                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Company Name</label> 
                                                <span className="tx-medium">{ clientProfile.client_name}</span>
                                                </div>
                                              
                                            </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                            <label>Address</label> 
                                            <span className="tx-medium">{ clientProfile.address}</span>
                                            </div>
                                            <div>
                                            <label>Telephone</label> 
                                            <span className="tx-medium">{ clientProfile.telephone}</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Company Motto</label> 
                                                <span className="tx-medium">{ clientProfile.motto}</span>
                                                </div>
                                              
                                            </div>
                                    </div>


                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Website</label> 
                                                <span className="tx-medium">{ clientProfile.website}</span>
                                                </div>
                                              
                                            </div>
                                    </div>


                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Email Address</label> 
                                                <span className="tx-medium">{ clientProfile.email_address}</span>
                                                </div>
                                              
                                            </div>
                                    </div>

                                    <div className="media">
                                            <div className="media-body">
                                                <div>
                                                <label>Short Name</label> 
                                                <span className="tx-medium">{ clientProfile.short_name}</span>
                                                </div>
                                              
                                            </div>
                                    </div>


                                    </div>

                                    </div>
                                    </div>
                                    </div>

                                    
                        </div>







                        </div>{/** Right */}
                  

                </div>} {/** container closes */}








                                    </div>
                                </div>
                        

                            </div>

                            <ConfigMenu/>
                       
                         
                        </div>

                     
        </PageContainer>
        );
    }
}

export default ClientProfilePage;