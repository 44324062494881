import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import CallOut from '../../Components/Common/CallOut';
import CustomCard from '../../Components/Common/Cards/CustomCard';
import ConfigMenu from '../../Components/Configuration/ConfigMenu';
import PageContainer from '../../Components/Headings/PageContainer';
import InfoWidget from '../../Components/Widget/InfoWidget';
import { ConfigConsumer } from '../../Context/ConfigContext';
import ajaxConfig from '../../remote/ajaxConfig';
import ajaxUser from '../../remote/ajaxUser';


class ConfigDashboard extends Component {

   

    render() {

        return (
           <ConfigConsumer>
            {props=>{
                return(
                    <PageContainer 
                    title="Configurations"
                    >
                   <div className="row">
                     <div className='col-md-9 col-sm-12'>
                        <div className='row'>

                            <CustomCard
                                href="/configurations/user" 
                                title="System Users" 
                                value={props.totalUsers} 
                                icon="fas fa-user"
                                tooltip="Setup system users" 
                                color="icon-service rounded-circle bg-secondary-transparent text-danger" />

                            <CustomCard
                                href="/configurations/signatory" 
                                title="Signatories" 
                                value={props.totalSignatories} 
                                icon="far fa-edit"
                                tooltip="Manage report signatories" 
                                color="icon-service rounded-circle bg-primary-transparent text-primary" />


                            <CustomCard
                                href="/configurations/signatory" 
                                title="Permissions" 
                                value={props.totalPermissions} 
                                icon="fa fa-lock"
                                tooltip="Setup user permissions" 
                                color="icon-service rounded-circle bg-success-transparent text-success" />



                            <CustomCard
                                href="/subscription/profile" 
                                title="Company Profile" 
                                value="100%" 
                                icon="fa fa-building"
                                tooltip="Setup company profile" 
                                color="icon-service rounded-circle bg-warning-transparent text-warning" />



                        </div>

                    </div>

                    <ConfigMenu/>


                    </div>

                    
                </PageContainer>
                )
            }}
           
           
           
           </ConfigConsumer>
        
        );
    }
}

export default ConfigDashboard;