import React, { Component } from 'react';
import './style/App.css';
import './style/tree.css';
import './style/report.css';
import './style/Custom.css';

import { Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import Login from './Pages/auth/Login';
import Footer from './Components/Common/Footer';
import NotFoundPage from './Pages/error/NotFoundPage';
import ChartOfAccountsPage from './Pages/accounts/ChartOfAccountsPage';
import NewExpensePage from './Pages/expenses/NewExpensePage';
import NewIncomePage from './Pages/income/NewIncomePage';
import DashboardPage from './Pages/DashboardPage';
import NewVendorPage from './Pages/vendor/NewVendorPage';
import LedgerPage from './Pages/transactions/LedgerPage';
import NewTagPage from './Pages/tags/NewTagPage';
import AccountStatementPage from './Pages/reports/AccountStatementPage';
import ReportDashboard from './Pages/reports/ReportDashboard';
import NewFinancialPeriod from './Pages/reports/NewFinancialPeriod';
import ImportCashBook from './Pages/transactions/ImportCashBook';
import ListVendorPage from './Pages/vendor/ListVendorPage';
import ImportVendorsPage from './Pages/vendor/ImportVendorsPage';
import ListTagPage from './Pages/tags/ListTagPage';
import ImportTagsPage from './Pages/tags/ImportTagsPage';
import SystemFinderPage from './Pages/find/SystemFinderPage';
import NewLiabilityPage from './Pages/liability/NewLiabilityPage';
import PayLiabilityPage from './Pages/liability/PayLiabilityPage';
import NewContraPage from './Pages/asset/NewContraPage';
import ConfigDashboard from './Pages/config/ConfigDashboard';
import ListSignatory from './Pages/config/Signatories/ListSignatory';
import ListUsers from './Pages/config/Users/ListUsers';
import ResetPassword from './Pages/auth/ResetPassword';
import functions from './util/functions';
import SuperProvider from './Context/SuperProvider';
import ListPermissions from './Pages/config/Permissions/ListPermissions';
import UserPermissions from './Pages/config/Users/UserPermissions';
import { AuthConsumer } from './Context/AuthContext';
import LoadingPage from './Pages/auth/LoadingPage';
import { RenderSecure } from './util/script/RenderSecure';
import CollectionOverviewPage from './Pages/school_fees/CollectionOverviewPage';
import BillingBatchPage from './Pages/school_fees/BillingBatchPage';
import FeesDefaultersPage from './Pages/school_fees/FeesDefaultersPage';
import NewFeesPaymentPage from './Pages/school_fees/NewFeesPaymentPage';
import FeesGroupPage from './Pages/school_fees/FeesGroupPage';
import StudentsRecordsPage from './Pages/school_fees/StudentsRecordsPage';
import StudentProfile from './Pages/school_fees/StudentProfile';
import BillStructurePage from './Pages/school_fees/BillStructurePage';
import PaymentsPage from './Pages/school_fees/PaymentsPage';
import DiscountPage from './Pages/school_fees/DiscountPage';
import ClientProfilePage from './Pages/profile/ClientProfilePage';
import BillingCenterPage from './Pages/school_fees/BillingCenterPage';
import VendorStatementPage from './Pages/reports/VendorStatementPage';
import InvoicingPage from './Pages/expenses/InvoicingPage';
import NewInvoicePage from './Pages/expenses/NewInvoicePage';
import PayInvoicePage from './Pages/expenses/PayInvoicePage';
import NewAssetPage from './Pages/asset/NewAssetPage';
import AppreciateAssetPage from './Pages/asset/AppreciateAssetPage';
import DepreciateAssetPage from './Pages/asset/DepreciateAssetPage';
import BillingConfigPage from './Pages/school_fees/BillingConfigPage';


class App extends Component {

  state={
    loggedIn:true,
    token:false
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin()
  {
    
    if(!window.localStorage.getItem("yexp@user"))
    {
      this.setState({
        loggedIn:false
      })
    }else{
      this.setState({
        loggedIn:true,
        token:functions.readUser()
      })
    }
  }

  render() {
    return (
      <SuperProvider>
        <AuthConsumer>
          {props=>{
             const permissionList = props.permissionList
             if(!permissionList && this.state.loggedIn)
             {
               return(
                <LoadingPage/>
               )
             }else{
                return(
                   <div className="wrapper" id="main-wrapper">

                    <Router forceRefresh={false}>
                    <Switch>
                        {!this.state.loggedIn && <>
                          <Route exact path="/login" component={Login}/>
                          <Route exact path="/" component={Login}/>
                        </>}

                        {this.state.token.secured*1<=0 && <>
                          <Route exact path="/account/security/password" component={ResetPassword}/>
                        </>}



                        {this.state.loggedIn && this.state.token.secured*1 &&<>
                        <Route exact path="/" component={DashboardPage}/>
                        <Route exact path="/dashboard" component={DashboardPage}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/accounts/chart" component={ChartOfAccountsPage}/>
                        <Route exact path="/expenses/new" component={NewExpensePage}/>
                        <Route exact path="/income/new" component={NewIncomePage}/>
                        <Route exact path="/vendor/new" component={NewVendorPage}/>
                        <Route exact path="/vendor/list" component={ListVendorPage}/>
                        <Route exact path="/vendor/import" component={ImportVendorsPage}/>

                        <Route exact path="/liability/new" component={NewLiabilityPage}/>
                        <Route exact path="/liability/pay" component={PayLiabilityPage}/>


                        <Route exact path="/ledgers" component={LedgerPage}/>
                        <Route exact path="/ledgers/import" component={ImportCashBook}/>

                        <Route exact path="/tags/new" component={NewTagPage}/>
                        <Route exact path="/tags/list" component={ListTagPage}/>
                        <Route exact path="/tags/import" component={ImportTagsPage}/>


                        <Route exact path="/reports/account-statement/:accountId" component={AccountStatementPage}/>
                        <Route exact path="/financial-reports" component={ReportDashboard}/>
                        <Route exact path="/period/new" component={NewFinancialPeriod}/>
                        <Route exact path="/finder" component={SystemFinderPage}/>


                        <Route exact path="/asset/contra/new" component={NewContraPage}/>
                        <Route exact path="/asset/new" component={NewAssetPage}/>
                        <Route exact path="/asset/appreciate" component={AppreciateAssetPage}/>
                        <Route exact path="/asset/depreciate" component={DepreciateAssetPage}/>


                        <Route exact path="/school/fees/overview" component={CollectionOverviewPage}/>
                        <Route exact path="/school/fees/billing-batch" component={BillingBatchPage}/>
                        <Route exact path="/school/fees/fees-defaulters" component={FeesDefaultersPage}/>
                        <Route exact path="/school/fees/new" component={NewFeesPaymentPage}/>
                        <Route exact path="/school/fees/fees-group" component={FeesGroupPage}/>
                        <Route exact path="/school/fees/students" component={StudentsRecordsPage}/>
                        <Route exact path="/school/fees/students/profile/:studentId" component={StudentProfile}/>
                        <Route exact path="/school/fees/structure/:groupId" component={BillStructurePage}/>
                        <Route exact path="/school/fees/payments" component={PaymentsPage}/>
                        <Route exact path="/school/fees/discounting" component={DiscountPage}/>
                        <Route exact path="/school/fees/billing" component={BillingCenterPage}/>
                        <Route exact path="/school/billing/config" component={BillingConfigPage}/>

                       
                        <Route exact path="/subscription/profile" component={ClientProfilePage}/>
                        <Route exact path="/reports/vendor-statement/:vendorId" component={VendorStatementPage}/>

                        <Route exact path="/expenses/invoicing" component={InvoicingPage}/>
                        <Route exact path="/expenses/invoicing/new" component={NewInvoicePage}/>

                        <Route exact path="/expenses/invoicing/pay/:invoiceId" component={PayInvoicePage}/>



                        <RenderSecure code='CONFIG-ACCESS'>
                            <Route exact path="/configurations" component={ConfigDashboard}/>
                            <Route exact path="/configurations/signatory" component={ListSignatory}/>
                            <Route exact path="/configurations/user" component={ListUsers}/>
                            <Route exact path="/configurations/permissions" component={ListPermissions}/>
                            <Route exact path="/user/permissions/:user_id" component={UserPermissions}/>
                        </RenderSecure>
                       
                        </>}
                        <Route exact path="/404" component={NotFoundPage}/>
                        <Route component={NotFoundPage}/>
                      </Switch>

                    </Router>
                    {this.state.loggedIn && this.state.token.secured*1 && <Footer/>}
        
                  </div>
                )
             }
      }}
    </AuthConsumer>
      </SuperProvider>
    );
  }
}

export default App;