import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InvoicingContext from '../../Context/InvoicingContext';
import ajaxLiability from '../../remote/ajaxLiability';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import InitialAvatar from '../Common/InitialAvatar';

class AccountsPayables extends Component {

    static contextType = InvoicingContext

    state={
        list:false,
        info:''
    }

    componentDidMount() {

        //this.getTransactions();
    }


    getTransactions=async()=>{

        const server_response = await ajaxLiability.listRecentPayables();
        if(server_response.status==="OK")
        {
            this.setState({
                list:server_response.details
            })
        }else{
            this.setState({
                list:'404',
                info:server_response.message
            })
        }
    }

    render() {
        const list = this.context.transactionListing;
        return (

            <div className="card">
            <div className="card-header">
              <h3 className="card-title">Incoming Invoices</h3>

              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                </button>
                {/* <button type="button" className="btn btn-tool" data-card-widget="remove"><i className="fas fa-times"></i>
                </button> */}
              </div>
            </div>


            <div className="card-body overflow-auto h-200">
                {!list && <ActivityLoader inline size={30}/>}
                {list==="404" && <AlertError type="info" message="No Incoming invoices found"/>} 
                <ul className="products-list product-list-in-card pl-2 pr-2">
                {Array.isArray(list) && list.map((item, key)=>
                  <li className="item" key={key}>
                    <div className="product-img">
                      <i className="fa fa-truck txt-danger" style={{fontSize:"18px"}}/>
                    </div>
                    <div className="product-inf">
                      <Link to={
                                {
                                    pathname:`/reports/vendor-statement/${item.vendor.payee_id}`,
                                    state:{vendorId:item.vendor.payee_id}
                                }
                            } 
                            className="product-title">{item.vendor.title}
                        <span className="badge badge-warning float-right">{item.amount.amount_c}</span></Link>
                      <span className="product-description">
                        {item.expense_account.account_name + " on " + item.invoice_date.short_date}
                      </span>
                    </div>
                  </li>)}

                  </ul>

            </div>

            </div>



           );
    }
}

export default AccountsPayables;