import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ajaxPeriod from '../../remote/ajaxPeriod';
import functions from '../../util/functions';
import ActivityLoader from '../Common/ActivityLoader';
import WarnDelete from '../Common/WarnDelete';

class FinancialPeriod extends Component {

    state={
        periodList:false,
        selectedPeriod:false,
        mdlSwitch:false
    }

    componentDidMount() {
        this.getPeriods();
    }

    switchPeriod=async(period_id)=>{
      const server_response = await ajaxPeriod.switchPeriod(period_id);
      if(server_response.status==="OK")
      {
        functions.closePopup("mdl-switch-period")
      }else{
        // functions.closePopup("mdl-switch-period")

      }
    }

    onClickSwitchPeriod=(period)=>{
        this.setState({
            mdlSwitch:false
        }, ()=>{
            this.setState({
                mdlSwitch:<WarnDelete 
                    title="Switch Period" 
                    description={"Confirm that you want to switch to the selected financial period(" + period.opening_date.year + '/' + period.closing_date.year  +"). Please note that the date range of the entered records must fall within the range of the selected period."}
                    buttonLabel="Confirm Switch"
                    id="mdl-switch-period"
                    onConfirm={()=>this.switchPeriod(period.period_id)}
                    />
            }, ()=>{
                functions.openPopup("mdl-switch-period")
            })
        })
    }



    getPeriods=async()=>{
        const server_response = await ajaxPeriod.listPeriods();
        if(server_response.status==="OK")
        {
            this.setState({
                periodList:server_response.details
            })
        }else{
            this.setState({
                periodList:"404"
            })
        }
    }

    onSelectPeriod=(period)=>{
        this.setState({
            selectedPeriod:period.period_id
        }, ()=>{
            this.props.onChangePeriod(period)
        })
    }


    render() {
        const periodList = this.state.periodList;
        return (
            <>
            <div className="card">
            <div className="card-header">
              <h3 className="card-title">Financial Periods</h3>


              <div className="card-tools">
                <ul className="pagination pagination-sm float-right">
                  <li className="page-item"><Link to="/period/new" className="page-link bg-success">New Financial Period</Link></li>
                  
                </ul>
              </div>
            </div>
            <div className="card-body p-0">
                {!periodList && <ActivityLoader/>}
              {periodList && periodList!=="404" && <table className="table">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>Assets</th>
                    <th>Liabilities</th>
                    <th style={{width: "40px"}}></th>
                  </tr>
                </thead>
                <tbody>
                  
                  {periodList.map((item, key)=><tr key={key}>
                    <td>
                        <input type="radio" onClick={()=>this.onSelectPeriod(item)} checked={this.state.selectedPeriod===item.period_id}/>
                    </td>
                    <td>{item.opening_date.year}/{item.closing_date.year}</td>
                    <td>{item.balance_sheet.total_assets.amount_c}</td>
                    <td>{item.balance_sheet.total_liabilities.amount_c}</td>
                    <td><button className="btn btn-sm btn-warning" onClick={()=>this.onClickSwitchPeriod(item)}>Switch</button></td>
                  </tr>

                 )}
                 
                </tbody>
              </table>}
            </div>
          </div>
            {this.state.mdlSwitch}
          </>
        );
    }
}

export default FinancialPeriod;