import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import Footer from '../../Components/Common/Footer';
import ajaxUser from '../../remote/ajaxUser';
import dictionary from '../../util/dictionary';
import logo from '../../images/aralogo.png';
import LoginAd from '../../Components/Widget/LoginAd';

class Login extends Component {

    state={
        username:'',
        password:'',
        error:false,
        info:"",
        loading:false
    
    }

    onPressLogin=async (event)=>{
        event.preventDefault();
        const{username, password}=this.state;
        if(username.length>0 && password.length>0)
        {

            this.setState({
              info:<ActivityLoader/>
            })

            var server_response;
            if(process.env.REACT_APP_ENVIRONMENT==="DEVELOPMENT"){
              server_response=await ajaxUser.loginUser(username, password);
  
            }else if(process.env.REACT_APP_ENVIRONMENT==="LIVE_CLIENT"){
              server_response=await ajaxUser.loginUser(username, password);
  
            }else{
              server_response=await ajaxUser.captiveLogin(username, password);
            }

          
          if(server_response.status==="OK")
          {

            var secure;
            if(process.env.REACT_APP_ENVIRONMENT==="DEVELOPMENT"){
              localStorage.setItem('yexp@user', server_response.details.token);
              secure = server_response.details.secured*1;

            }else if(process.env.REACT_APP_ENVIRONMENT==="LIVE_CLIENT"){
              localStorage.setItem('yexp@user', server_response.details.token);
              secure = server_response.details.secured*1;
            }
            else{
              localStorage.setItem('yexp@user', server_response.details.remote_response.details.token);
              localStorage.setItem('yexp@remote', server_response.details.client_link);
              secure = server_response.details.remote_response.details.secured*1;
            }

           if(secure===0)
           {
             this.props.history.push('/account/security/password');

           }else{
             this.props.history.push('/');
           }
           window.location.reload();
           
          }else{
                this.setState({
                  info:<AlertError message={server_response.message}/>,
                  loading:false
                })
          }

        }else{
          this.setState({
            info:<AlertError message={dictionary._completeFields}/>,
            loading:''
          })
        }
       
       
      }

      onChangeUsername = (event) => {
        this.setState({username: event.target.value});
      }

      onChangePassword = (event) => {
        this.setState({password: event.target.value});
      }




      LoginCard=()=>{
        return(
          <div className="login-box">
         
          <div className="card" style={{height:"75vh", width:"30vw", padding:"30px"}}>
          <div className="card-body login-card-body">
          <div className="login-logo-custom">
            <h1>Login</h1>
          </div>
            <p className="login-box-msg">Sign in to start your session</p>
            {this.state.info}
            <form method="post" onSubmit={this.onPressLogin}>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Username" onChange={this.onChangeUsername}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="Password" onChange={this.onChangePassword}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember"/>
                    <label htmlFor="remember">
                      Remember Me
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-success btn-block">Sign In</button>
                </div>
              </div>
            </form>
      
            
      
            <p className="mt-4">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p className="mb-4">
              <Link to="#" className="text-center">Request for a new account</Link>
            </p>
          </div>
        </div>
        </div>
        )
      }


  

    render() {
        return (
    
<div className="col-12 login-page-custom">
<div className='row mt-3'>
      <div className='col-12'>
      <img src={logo} alt="ARAKNERD" width="300px"/>

          
      </div>
</div>

<div className='row'>

    <div className='col-lg-8 col-md-8 col-sm-12'>
         

          <LoginAd/>


    </div>

    
    <div className='col-lg-4 col-md-4 col-sm-12'>
          <this.LoginCard/>
    </div>


</div>







</div>




        );
    }
}

export default Login;