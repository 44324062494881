/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";
import apiCall from "./apiCall";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   
    async listIncomeAccounts()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'income/accounts/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async listPaymentAccounts()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'income/payment-accounts/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },
    async addIncome(paymentAccount, targetAccount, amount, transactionDate, description, payee, transactionRef, tag)
    {

        let data={
            "trail_account":paymentAccount,
            "target_account":targetAccount,
            "amount":amount,
            "transaction_date":transactionDate,
            "description":description,
            "payee":payee,
            "transaction_reference":transactionRef,
            "tag":tag
        }

        const response= await apiCall("income/add", data);
        return response;

    },

    async getIncomeBalance()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'income/balance',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async listIncomeBalances()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'income/balances/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


} 