import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ConfigMenu extends Component {
    state = {
        activeLocationPath: window.location.pathname
    }
    render() {
        const path = this.state.activeLocationPath
        return (
            <div className="col-xl-3 col-lg-3 col-sm-12 d-xl-block custom-leftnav">
                        <div className="main-content-left-components">
                            <div className="card custom-card">
                                <div className="card-body config-component-item">
                                    <nav className="nav flex-column">
                                            <>

                                                <Link
                                                    className={`nav-link ${path === '/configurations' && 'active'}`}
                                                    to="/configurations">Configurations</Link>
                                                <Link
                                                    className={`nav-link ${path === '/configuration/roles' && 'active'}`}
                                                    to="/configuration/roles">User Roles</Link>
                                                <Link
                                                    className={`nav-link ${path === '/configuration/user' && 'active'}`}
                                                    to="/configurations/user">System Users</Link>
                                                
                                                <Link
                                                    className={`nav-link ${path === '/subscription/profile' && 'active'}`}
                                                    to="/subscription/profile">Company Profile</Link>
                                            </>
                                        
                                    </nav>
                                </div>
                            </div>
        
        
                        </div>
                    </div>
        );
    }
}

export default ConfigMenu;