import React from 'react'

class Footer extends React.Component{

    render(){
        return(

            <footer className="main-footer">
            <strong>Copyright &copy; 2017-2023 <a href="https://www.araknerd.com">ARAKNERD</a>.</strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
              <b>Version</b> 2023
            </div>
          </footer>
        )
    }

}

export default Footer