/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";
import apiCall from "./apiCall";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   

    async filter_signatories(page)
    {

        let data={
            page:page
        }
        try{
            let response= await fetch(apiHost + 'config/signatory/list/all',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async get_active_signatories()
    {

        let data={
            page:1
        }
        try{
            let response= await fetch(apiHost + 'config/signatory/list/active',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async create_signatory(first_name, last_name, designation)
    {

        let data={
            "first_name":first_name,
            "last_name":last_name,
            "designation":designation
        }
        try{
            let response= await fetch(apiHost + 'config/signatory/create',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async update_signatory(signatory_id, first_name, last_name, designation)
    {

        let data={
            first_name:first_name,
            last_name:last_name,
            designation:designation,
            signatory_id:signatory_id
        }
        try{
            let response= await fetch(apiHost + 'config/signatory/update',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async update_signatory_status(signatory_id, status)
    {

        let data={
            status:status,
            signatory_id:signatory_id
        }
        try{
            let response= await fetch(apiHost + 'config/signatory/update/status',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async delete_signatory(signatory_id)
    {

        let data={
            signatory_id:signatory_id
        }
        try{
            let response= await fetch(apiHost + 'config/signatory/delete',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async count_signatories()
    {

        let data={
            requester:"admin"
        }
        try{
            let response= await fetch(apiHost + 'config/signatory/list/count',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async filter_roles(page)
    {

        let data={
            page:page
        }
        try{
            let response= await fetch(apiHost + 'config/role/list/all',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


    async fetchClientProfile() {

        let response = await apiCall("client/info");
        return response;
      
      },



      async updateClientProfile(data) {

        let response = await apiCall("client/update", data);
        return response;
      
      },

} 