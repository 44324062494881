import React, { Component } from 'react'

class CallOut extends Component {

  render () {
    return (
      <div className='callout callout-info'>
        <h5>
          <i className='fas fa-info'></i> {this.props.title}
        </h5>
        {this.props.description}
      </div>
    )
  }
  
}

export default CallOut
