import React from 'react'

class InfoBox extends React.Component {

    render() {
        return (
            <div className={`info-box mb-3 ${this.props.bg}`}>
              <span className="info-box-icon">
                  <i className={this.props.iconName}></i>
                </span>

              <div className="info-box-content">
                <span className="info-box-text">{this.props.title}</span>
                <span className="info-box-number">{this.props.value}</span>
              </div>
            </div>
        )
    }

}

export default InfoBox