import React, { Component, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountsContext from '../../Context/AccountsContext';
import SchoolContext from '../../Context/SchoolContext';
import SchoolDiscountContext from '../../Context/SchoolDiscountContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

const PopNewDiscount=()=>{

    const [info, setInfo] = useState("")
    const [loading, setLoading] = useState(false)
    const {feesVotes, listFeesVotes} = useContext(SchoolContext);
    const [studentListing, setStudentListing] = useState(false);
    const {refresh} = useContext(SchoolDiscountContext);
    const [studentId, setStudentId] = useState("");
    const [voteId, setVoteId] = useState("")
    const [amount, setAmount] = useState("");
    const [validity, setValidity] = useState("0");

  
    const onChangeValidity=(item)=>{
            setValidity(item.value)
    }

    const confirm=async(event)=>{
        event.preventDefault();
  
        if(voteId>0 && studentId>0 && amount>0)
        {   
            setInfo(<ActivityLoader/>)
            setLoading(true)
            const server_response = await ajaxSchool.createDiscount({
                
                    "student_id":studentId,
                    "vote_id":voteId,
                    "amount":amount,
                    "recurring":validity
                  
            });
            setLoading(false)
            if(server_response.status==="OK")
            {
               setAmount("");
               refresh();
                setInfo(<AlertSuccess message={server_response.message}/>)
                
            }else{
                setInfo(<AlertError message={server_response.message}/>)
        
            }

        }else{
           
            setInfo(<AlertError message={dictionary._completeFields}/>)
        
        }
    }




        const RenderStudent = () => {

            const optionStudent = []
              Array.isArray(studentListing) && studentListing.map((item, key) =>
                optionStudent.push({
                  label: item.last_name + ' ' + item.first_name + ' - ' + item.reg_no + ' (' + item.class.class_name + ')',
                  value: item.id
                })
              )
            
        
            return (
              <TextInput
                label='Beneficiary Student'
                type='select'
                leftIcon='fa fa-user'
                placeholder='Select student....'
                options={optionStudent}
                onChange={onChangeStudent}
                leftComponent={
                  <i className='fa fa-user'></i>
                }
                loading={!studentListing ? true : false}
                onType={(e)=>{findStudent(e.target.value)}}
                wrap
              />
            )
          }



          const onChangeStudent = item => {
            setStudentId(item.value)
          }


          const onChangeVote = item => {
            setVoteId(item.value)
          
        }
      

          const findStudent=async(hint)=>{
            const server_reponse  = await ajaxSchool.searchStudent(hint);
            if(server_reponse.status === "OK"){
                setStudentListing(server_reponse.details)
               
            }else{
                setStudentListing("404")
            }
        }



        const RenderVotes = () => {

        
            const optionsVoteListing = []
            if (Array.isArray(feesVotes)) {
              feesVotes.map((item, key) =>
                   optionsVoteListing.push({ label: item.vote_name, value: item.id })
              )
            }
        
            return (
              <TextInput
                label='Discounted Fees Vote'
                type='select'
                leftIcon='fa fa-check'
                placeholder='Select discounted fees vote'
                options={optionsVoteListing}
                onChange={onChangeVote}
                leftComponent={<i className='fa fa-check' onClick={listFeesVotes}></i>}
                loading={!feesVotes ? true : false}
                wrap
              />
            )
          }



        return (
            
        <SystemModal id="mdl-new-discount" title="New Fees Discount">
        <form method="post" className="form-horizontal" onSubmit={confirm}>
               {info}

               {RenderStudent()}

               {RenderVotes()}
        
                <TextInput
                    type="number"
                    label="Discounted Amount"
                    placeholder="Enter Discounted Amount"
                    leftIcon="fa fa-money-bill"
                    onChange={(e)=>setAmount(e.target.value)}
                    required
                    wrap
                    name="Amount"
                    value={amount}
                    />



                <TextInput
                    type="select"
                    label="Discount Validity"
                    placeholder="Select Discount Validity"
                    leftIcon="fa fa-calendar-alt"
                    onChange={onChangeValidity}
                    options={[{"label":"One time", "value":"0"}, {"label":"Recurring", "value":"1"}]} 
                    required
                    wrap
                    />
            </form> 
            
            <div className="modal-footer no-border">
                <button className="btn btn-success" type='submit' onClick={confirm} disabled={loading}>
                   {loading?<ActivityLoader inline size={20}/> : "Confirm Discount"}
                </button>
            </div>

   

   

    </SystemModal>
        );
    }


export default PopNewDiscount;