import React, { Component } from 'react';
import ajaxAsset from '../../remote/ajaxAsset';
import ajaxExpenses from '../../remote/ajaxExpenses';
import ajaxIncome from '../../remote/ajaxIncome';
import ajaxLiability from '../../remote/ajaxLiability';
import TitleValueCard from '../Common/TitleValueCard';

class AccountTypeSummary extends Component {

    state={
        incomeBalance:'...',
        expenseBalance:'...',
        assetBalance:'...',
        liabilityBalance:'...'
    }

    componentDidMount() {
        this.getIncomeBalance();
        this.getExpenseBalance();
        this.getAssetBalance();
        this.getLiabilityBalance();
    }


    getIncomeBalance=async()=>{
        const server_response = await ajaxIncome.getIncomeBalance();
        if(server_response.status==="OK")
        {
            this.setState({
                incomeBalance:server_response.details.amount_p
            })
        }
    }   

    getExpenseBalance=async()=>{
        const server_response = await ajaxExpenses.getExpenseBalance();
        if(server_response.status==="OK")
        {
            this.setState({
                expenseBalance:server_response.details.amount_p
            })
        }
    }   


    getAssetBalance=async()=>{
        const server_response = await ajaxAsset.getAssetBalance();
        if(server_response.status==="OK")
        {
            this.setState({
                assetBalance:server_response.details.amount_p
            })
        }
    }   

    getLiabilityBalance=async()=>{
        const server_response = await ajaxLiability.getLiabilityBalance();
        if(server_response.status==="OK")
        {
            this.setState({
                liabilityBalance:server_response.details.amount_p
            })
        }
    }   

    render() {
        return (
            <div className="row">

                <TitleValueCard 
                    title="Income" 
                    value={this.state.incomeBalance}
                    icon={<span className="info-box-icon bg-success elevation-1"><i className="fas fa-download"></i></span>}
                    />

                 <TitleValueCard 
                    title="Expense" 
                    value={this.state.expenseBalance}
                    icon={<span className="info-box-icon bg-primary elevation-1"><i className="fas fa-upload"></i></span>}
                    />

                 <TitleValueCard 
                    title="Assets" 
                    value={this.state.assetBalance}
                    icon={<span className="info-box-icon bg-warning elevation-1"><i className="fas fa-desktop"></i></span>}

                    />

                 <TitleValueCard 
                    title="Liabilities" 
                    value={this.state.liabilityBalance}
                    icon={<span className="info-box-icon bg-danger elevation-1"><i className="fas fa-briefcase"></i></span>}
                    />

            </div>
        );
    }
}

export default AccountTypeSummary;