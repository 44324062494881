import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import PageNav from '../../Components/Common/PageNav';
import SilentText from '../../Components/Common/SilentText';
import WarnDelete from '../../Components/Common/WarnDelete';
import PageContainer from '../../Components/Headings/PageContainer';
import ReportFilter from '../../Components/Report/ReportFilter';
import EditLedger from '../../Components/Transaction/EditLedger';
import ajaxLedger from '../../remote/ajaxLedger';
import functions from '../../util/functions';

class LedgerPage extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:"",
        page:1,
        metaData:false,
        pagination:false,
        activeTab:"main",
        currentRecord:false,
        mdlDelete:false,
        openingDate:'',
        closingDate:''
    }

    componentDidMount() {
        this.listTransactions();
    }

    listTransactions=async()=>{
        const server_response = await ajaxLedger.filterTransactions(this.state.page, this.state.openingDate, this.state.closingDate);
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details.list,
                metaData:server_response.details.meta
            }, ()=>this.paginate())
        }else{
            this.setState({
                transactions:"404",
                info:<AlertError type="info" message={server_response.message}/>
            })
        }
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }


    onClickDelete=(ledgerId)=>{

      this.setState({
        mdlDelete:false
      }, ()=>{

        this.setState({
          mdlDelete:<WarnDelete 
                        title="Delete Ledger" 
                        description={"Confirm that you want to delete this ledger("+ ledgerId + " ) from the system."}
                        id="mdl_delete"
                        buttonType="danger"
                        onConfirm={()=>this.onConfirmDelete(ledgerId)}
                        />
        }, ()=>{
            functions.openPopup("mdl_delete")
        })

      })

    }


    onConfirmDelete=async(ledger_id)=>{
      const server_response = await ajaxLedger.deleteLedger(ledger_id);
      if(server_response.status==="OK")
      {
        functions.closePopup("mdl_delete");
        functions.hide("ledger"+ledger_id)
      }else{
        alert("Failed to delete. " + server_response.message)
      }

  }


    //==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactions:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactions:false,
        pagination:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactions:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


  onClickTab=(value)=>{
      this.setState({activeTab:value})
  }


  filterTransactions=(openingDate, closingDate)=>{
    this.setState({
      openingDate,
      closingDate,
      transactions:false,
      pagination:false,
      metaData:false
    }, ()=>this.listTransactions())
}



  RenderLedgers=()=>{
        const transactions = this.state.transactions;
      return(
       <>
        <ReportFilter 
        title="Manage ledger entries"
        filterSelection={this.filterTransactions}
        />
        <div className="card">
            <div className="card-header border-transparent">
              {this.state.activeTab==="main" && <h3 className="card-title">
                Transaction Listing
                </h3>}

                {this.state.activeTab==="edit" &&  <ol className="breadcrumb float-sm-left">
          <li className="breadcrumb-item"><Link to="#" onClick={()=>this.onClickTab("main")}>Transactions</Link></li>
          <li className="breadcrumb-item active">Edit Ledger</li>
      </ol>}

            </div>

            <div className="card-body p-0">
              {!transactions && <ActivityLoader/>}


                <div className="table table-responsive table-ellipsis">
            {transactions && transactions!=="404" && <table className="table table-bordere text-nowrap mb-0">
                <thead>
                    <tr>
                    <th>Record Date</th>                                   

                    <th>Trans Date</th> 
                            
                    <th>Payee</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Account</th>
                    <th>Created By</th>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {transactions.map((item, key)=>

                    <tr 
                    className={`${item.core_grouping_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                    style={{cursor:'default'}}
                    key={key}
                    onClick={()=>this.onSelectRecord(item.core_grouping_id, item)}
                    id={"ledger" + item.core_grouping_id}
                    >
                    <td>{item.created_at.short_date}
                        <br/><SilentText text={item.created_at.time}/>
                    </td>
                    <td>{item.transaction_date.short_date}
                    <br/><SilentText text={"Ledger No. " + item.ledger_no}/>
                    </td>

                    <td>
                        {item.payee?item.payee.title:""}
                        {item.tag && <><br/><i className="fa fa-tag small-caps"/><SilentText text={item.tag.tag_name}/></>}
                    </td>
                    <td>{item.amount.amount_c}
                        {item.transaction_ref && <><br/><SilentText text={"Ref: " + item.transaction_ref}/></>}
                    </td>
                    <td>{item.description}</td>
                    <td>
                        {item.account.account_name}
                        <br/><SilentText text={item.ledger_grouping?item.ledger_grouping.account.account_name:""}/>
                    </td>
                    <td>
                        {item.created_by.first_name}
                        <br/><SilentText text={item.created_by.username}/>
                    </td>

                    <td>
                        <button type="button" className="btn btn-primary btn-flat btn-pri btn-sm" onClick={()=>this.onClickTab("edit")}>Edit</button>
                        <button type="button" className="btn btn-danger btn-flat btn-pri btn-sm" onClick={()=>this.onClickDelete(item.core_grouping_id)}>Delete</button>

                    </td>
        
                </tr>
            
                )}
                
                </tbody>
            </table>}
                {transactions==="404" && this.state.info}
                {this.state.pagination}



            </div>

          </div>
    
    </div>
    </>
      )
  }


  RenderHeading=()=>{
      if(this.state.activeTab==="main")
      {
          return  <h3>All Transactions</h3>
      }else{
          return  <ol className="breadcrumb">
          <li><Link to="#" onClick={()=>this.onClickTab("main")}>Transactions</Link></li>
          <li className="active">Edit Ledger</li>
      </ol>
      }
  }

  RenderControls=()=>{
    return (
      <Link to="/ledgers/import" className="btn ripple btn-dark mb-1">
        <i className="fa fa-upload mr-1"></i>&nbsp;Import</Link>
    )
  }


    render() {
       

        return (
           <PageContainer title="Ledgers">
                      {this.state.mdlDelete}
                     <div className="row">
                        <div className="col-md-12">
                       

					              {this.state.activeTab==="main" && <this.RenderLedgers/>}
                        {this.state.activeTab==="edit" && <EditLedger record={this.state.currentRecord} onClickTab={this.onClickTab}/>}
                        </div>

                        
                    </div>
          </PageContainer>
                
        );
    }
}

export default LedgerPage;