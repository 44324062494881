import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';
import StudentInputField from './Student/StudentInputField';

class PopCustomBill extends Component {

    static contextType = SchoolContext

    state={
       voteId:"",
       description:"",
       studentId:"",
       amount:"",
       loading:false,
       info:"",
       record:""
    }



   
    


   
    onChangeVote=(item)=>{
        this.setState({
            voteId:item.value,
            record:item.item
        })
    }


    onChangeDescription=(event)=>{
        this.setState({
            description:event.target.value
        })
    }

    onChangeAmount=(event)=>{
        this.setState({
            amount:event.target.value
        })
    }

    onChangeStudent=(studentRecord)=>{
        this.setState({studentId:studentRecord.id})
    }




    confirmAssign=async(event)=>{
        event.preventDefault();
  
        const { studentId, voteId, description, amount }=this.state;
        if(studentId.length>0 && voteId.length>0 && description.length>0 && amount>0)
        {   
            this.setState({info:<ActivityLoader/>})
            const server_response = await ajaxSchool.addCustomBill(studentId, voteId, amount, description);
            if(server_response.status==="OK")
            {

                this.setState({
                    amount:'',
                    info:<AlertSuccess message={server_response.message}/>
                })
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={"Complete all fields and try again"}/>
            })
        }
    }


   


    render() {


        const feesVotes = this.context.feesVotes;
        const optionsGroupList=[];
        if(Array.isArray(feesVotes))
        {
            feesVotes.forEach((item, key)=>{
                optionsGroupList.push({label:item.vote_name, value:item.id, item:item})
            })
        }


        return (
            
    <SystemModal id="mdl-custom-bill" title="Student Custom Billing">
        <form method="post" className="form-horizontal" onSubmit={this.confirmAssign}>
               {this.state.info}
              
               
               <StudentInputField wrap={true} onChange={this.onChangeStudent}/>
               
               <TextInput
                    type="select"
                    label="Fees Vote"
                    placeholder="Select fees vote"
                    leftIcon="fa fa-check"
                    onChange={this.onChangeVote}
                    options={optionsGroupList} 
                    required
                    wrap
                    />


                <TextInput
                    type="number"
                    label="Bill amount"
                    placeholder="Enter amount"
                    leftIcon="fa fa-money-bill"
                    onChange={this.onChangeAmount}
                    required
                    value={this.state.amount}
                    wrap
                    />

                <TextInput
                    type="texarea"
                    label="Bill Description"
                    placeholder="Bill Description"
                    leftIcon="fa fa-align-justify"
                    onChange={this.onChangeDescription}
                    required
                    wrap
                    value={this.state.description}
                    />
            </form> 
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary" onClick={this.confirmAssign}>Bill Student</button>
            </div>

   

   

    </SystemModal>
        );
    }
}

export default PopCustomBill;