import React, { Component } from 'react';
import ConfigContext from '../../Context/ConfigContext';
import ajaxConfig from '../../remote/ajaxConfig';
import dictionary from '../../util/dictionary';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';


class EditClientProfile extends Component {

    static contextType = ConfigContext;

    state={
        clientName:'',
        address:'',
        telephone:'',
        motto:'',
        website:'',
        email:'',
        shortName:'',
        info:false,
        actualFile:"",
        fileSource:""

    }

    componentDidMount() {
        const {clientProfile} = this.context;
        if(clientProfile)
        {
            this.setState({
                clientName: clientProfile.client_name || "",
                address:  clientProfile.address || "",
                telephone:  clientProfile.telephone || "",
                website:  clientProfile.website || "",
                email: clientProfile.email_address || "",
                motto:  clientProfile.motto || "",
                shortName:  clientProfile.short_name || ""
            })
        }
       
    }

  

    handleFormChange=(event)=>{
        this.setState({
           [event.target.name]:event.target.value
        })
    }


   handleFileChange=(event)=>{
        this.setState({
            fileSource:event.target.value
        })
        let files=event.target.files;
        let reader=new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload=(e)=>{
            this.setState({
                actualFile:{file:  e.target.result}
            })
            
        }
}

   
    confirmSave=async(event)=>{
        event.preventDefault();
  
        const {clientName, motto, address, email, website, shortName, telephone}=this.state;
        
        const data={
            "client_name":clientName,
            "telephone":telephone,
            "address":address,
            "email_address":email,
            "motto":motto,
            "short_name":shortName,
            "website":website,
            "badge":this.state.actualFile
        }
       
        if(clientName.length>0 && motto.length>0 && address.length>0 && email.length>0 && telephone.length>0 && shortName.length>0)
        {
          
            const server_response = await ajaxConfig.updateClientProfile(data);
            if(server_response.status==="OK")
            {
                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
                this.props.refresh();
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={dictionary._completeFields}/>
            })
        }
    }


    RenderRoles=()=>{
        const {userRoles} = this.context;
        const optionRoles=[];
        Array.isArray(userRoles) && userRoles.map((item, key)=>
            optionRoles.push({label:item.role_name, value:item.role_id})
        )
        return  <TextInput
        type="select"
        label="User Role"
        placeholder="Select User Role...."
        leftIcon="fa fa-user"
        onChange={this.setRoleId} 
        options={optionRoles}
        required
        wrap
        />
    }


    render() {
        return (
            
    <SystemModal id="mdl-edit-school" title="Edit Company Profile" type="modal-lg">
    <form method="post" className="form-horizontal" onSubmit={this.confirmSave}>
               {this.state.info}
               <TextInput
                            type="text"
                            label="Company Name"
                            placeholder="Company Name"
                            leftIcon="fa fa-info"
                            value={this.state.clientName}
                            name="clientName"
                            onChange={this.handleFormChange} 
                            required
                            />

                <TextInput
                    type="text"
                    label="Company Address"
                    placeholder="Company Address"
                    leftIcon="fa fa-map-pin"
                    value={this.state.address}
                    name="address"
                    onChange={this.handleFormChange} 
                    required
                    />


               <TextInput
                    type="text"
                    label="Company Motto"
                    placeholder="Company Motto"
                    leftIcon="fa fa-info"
                    value={this.state.motto}
                    name="motto"
                    onChange={this.handleFormChange} 
                    required
                    />


               <TextInput
                    type="text"
                    label="Telephone"
                    placeholder="Telephone"
                    leftIcon="fa fa-phone"
                    value={this.state.telephone}
                    name="telephone"
                    onChange={this.handleFormChange} 
                    required
                    />


                <TextInput
                    type="text"
                    label="Email Address"
                    placeholder="Email address"
                    leftIcon="fa fa-envelope"
                    value={this.state.email}
                    name="email"
                    onChange={this.handleFormChange} 
                    required
                    />
               
    
               <TextInput
                    type="text"
                    label="Website"
                    placeholder="Website"
                    leftIcon="fa fa-globe"
                    value={this.state.website}
                    name="website"
                    onChange={this.handleFormChange} 
                    />
                <TextInput
                    type="text"
                    label="Short Name"
                    placeholder="Short Name"
                    leftIcon="fa fa-info"
                    value={this.state.shortName}
                    name="shortName"
                    onChange={this.handleFormChange} 
                    required
                    />


                <TextInput
                    type="file"
                    label="Company Badge"
                    placeholder="Website"
                    leftIcon="fa fa-info"
                    value={this.state.fileSource}
                    name="badge"
                    onChange={this.handleFileChange} 
                    />


       
            
            <div className="modal-footer no-border">
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>

    </form> 

   

    </SystemModal>
        );
    }
}

export default EditClientProfile;