import React, { Component } from 'react';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';
import TextInput from '../Common/TextInput';

class PopChangeBatch extends Component {

    static contextType = SchoolContext

    state={
       batchId:"",
       loading:false,
       info:"",
       record:""
    }


   
    onChangeGroup=(item)=>{
        this.setState({
            batchId:item.value,
            record:item.item
        })
    }




    confirmAssign=async(event)=>{
        event.preventDefault();
  
        const { batchId }=this.state;
        if(batchId.length>0)
        {   
            this.props.onConfirm(batchId)
            this.setState({loading:true})
        }else{
            this.setState({
                info:<AlertError message={"Select batch and try again"}/>
            })
        }
    }


   


    render() {


        const billingBatches = this.context.billingBatches;
        const optionsGroupList=[];
        if(Array.isArray(billingBatches))
        {
            billingBatches.forEach((item, key)=>{
                optionsGroupList.push({label:item.batch_name, value:item.id})
            })
        }


        return (
            
    <SystemModal id="mdl-change-batch" title={this.props.title || "Change Batch"}>
        <form method="post" className="form-horizontal" onSubmit={this.confirmAssign}>
               {this.state.info}
              
               <TextInput
                            type="select"
                            label="Select Batch"
                            placeholder="Select billing batch"
                            leftIcon="fa fa-calendar"
                            onChange={this.onChangeGroup}
                            options={optionsGroupList} 
                            required
                            wrap
                            />
            </form> 
            
            <div className="modal-footer no-border">
                
               {!this.state.loading && <button type="submit" className="btn btn-primary" onClick={this.confirmAssign}>{this.props.commandTitle || "View Statement"}</button>}
               {this.state.loading && <ActivityLoader/>}
            </div>

   

   

    </SystemModal>
        );
    }
}

export default PopChangeBatch;