/* eslint-disable import/no-anonymous-default-export */

import dictionary from "../util/dictionary";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('yexp@user');

export default {
   
    async listLiabilityAccounts()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'liability/accounts/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async listPaymentAccounts()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'income/payment-accounts/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },
   
    async addLiability(paymentAccount, targetAccount, amount, transactionDate, description, payee, transactionRef, tag)
    {

        let data={
            "trail_account":paymentAccount,
            "target_account":targetAccount,
            "amount":amount,
            "transaction_date":transactionDate,
            "description":description,
            "payee":payee,
            "transaction_reference":transactionRef,
            "tag":tag
        }
        try{
            let response= await fetch(apiHost + 'liability/add',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async getLiabilityBalance()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'liability/balance',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async listRecentPayables()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'liability/payables/recent',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async listLiabilityBalances()
    {

        let data={
            "source":"Web",
        }
        try{
            let response= await fetch(apiHost + 'liability/balances/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async reduceLiability(paymentAccount, targetAccount, amount, transactionDate, description, payee, transactionRef, tag)
    {

        let data={
            "trail_account":paymentAccount,
            "target_account":targetAccount,
            "amount":amount,
            "transaction_date":transactionDate,
            "description":description,
            "payee":payee,
            "transaction_reference":transactionRef,
            "tag":tag
        }
        try{
            let response= await fetch(apiHost + 'liability/reduce',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

  

} 