import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import AlertError from '../../../Components/Common/AlertError';
import PageNav from '../../../Components/Common/PageNav';
import EditSignatory from '../../../Components/Configuration/Signatory/EditSignatory';
import NewSignatory from '../../../Components/Configuration/Signatory/NewSignatory';
import EditUser from '../../../Components/Configuration/User/EditUser';
import NewUser from '../../../Components/Configuration/User/NewUser';
import PageContainer from '../../../Components/Headings/PageContainer';
import ajaxConfig from '../../../remote/ajaxConfig';
import ajaxUser from '../../../remote/ajaxUser';
import functions from '../../../util/functions';

class ListUsers extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:"",
        page:1,
        metaData:false,
        pagination:false,
        currentRecord:false,
        accountInfo:false,
        mdlCrud:false
    }

    componentDidMount() {

        this.listTransactions();
    }

    listTransactions=async()=>{
        const server_response = await ajaxUser.filter_users(this.state.page);
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details.list,
                metaData:server_response.details.meta
            }, ()=>this.paginate())
        }else{
            this.setState({
                transactions:"404",
                info:<AlertError type="info" message={server_response.message}/>
            })
        }
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }



//==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================




  RenderTransactions=()=>{
        const transactions = this.state.transactions;
      return(
        <>


            <div className="table-responsive">
            {transactions && transactions!=="404" && 
            <table id="exportexample" className="table table-bordered border-t0 key-buttons text-nowrap w-100" >
                <thead>
                    <tr>
                    <th>No.</th>                                   
                    <th>First Name</th> 
                    <th>Last Name</th> 
                    <th>Username</th>
                    <th>Role</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Last updated</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map((item, key)=>
                    <tr 
                    className={`${item.user_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                    style={{cursor:'default'}}
                    onClick={()=>this.onSelectRecord(item.user_id, item)}
                    key={key}>
                        <td>{this.state.metaData.offset_count+key+1}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name }</td>
                        <td>
                          <Link to={
                                {
                                    pathname:`/user/permissions/${item.user_id}`,
                                    state:{user_id:item.user_id}
                                }
                            }>
                          @{item.username}
                          </Link>
                         
                        </td>
                        <td>{item.role.role_name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone_number}</td>
                        <td>{item.updated_at.short_date + ' ' + item.updated_at.time}</td>
                    </tr>
                )} 
                </tbody>
            </table>}

                {!transactions && <ActivityLoader/>}
                {this.state.pagination}



            </div>


    
    </>

      )
  }


  RenderControls=()=>{
    return (
        <div className='float-sm-right'>
            <ol className='button-listing'>
                {this.state.currentRecord && <>
                    <li>
                    <Link to="#" className="btn ripple btn-warning mb-1" onClick={this.editUser}>
                        <i className="fa fa-pen mr-1"></i>&nbsp;Edit User</Link>
                    </li>
                    <li>
                    <Link to="#" className="btn ripple btn-danger mb-1" onClick={this.editSignatory}>
                        <i className="fa fa-plus mr-1"></i>&nbsp;Permissions</Link>
                </li>
                </>}

                
                <li>
                    <Link to="#" className="btn ripple btn-success mb-1" onClick={this.newUser}>
                    <i className="fa fa-edit mr-1"></i>&nbsp;New User</Link>
                </li>
                <li>
                    <Link to="/configurations" className="btn ripple btn-dark mb-1">
                        <i className="fa fa-cog mr-1"></i>&nbsp;Home</Link>
                </li>
            </ol>
           

            
        </div>
      
    )
  }



  newUser=()=>{
      this.setState({
          mdlCrud:false
      }, ()=>{
          this.setState({
              mdlCrud:<NewUser refresh={this.listTransactions}/>
          }, ()=>functions.openPopup("mdl-new-user"))
      })
  }

  editUser=()=>{
    this.setState({
        mdlCrud:false
    }, ()=>{
        this.setState({
            mdlCrud:<EditUser refresh={this.listTransactions} record={this.state.currentRecord}/>
        }, ()=>functions.openPopup("mdl-edit-user"))
    })
}

    render() {
       

        return (
          <PageContainer 
            title="Manage Users"
            headerNav={<this.RenderControls/>}
            >
          <div className="row">
              <div className="col-md-12">
                  {this.state.info}
                  {this.state.mdlCrud}
              <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">System users listing</h3>
                  </div>

                <div className="card-body">
					          <this.RenderTransactions/>
                </div>
              </div>

						
          </div>
          </div>
          </PageContainer>
        );
    }
}

export default ListUsers;
