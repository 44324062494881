import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import CallOut from '../../Components/Common/CallOut';
import WarnDelete from '../../Components/Common/WarnDelete';
import PageContainer from '../../Components/Headings/PageContainer';
import EditLedger from '../../Components/Transaction/EditLedger';
import SearchContext from '../../Context/SearchContext';
import ajaxLedger from '../../remote/ajaxLedger';
import functions from '../../util/functions';
import { RenderSecure } from '../../util/script/RenderSecure';

class SystemFinderPage extends Component {

  static contextType = SearchContext;

    state={
        transactions:false,
        info:'',
        activeTab: (this.props.location.state)?this.props.location.state.tab : "main",
        currentRecord:false,
        query:'',
        accountInfo:false,
        mdlDelete:false,
        tabEdit:false
    }



    renderAccountResults=()=>{
      const results = this.context.searchResults;

      return <div className="active tab-pane" id="accounts">
          {results.account_results && results.account_results.map((item, key)=>
             <div className="row" key={key} style={{borderBottomWidth:1, borderBottomStyle:'solid', borderColor:'#efefef', paddingBottom:'10px'}}>
                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                      <dt>
                      <Link to={
                                {
                                    pathname:`/reports/account-statement/${item.account_id}`,
                                    state:{accountId:item.account_id}
                                }
                            } >{item.account_name}</Link></dt>
                      <dd><i>{item.account_category.category_name + ' account'}</i>
                        {item.description==="none"?"":" - " + item.description} 
                      </dd>
                      <dt>Account No: {item.account_number}</dt>
                      <dd>#{item.account_id}
                        {!item.main_account?"":" - under: " + item.main_account.account_number + ' - ' + item.main_account.account_name}
                      </dd>
                      
                    </dl>
                  
                </div>

                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                      <dt>Account Balance</dt>
                      <dd>{item.balance.amount_p}</dd>
                      <dt>Related Links</dt>
                      <dd><Link to="/accounts/chart">Chart of Accounts</Link></dd>
                      <dd> <Link to={
                                {
                                    pathname:`/reports/account-statement/${item.account_id}`,
                                    state:{accountId:item.account_id}
                                }
                            } >Statement of accounts</Link></dd>
                      
                    </dl>
                  
                </div>
         </div>
          )}

          {!results && <ActivityLoader/>}
          {results && !results.account_results &&  <CallOut title="info" description="No account results found"/>}
      </div>
    }



    renderPayeeResults=()=>{
      const results = this.context.searchResults;
      return <div className="tab-pane active" id="payee">
              {results.payee_results && results.payee_results.map((item, key)=>
             <div className="row" key={key} style={{borderBottomWidth:1, borderBottomStyle:'solid', borderColor:'#efefef', paddingBottom:'10px'}}>
                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                      <dt>
                      <Link to={
                                {
                                    pathname:`/reports/vendor-statement/${item.payee_id}`,
                                    state:{vendorId:item.payee_id}
                                }
                            } >
                          
                          {item.title}</Link></dt>
                      <dd><i>#{item.ref}</i>
                        {item.description==="none"?"":" - " + item.description} 
                      </dd>
                      {item.address!=="none" && <><dt>Address</dt>
                      <dd>{item.address}</dd></>}
                      
                    </dl>
                  
                </div>

                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                      <dt>Contact Info</dt>
                      <dd><i className="fa fa-phone txt-primary"/> {item.phone_number}</dd>
                      <dd><i className="fa fa-envelope txt-primary"/> {item.email}</dd>
                      
                    </dl>
                  
                </div>
         </div>
          )}

              {!results && <ActivityLoader/>}
              {results && !results.payee_results &&  <CallOut title="info" description="No payee results found"/>}
      </div>
    }


    renderLedgerResults=()=>{
      const results = this.context.searchResults;
      return <div className="tab-pane active" id="ledger">


        {results.ledger_results && results.ledger_results.map((item, key)=>
             <div className="row" id={"ledger" + item.core_grouping_id} key={key} style={{borderBottomWidth:1, borderBottomStyle:'solid', borderColor:'#efefef', paddingBottom:'10px'}}>
                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                      <dt><Link to="#">Ledger No. {item.ledger_no}</Link></dt>
                      <dd>{item.description}</dd>
                      <dt>Amount: {item.amount.amount_p}
                      &nbsp; {item.tag && <span><i className="fa fa-tag text-primary"/> Tag: {item.tag.tag_name}</span>}
                      </dt>
                      <dd>Transaction date: {item.transaction_date.short_date}
                        {" | Recorded " + item.created_at.when + " by " + item.created_by.username}
                      </dd>

                      <dd>
                        <Link to="#" onClick={()=>this.onClickDelete(item.core_grouping_id)}><i className="fa fa-trash-alt txt-danger"/></Link>&nbsp;&nbsp;&nbsp;
                        <Link to="#" onClick={()=>this.onClickEdit(item)}><i className="fa fa-edit txt-primary"/></Link>
                      </dd>
                      
                    </dl>
                  
                </div>

                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                    {item.payee && <><dt>Payee</dt>
                      <dd>{item.payee.title}</dd></>}
                      <dt>Accounts</dt>
                      <dd><Link to="#"><i className="fa fa-university"/> {item.account.account_number + ' - ' + item.account.account_name}</Link></dd>
                      
                     {item.ledger_grouping && <dd><Link to="#"><i className="fa fa-university"/> {item.ledger_grouping.account.account_number + ' - ' + item.ledger_grouping.account.account_name}</Link></dd>}
                      
                    </dl>
                  
                </div>
         </div>
          )}


        {!results && <ActivityLoader/>}
        {results && !results.ledger_results &&  <CallOut title="info" description="No ledger results found"/>}
      </div>
    }
   

    onClickDelete=(ledgerId)=>{

      this.setState({
        mdlDelete:false
      }, ()=>{

        this.setState({
          mdlDelete:<WarnDelete 
                        title="Delete Ledger" 
                        description={"Confirm that you want to delete this ledger (" + ledgerId + ") from the system."}
                        id="mdl_delete"
                        buttonType="danger"
                        onConfirm={()=>this.onConfirmDelete(ledgerId)}
                        />
        }, ()=>{
            functions.openPopup("mdl_delete")
        })

      })

    }


    renderStudentResults=()=>{
      const results = this.context.searchResults;
      return <div className="tab-pane active" id="student">
              {results.student_results && results.student_results.map((item, key)=>
             <div className="row" key={key} style={{borderBottomWidth:1, borderBottomStyle:'solid', borderColor:'#efefef', paddingBottom:'10px'}}>
                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                      <dt> 
                        <Link to={
                                  {
                                      pathname:`/school/fees/students/profile/${item.id}`,
                                      state:{studentId:item.id}
                                  }
                                  }>{item.full_name}</Link></dt>
                      <dd><i>#{item.reg_no}</i> - {item.fees_group?item.fees_group.group_name:"No fees group assigned"} </dd>
                      <><dt>Payment Code</dt>
                      <dd>{item.payment_code}</dd></>
                      
                    </dl>
                  
                </div>

                <div className="col-md-6 col-sm-6 mt-2">
                    <dl>
                      <dt>Home Contact</dt>
                      <dd><i className="fa fa-phone txt-primary"/> {item.home_contact}</dd>

                      <dt>Class</dt>
                      <dd><i className="fa fa-users txt-primary"/> {item.class.class_name}</dd>
                      
                    </dl>
                  
                </div>
         </div>
          )}

              {!results && <ActivityLoader/>}
              {results && !results.student_results &&  <CallOut title="info" description="No student results found"/>}
      </div>
    }



    onConfirmDelete=async(ledger_id)=>{
        const server_response = await ajaxLedger.deleteLedger(ledger_id);
        if(server_response.status==="OK")
        {
          functions.closePopup("mdl_delete");
          functions.hide("ledger"+ledger_id)
        }else{
          alert("Failed to delete. " + server_response.message)
        }

    }


    onClickEdit=(record)=>{
        this.setState({
          tabEdit:false
        }, ()=>{
          this.setState({
            tabEdit:<EditLedger record={record}/>
          })

        })
       
    }


    resetEdit=()=>{
      this.setState({
        tabEdit:false,
        activeTab:"ledger"
      })
    }


    onClickTab=(activeTab)=>{
      this.setState({
        activeTab
      })
    }

    render() {
       

        return (
          <>
          <PageContainer title="Finder">
          <div className="row">
              <div className="col-md-12">
                <AlertError type="light" message={<span><b><i className="fa fa-search txt-primary"/> Search results for: </b><i>{this.context.query}</i></span>}/>
              

              <div className="card">
                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                {!this.state.tabEdit && <>
                                <li className="nav-item"><a className={`nav-link ${this.state.activeTab==="main"?"active":""}`} href="#" onClick={()=>this.onClickTab("main")}>Account results</a></li>
                                <li className="nav-item"><a className={`nav-link ${this.state.activeTab==="payee"?"active":""}`} href="#" onClick={()=>this.onClickTab("payee")}>Payee/Vendor results</a></li>
                                <li className="nav-item"><a className={`nav-link ${this.state.activeTab==="ledger"?"active":""}`} href="#" onClick={()=>this.onClickTab("ledger")}>Ledger results</a></li>
                                <RenderSecure code='SCHOOL-FEES'>
                                  <li className="nav-item"><a className={`nav-link ${this.state.activeTab==="student"?"active":""}`} href="#" onClick={()=>this.onClickTab("student")}>Student results</a></li>
                                </RenderSecure>
                                </>}

                                {this.state.tabEdit && <>
                                <li className="nav-item"><a className="nav-link" href="#ledger" data-toggle="tab" onClick={this.resetEdit}>Search Results</a></li>
                                <li className="nav-item"><a className="nav-link active" href="#edit" data-toggle="tab">Edit Ledger</a></li>

                                </>}

                                </ul>
                            </div>
                            {/* <!-- /.card-header --> */}
                            <div className="card-body">
                                <div className="tab-content">
                                {!this.state.tabEdit && <>
                                {this.state.activeTab==="main" && <this.renderAccountResults/>}
                                {this.state.activeTab==="payee" && <this.renderPayeeResults/>}
                                {this.state.activeTab==="ledger" && <this.renderLedgerResults/>}
                                {this.state.activeTab==="student" && <this.renderStudentResults/>}

                                </>}
                                {this.state.tabEdit}
                                {/* <!-- /.tab-pane --> */}
                                </div>
                                {/* <!-- /.tab-content --> */}
                            </div>
                                    {/* <!-- /.card-body --> */}
                            </div>
         

						
          </div>
          </div>
          </PageContainer>
          {this.state.mdlDelete}
          </>
        );
    }
}

export default SystemFinderPage;