import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SchoolDiscountContext from '../../Context/SchoolDiscountContext';
import CardTitle from '../Common/CardTitle';

class DiscountedStudents extends Component {
    static contextType = SchoolDiscountContext

    render() {
        
        const {recurringListing} = this.context

        return (
            <div className="card">
            <CardTitle
                title="Recurring Discounts"
                subtitle="These discounts are automatically effected per term."
            />

            <div className="card-body p-0 overflow-auto" style={{height:"325px"}}>
                <ul className="products-list product-list-in-card pl-2 pr-2">
                    
                    { Array.isArray(recurringListing) && recurringListing.map((item, key)=><li className="item" key={key}>
                
                        <div className="product-info">
                        <Link to="#" className="product-title">{item.student.last_name + ' ' + item.student.first_name}
                            <span className="badge badge-success float-right mr-3">UGX. {item.amount.amount_c}</span>
                        </Link>
                        <span className="product-description">
                        {item.student.reg_no + " |  " + item.vote.vote_name  + " | " + item.student.class.class_name}
                        </span>
                        </div>
                     </li>)}
                
                </ul>
            </div>
            
            </div>
        );
    }
}

export default DiscountedStudents;