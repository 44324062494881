import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ConfigConsumer } from '../../../Context/ConfigContext';
import ajaxConfig from '../../../remote/ajaxConfig';
import ajaxUser from '../../../remote/ajaxUser';
import { removeTables } from '../../../util/cleanTables';
import dictionary from '../../../util/dictionary';
import { beautifyTable } from '../../../util/script/customTables';
import ActivityLoader from '../../Common/ActivityLoader';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import SystemModal from '../../Common/SystemModal';
import TextInput from '../../Common/TextInput';

class GrantPermission extends Component {

    state={
       info:'',
       userId:this.props.userId,
       permissionId:'',
       loading:false
    }

   componentDidMount() {
        var tableElement = document.getElementsByClassName("datatable");
        if(tableElement.length>0)
        {
            beautifyTable(tableElement[0].getAttribute('id'), true, false)
            // console.log(tableElement[0].getAttribute('id'))
        }
   }

    confirmGrant=(permissionId)=>{

            this.setState({
                permissionId:permissionId,
                info:''
            }, async()=>{
                this.setState({
                    loading:true
                })
                const server_response = await ajaxUser.grantUserPermission(this.state.userId, permissionId);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.message}/>,
                    })
                    this.props.refresh();
                }else{
                    this.setState({
                        info:<AlertError message={server_response.message}/>
                    })
                }

            })
           

       
    }


    render() {

        const rolesList = this.state.rolesList;
        const optionsRolesList=[];
        if(rolesList && rolesList!=="404")
        {
            rolesList.map((item, key)=>
                optionsRolesList.push({label:item.role_name, value:item.id})
            )
        }


        return (
            <ConfigConsumer>
                {props=>{
                    const transactions = props.permissionListing;

                    return(
                        <SystemModal id="mdl-permissions" title="Grant Permissions" type="modal-lg">
                        {this.state.info}
                        
    
                        {transactions && transactions!=="404" && 
                    <>
                    <div className='table-responsive'>
                    <table style={{fontSize:'14px'}} id={props.tableId} className="table table-bordered table-striped datatable">
									
                        <thead>
                            <tr>
                            <th>Code</th> 
                            <th>Description</th> 
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((item, key)=>
                            <tr 
                            // className={`${item.id===this.state.transactionId?'flag-row':'hot-tr'}`}
                            style={{cursor:'default'}}
                            // onClick={()=>this.onSelectRecord(item.id, item)}
                            key={key}>
                                <td>{item.code}</td>
                                <td>{item.description}</td>
                                <td>
                                <button className="btn btn-sm ripple btn-warning mb-1" onClick={()=>this.confirmGrant(item.id)} disabled={this.state.loading}>
                                    <i className="fa fa-check mr-1"></i>&nbsp;Grant</button>
                                </td>
                            </tr>
                        )} 
                        </tbody>
                    </table>
                    </div>
                    </>
                    }
        
                        {!transactions && <ActivityLoader/>}
        
                
    
                        <div className="row">&nbsp;</div>
    
    
                
                        <div className="row">&nbsp;</div>
                        
                        <div className="modal-footer no-border">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                            {/* <button type="submit" className="btn btn-success">Confirm New User</button> */}
                      
                        </div>
    
    
            
    
                </SystemModal>

                    )
                }}
            </ConfigConsumer>
          
        );
    }
}

export default GrantPermission;