/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ajaxSchool from "../../../remote/ajaxSchool";
import functions from "../../../util/functions";
import ActivityLoader from "../../Common/ActivityLoader";
import LetterHead from "../../Securities/LetterHead";

const StudentFeeStatement=(props)=>{

    const [statement, setStatement] = useState(false);
    const [meta, setMeta] = useState(false);
    const [openingBalance, setOpeningBalance] = useState({"amount_c":"0.00"})
    const [accountInfo, setAccountInfo] = useState(false);
    const [batchInfo, setBatchInfo] = useState(false);
    const [data, setData] = useState(props.data)

    useEffect(()=>{
        if(props.data){
            getStatement()
        }else{
            alert("Student statement not available this time")
        }
       
    }, [data])

   



    const getStatement=async()=>{
        const server_response = await ajaxSchool.fetchStudentStatement(data)
        if(server_response.status==="OK"){
            setStatement(server_response.details.list);
            setMeta(server_response.details.meta)
            setOpeningBalance(server_response.details.opening_balance);
            setAccountInfo(server_response.details.account_info)
            setBatchInfo(server_response.details.batch_info)
        }else{
            setStatement("404")
        }
    }


    const printStatement=()=>{

        functions.printElement("doc_statement_fin");
    }


    return(
        <>

        <div className="card-tools">
            
            <button type="button" className="btn btn-secondary float-right" onClick={printStatement}>
            <i className="fas fa-print"></i> Print</button> 

        </div>

        <div className="table-responsive mg-t-40" id="doc_statement_fin">

            {accountInfo && <LetterHead 
                    title={"School Fees Statement for " + accountInfo.full_name + "(" + accountInfo.reg_no + ") - " + accountInfo.class.class_name + " " + batchInfo.batch_name }
                    photo={accountInfo.photo}
                    />}

                    
                <table className="table table-invoice table-bordered" style={{fontSize:'12px'}}>
                    <thead>
                        <tr>
                            <th className="wd-20p tx-left">Date</th>
                            <th className="wd-40p tx-right">Description</th>
                            <th className="wd-20p tx-right">Invoice</th>
                            <th className="wd-20p tx-right">Payment</th>
                            <th className="wd-20p tx-right">Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            <td><b>Opening Balance</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td><b>{openingBalance.amount_c}</b></td>
                        </tr>
                        {Array.isArray(statement) && statement.map((item, key)=>
                            <tr key={key}>
                                <td>{item.transaction_date.long_date}</td>
                                <td>{item.description + " - Ledger ref: " + item.ledger_grouping }</td>
                                <td>{item.debit.amount > 0 ? item.debit.amount_c : ""}</td>
                                <td>{item.credit.amount > 0 ? item.credit.amount_c : ""}</td>
                                <td><b>{item.balance.amount_c}</b></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            

            {!statement && <ActivityLoader/>}

        </div></>)

}


export default StudentFeeStatement;