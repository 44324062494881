import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SchoolBillingContext from '../../Context/SchoolBillingContext';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import dictionary from '../../util/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SystemModal from '../Common/SystemModal';

class PopBillStudent extends Component {

    static contextType = SchoolBillingContext 

    state={
       
       loading:false,
       info:"",
       record:false,
       recordId:false
    }



   


    confirmAssign=async(event)=>{
        event.preventDefault();
  
        const { record }=this.state;
        if(record)
        {   
            this.setState({info:<ActivityLoader/>, loading:true})
            const server_response = await ajaxSchool.autoBillStudents({
                "config_id":record.id
            });
            this.setState({
                loading:false
            })
            if(server_response.status==="OK")
            {

                this.setState({
                    info:<AlertSuccess message={server_response.message}/>
                })
            }else{
                this.setState({
                    info:<AlertError message={server_response.message}/>
                })
            }

        }else{
            this.setState({
                info:<AlertError message={"Make a selection and try again"}/>
            })
        }
    }

    onSelectRecord=(recordId, record)=>{
        this.setState({recordId, record})
    }


   


    render() {

        const { billingConfig } = this.context

        return (
            
    <SystemModal id="mdl-auto-bill" title="Auto Students Billing" type="modal-lg">
               {this.state.info}
              
               <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                <div className="table-responsive table-ellipsi">
               <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Class</th>
                            <th>Residence</th>
                            <th>Gender</th>
                            <th>Billing Class</th>
                        </tr>
                    </thead>
                    <tbody>
                        { Array.isArray(billingConfig) && billingConfig.map((item, key)=>
                            <tr 
                                key={key}
                                className={`${item.id === this.state.recordId ? 'flag-row' : 'hot-tr'}`}
                                style={{ cursor: 'default' }}

                                onClick={() => this.onSelectRecord(item.id, item)}
                                id={"billconfig" + item.id}
                                >
                                <td>{item.class.class_name}</td>
                                <td>{item.residence.residence_name}</td>
                                <td>{item.gender}</td>
                                <td>{item.fees_group.group_name}</td>
                            </tr>
                        )}

                    </tbody>
               </table>

               </div>
               </div>

               {billingConfig === "404" && <AlertError message="No billing configuration was found. Please go to 'Fees Group' and assign each fees group"/>}
               
               {!billingConfig && <ActivityLoader/>}
            
            <div className="modal-footer no-border">
               {!this.state.loading && <button type="submit" className="btn btn-primary" onClick={this.confirmAssign} disabled={!this.state.recordId}>Bill Students</button>}
            </div>

   

   

    </SystemModal>
        );
    }
}

export default PopBillStudent;