import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import AlertError from '../../Components/Common/AlertError'
import CallOut from '../../Components/Common/CallOut'
import TextInput from '../../Components/Common/TextInput'
import PageContainer from '../../Components/Headings/PageContainer'
import NewBillingBatch from '../../Components/SchoolFees/NewBillingBatch'
import PopEditFeesBatch from '../../Components/SchoolFees/PopEditFeesBatch'
import SchoolContext from '../../Context/SchoolContext'
import functions from '../../util/functions'

class BillingBatchPage extends Component {
  static contextType = SchoolContext;

  state = {
   batchId:false,
   batch:false,
   modal:false
  }


  handleEdit=()=>{

    this.setState({
      modal:false
    }, ()=>{
      this.setState({
        modal:<PopEditFeesBatch batchInfo={this.state.batch}/>
      }, ()=>functions.openPopup("mdl-edit-batch"))
    })

  }

  handleRefresh=()=>{
    this.context.listBillingBatches()
  }

 
  RenderControls = () => {
    return (

      <div className='float-sm-right'>
                <ol className='button-listing'>
      
                   {this.state.batchId && <li>
                        <Link to="#" className="btn ripple btn-primary mb-1" onClick={this.handleEdit}>
                        <i className="fa fa-edit mr-1"></i>&nbsp;Edit</Link>
                    </li>}
                    <li>
                        <Link to="#" className="btn ripple btn-secondary mb-1" onClick={this.handleRefresh}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>
                    </li>
                </ol>
               
    
                
            </div>

    )
  }


  onSelectRecord=(batchId, batch)=>{
    this.setState({batchId, batch})
  }

  render () {

    const { billingBatches } = this.context;




    return (
      <>

        <PageContainer 
            title='Billing Batches'
            headerNav={<this.RenderControls/>}
            >
              {this.state.modal}
          <CallOut
            title='Billing Batches'
            description='Periodical Intervals for which the students are billed e.g Term I 2022, Term II 2022, etc'
          />
          <div className='row'>
            <div className='col-md-5'>
              <NewBillingBatch/>
            </div>

            <div className='col-md-7'>
              <div className='card'>
                <div className='card-header border-transparent'>
                  <h3 className='card-title'>All billing batches</h3>
                </div>

                <div className='card-body'>

                 

                <div className='table table-responsive table-ellipsis'>
                    {!billingBatches && <ActivityLoader/>}
                    <table className='table table-bordere text-nowrap mb-0'>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Batch</th>
                          <th>Start date</th>
                          <th>End Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(billingBatches) && billingBatches.map((item, key) => (
                          <tr
                            className={`${
                              item.id === this.state.batchId
                                ? 'flag-row'
                                : 'hot-tr'
                            }`}
                            style={{ cursor: 'default' }}
                            key={key}
                            onClick={() => this.onSelectRecord(item.id, item)}
                          >
                            <td>{key + 1}</td>
                            <td>{item.batch_name}</td>
                            <td>{item.start_date.short_date}</td>
                            <td>{item.end_date.short_date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {billingBatches==="404" && <AlertError type="info" message="No billing batch found"/>}
                </div>





                </div>
              </div>
            </div>
          </div>
        </PageContainer>
      </>
    )
  }
}

export default BillingBatchPage
