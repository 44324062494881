import React, { Component, useContext } from 'react'
import { Link } from 'react-router-dom'
import SchoolContext from '../../Context/SchoolContext'
// import Chart from 'react-apexcharts'
import ActivityLoader from '../Common/ActivityLoader'
import CardTitle from '../Common/CardTitle'

const FeesGroupsCard =()=> {
  
    const { feesGroups } = useContext(SchoolContext)
 
    return (
      <>
        <div className="card">
          <CardTitle
            title="Fees groupings"
            subtitle="List of fees groups and their respective total fees"
          />

          <div className="card-body p-0 overflow-auto h-200">
           {Array.isArray(feesGroups) && <ul className="products-list product-list-in-card pl-2 pr-2">
            
              {feesGroups.map((item, key)=><li className="item" key={key}>
              
                <div className="product-info">
                  <Link to="#" className="product-title">{item.group_info.group_name}
                    <span className="badge badge-primary float-right mr-3">UGX. {item.structure?item.structure.total_fees.amount_p:0}</span>
                  </Link>
                  <span className="product-description">
                    {item.group_info.description}
                  </span>
                </div>
              </li>)}
             
            </ul>}

            {!feesGroups && <ActivityLoader/>}

          </div>
          <div className="card-footer text-center">
            <a href="/school/fees/fees-group" className="uppercase">Manage Fees Groups</a>
          </div>
        </div>
       
      </>
    )
  
}

export default FeesGroupsCard
