import React, { useEffect, useState } from 'react';
import ajaxUser from '../remote/ajaxUser';
import functions from '../util/functions';

const AuthContext = React.createContext();

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = (props)=> {

    const [user, setUser] = useState({
        username:"Andrew",
        firstName:"Andre",
        lastName:"Ssekirime"
    })

    const [userId, setUserId] = useState(functions.sessionGuard());
    const [permissionList, setPermissionList] = useState([]);

    useEffect(()=>{
        getUserAccess();
    }, [])


    const getUserAccess=async()=>{
        if(!userId)
        {
            return false
        }
        const server_response = await ajaxUser.getUserPermissionCodes(userId);
        if(server_response.status==="OK")
        {  
            setPermissionList(server_response.details);
        
        }else{
           // alert("Something went wrong loading YEXP, check your connection or contact system admin")
        }
    }
        
    
    return (
           <AuthContext.Provider value={
               {
                   user,
                   permissionList
               }
               }>
               {props.children}
           </AuthContext.Provider>
        );
    
}

export default AuthContext;