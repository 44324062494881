import React, { Component } from 'react';

class PageTitle extends Component {
    render() {
        return (
            <h2 className="title1">{this.props.title}
            {this.props.headerEnd && <span style={{float:"right", marginRight:"10px"}}>{this.props.headerEnd}</span>}
        </h2>
        );
    }
}

export default PageTitle;