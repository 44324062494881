import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import PageNav from '../../Components/Common/PageNav';
import TopBar from '../../Components/Common/TopBar';
import PageContainer from '../../Components/Headings/PageContainer';
import PageTitle from '../../Components/Headings/PageTitle';
import ajaxTag from '../../remote/ajaxTag';

class ListTagPage extends Component {

    state={
        transactions:false,
        info:'',
        transactionId:"",
        page:1,
        metaData:false,
        pagination:false,
        activeTab:"main",
        currentRecord:false,
        accountId:'',
        accountInfo:false
    }

    componentDidMount() {

        this.listTransactions();
    }

    listTransactions=async()=>{
        const server_response = await ajaxTag.filterTags(this.state.page);
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details.list,
                metaData:server_response.details.meta
            }, ()=>this.paginate())
        }else{
            this.setState({
                transactions:"404",
                info:<AlertError type="info" message={server_response.message}/>
            })
        }
    }

    onSelectRecord=(transactionId, currentRecord)=>{
        this.setState({transactionId, currentRecord})
    }



    //==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


  onClickTab=(value)=>{
      this.setState({activeTab:value})
  }



  RenderTransactions=()=>{
        const transactions = this.state.transactions;
      return(
        <>
        {!transactions && <ActivityLoader/>}


            <div className="table table-responsive table-ellipsis">
            {transactions && transactions!=="404" && <table className="table table-bordere text-nowrap mb-0">
                <thead>
                    <tr>
                    <th>No.</th>                                   
                    <th>Tag Name</th> 
                    <th>#Ref</th> 
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map((item, key)=>
                 <tr 
                    className={`${item.tag_id===this.state.transactionId?'flag-row':'hot-tr'}`}
                    style={{cursor:'default'}}
                    key={key}
                    onClick={()=>this.onSelectRecord(item.tag_id, item)}
                 >
                     <td>{this.state.metaData.offset_count+key+1}</td>
                     <td>{item.tag_name}</td>
                     <td>{item.ref}</td>
                     <td>{item.description}</td>
                </tr>
                )}
                   
            
                
                </tbody>
            </table>}
                {transactions==="404" && this.state.info}
                {this.state.pagination}



            </div>


    
    </>

      )
  }


  RenderControls=()=>{
    return (
      <Link to="/tags/new" className="btn ripple btn-primary mb-1">
        <i className="fa fa-edit mr-1"></i>&nbsp;Create New</Link>
    )
  }

    render() {
       

        return (
          <PageContainer title="Ledger Tags">
          <div className="row">
              <div className="col-md-12">
              <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Tag Listing</h3>
                  </div>

                <div className="card-body">
					          <this.RenderTransactions/>
                </div>
              </div>

						
          </div>
          </div>
          </PageContainer>
        );
    }
}

export default ListTagPage;