import React, { useEffect, useState } from 'react';
import ajaxConfig from '../remote/ajaxConfig';
import ajaxUser from '../remote/ajaxUser';
import { beautifyTable } from '../util/script/customTables';

const ConfigContext = React.createContext();

export const ConfigConsumer=ConfigContext.Consumer;

export const ConfigProvider=(props)=> {

    const [totalSignatories, setTotalSignatories] = useState('...');
    const [totalUsers, setTotalUsers] = useState('...');
    const [totalPermissions, setTotalPermissions] = useState('...');
    const [permissionListing, setPermissionListing] = useState(false);
    const [tableId, setTableId] = useState('table');
    const [loadTable, setLoadTable] = useState(true);
    const [exportFormats, setExportFormats] = useState(false);
    const [clientProfile, setClientProfile] = useState(false);
   
 
     useEffect(()=>{
        count_signatories();
        count_users();
        count_permissions();
        getPermissionListing();
        getClientProfile();
     }, [])
       
    //  useEffect(()=>{
    //     beautifyTable(tableId, loadTable, 'forexport');
    //     setLoadTable(false)
    //     console.log("Table loaded")
    //  }, [tableId, loadTable])

    const refresh=()=>{
        console.log("Refreshed")
    }
 
     const count_signatories=async()=>{
         const server_response = await ajaxConfig.count_signatories();
         if(server_response.status==="OK")
         {
             setTotalSignatories(server_response.details.total_c)
         }
     }
    
     const count_users=async()=>{
         const server_response = await ajaxUser.count_users();
         if(server_response.status==="OK")
         {
             setTotalUsers(server_response.details.total_c)
         }
     }

     const count_permissions=async()=>{
        const server_response = await ajaxUser.countAllPermissions();
        
        if(server_response.status==="OK")
        {
            setTotalPermissions(server_response.details.total_c)
        }
    }

    const getPermissionListing=async()=>{
        const server_response = await ajaxUser.listAllPermissions();
        if(server_response.status==="OK")
        {  
            setPermissionListing(server_response.details.list);
            setTableId("table-permissions");
            beautifyTable(tableId, loadTable, exportFormats);
            setLoadTable(false)
        
        }else{
            setPermissionListing("404")
        }
    }

    const exportTable=(types)=>{
        setExportFormats(types);
    }


    const getClientProfile=async()=>{

        const server_response = await ajaxConfig.fetchClientProfile();
        if(server_response.status === "OK"){
            setClientProfile(server_response.details);
        }else{
            setClientProfile("404")
        }

    }
   

        return (
           <ConfigContext.Provider value={
               {
                   totalSignatories, 
                   totalUsers,
                   totalPermissions,
                   permissionListing,
                   tableId,
                   clientProfile,
                   getClientProfile,
                   exportTable
                }
               }>
               {props.children}
           </ConfigContext.Provider>
        );
    
}

export default ConfigContext;