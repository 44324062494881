import React from 'react';


class SystemModal extends React.Component {
  state={
      loading:false
  }

  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  render(){
    
      return (
        <div className="modal" id={this.props.id || "sys_modal"}>
        <div className={`modal-dialog modal-dialog-centered ${this.props.type}`} role="document">
            <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{this.props.title}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
                
                <div className="modal-body">
                   {this.props.children}
                </div>

                 <div className="modal-footer justify-content-between">
                   {this.props.footer}
                </div>


            </div>
        </div>
    </div>
      )
    
 
  }
}

export default SystemModal;